import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from '../guards/AuthGuard';
import { useGetProjectServicesQuery } from '../generated/graphql';
import useProject from '../utils/useProject';
import News from '../pages/PageNews';

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const [project] = useProject();
  const projectServices = useGetProjectServicesQuery({
    variables: {
      selectedProjectId: project,
    },
  });

  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/dashboard" replace />,
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/overview" replace />, index: true },
        { path: 'overview', element: <ProjectOverview /> },
        { path: 'news', element: <News /> },
        { path: 'project-history', element: <ProjectHistory /> },
        { path: 'contact-persons', element: <ContactPersons /> },
        { path: 'tasks', element: <LiveTasks /> },
      ],
    },
    {
      path: '/services',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'overview', element: <Services /> },
        { path: ':serviceId', element: <ServiceIframe /> },
        { element: <Navigate to="/services/overview" replace />, index: true },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Dashboard
const ProjectOverview = Loadable(lazy(() => import('../pages/PageProjectOverview')));
const ProjectHistory = Loadable(lazy(() => import('../pages/ProjectHistory')));
const ContactPersons = Loadable(lazy(() => import('../pages/ContactPersons')));
const LiveTasks = Loadable(lazy(() => import('../pages/PageLiveTasks')));
const Services = Loadable(lazy(() => import('../pages/PageServices')));
const ServiceIframe = Loadable(lazy(() => import('../pages/PageServiceIframe')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
