import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Drawer, Stack } from '@mui/material';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import { NAVBAR } from '../../../config';
import Logo from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
import NavbarAccount from './NavbarAccount';
import { useGetProjectServicesForNavigationQuery } from '../../../generated/graphql';
import SvgIconStyle from '../../../components/SvgIconStyle';
import Iconify from '../../../components/Iconify';
import Label from '../../../components/Label';
import useProject from '../../../utils/useProject';
import { ProjectPicker } from './ProjectPicker';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export const NEWS_URL_PATH: string = '/dashboard/news';

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }: Props) {
  const { pathname } = useLocation();
  const [project] = useProject();

  let { isCollapse, collapseClick } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const availableServices = useGetProjectServicesForNavigationQuery({
    variables: {
      selectedProjectId: project,
    },
  });

  const serviceChildren = [{ title: 'Overview', path: '/services/overview' }];

  availableServices.data?.Project_Service.forEach((service) => {
    if (service.display_iframe) {
      serviceChildren.push({ title: service.title ?? '', path: '/services/' + service.id });
    }
  });

  const navConfig = [
    {
      subheader: 'Unser Projekt',
      items: [
        { title: 'Übersicht', path: '/dashboard/overview', icon: ICONS.overview },
        {
          title: 'News',
          path: NEWS_URL_PATH,
          icon: ICONS.news,
        },
        { title: 'Projektverlauf', path: '/dashboard/project-history', icon: ICONS.projectHistory },
        {
          title: 'Ansprechpartner',
          path: '/dashboard/contact-persons',
          icon: ICONS.contactPersons,
        },
        {
          title: 'Aufgaben',
          path: '/dashboard/tasks',
          icon: ICONS.tasks,
          info: <Label color="info">NEW</Label>,
        },
        {
          title: 'Services',
          path: '/services',
          icon: ICONS.services,
          children: serviceChildren,
        },
        // {
        //     // default roles : All roles can see this entry.
        //     // roles: ['user'] Only users can see this item.
        //     // roles: ['admin'] Only admin can see this item.
        //     // roles: ['admin', 'manager'] Only admin/manager can see this item.
        //     // Reference from 'src/guards/RoleBasedGuard'.
        //     title: 'item_by_roles',
        //     path: '/test',
        //     icon: ICONS.services,
        //     roles: ['admin'],
        //     caption: 'only_admin_can_see_this_item',
        // },
      ],
    },
  ];

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Logo isCollapse={isCollapse} />
          {/*<CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick}/>*/}
        </Stack>

        <NavbarAccount isCollapse={isCollapse} />
        <ProjectPicker />
      </Stack>

      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />

      <Box sx={{ flexGrow: 1 }} />

      {/*{!isCollapse && <NavbarDocs/>}*/}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      <Drawer
        open
        variant="persistent"
        PaperProps={{
          sx: {
            width: NAVBAR.DASHBOARD_WIDTH,
            borderRightStyle: 'dashed',
            bgcolor: 'background.default',
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.standard,
              }),
            ...(isCollapse && {
              width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
            }),
          },
        }}
      >
        {renderContent}
      </Drawer>
    </RootStyle>
  );
}

const getIconFromSVG = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

export const getIcon = (selectedIcon: string) => <Iconify icon={selectedIcon} />;

/**
 * Icons are loaded from here: https://icon-sets.iconify.design/
 */
const ICONS = {
  user: getIconFromSVG('ic_user'),
  overview: getIcon('openmoji:overview'),
  news: getIcon('ic:round-newspaper'),
  projectHistory: getIcon('gis:map-stat'),
  contactPersons: getIcon('mdi:account-multiple'),
  tasks: getIcon('bi:list-task'),
  services: getIcon('bi:bookmark-plus'),
};
