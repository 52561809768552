import { useGetProjectsQuery } from '../../../generated/graphql';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import useProject from '../../../utils/useProject';

export function ProjectPicker() {
  const [project, setProject] = useProject();
  const { data } = useGetProjectsQuery();

  if (project === -1) {
    // The project has never been set, defaulting to first project
    if (data && data.Project.length > 0) {
      setProject(data.Project[0].id);
    }
  }

  const handleChange = (event: SelectChangeEvent) => {
    setProject(parseInt(event.target.value));
  };

  if ((data?.Project.length ?? 0) <= 1) {
    return null;
  }

  return (
    <FormControl fullWidth sx={{ mt: 2 }}>
      <InputLabel id="project-picker-label">Projekt</InputLabel>
      <Select
        size="small"
        variant="outlined"
        label="Projekt"
        labelId="project-picker-label"
        onChange={handleChange}
        value={project.toString()}
      >
        {data &&
          data.Project.map((it) => (
            <MenuItem value={it.id} key={it.id}>
              {it.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
