import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, CircularProgress, IconButton, InputAdornment, Stack } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import Iconify from '../../components/Iconify';
import { FormProvider, RHFCheckbox, RHFTextField } from '../../components/hook-form';

type FormValuesProps = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};

export default function LoginForm() {
  const { login } = useAuth();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Sie müssen eine gültige E-Mail-Adresse eingeben')
      .required('Pflichtfeld'),
    password: Yup.string().required('Pflichtfeld'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    clearErrors();
    try {
      await login(data.email, data.password);
    } catch (error) {
      console.error('Error during login', error);

      reset({ email: data.email, password: data.password });

      //TODO nicely format error codes
      const errorMessage = error.errors[0].message;

      if (isMountedRef.current) {
        console.log('setting error');
        setError('afterSubmit', { ...error, message: errorMessage });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="email" label="E-Mail-Adresse" />

        <RHFTextField
          name="password"
          label="Passwort"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {false && (
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <RHFCheckbox name="remember" label="Angemeldet bleiben" />
        </Stack>
      )}

      <Button
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        disabled={isSubmitting}
        sx={{ my: 3 }}
      >
        {isSubmitting ? <CircularProgress /> : null}Anmelden
      </Button>
    </FormProvider>
  );
}
