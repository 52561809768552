import { format, formatDistanceToNow, getTime } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function fToGQLRepresentation(date: Date | string | number): string {
  const utcDate = zonedTimeToUtc(new Date(date), 'UTC');
  return utcDate.toISOString();
}

export function fFromGQLRepresentation(date: Date | string | number) {
  const dateInUtc = utcToZonedTime(new Date(date), 'UTC');
  return format(dateInUtc, 'dd.MM.yyyy - HH:mm');
}
