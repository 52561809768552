import {getSession} from "./jwt";

const base = "https://admin.selectcode.dev/assets/"

export function downloadFile(fileId: string) {
    return `${base}${fileId}?access_token=${getSession()}&download`
}


function serialize(obj: object) {
    const str = [];
    for (const p in obj)
        { // @ts-ignore
            if (obj.hasOwnProperty(p) && obj[p]) {
                        // @ts-ignore
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
        }
    return str.join("&");
}

interface ThumbnailOptions {
    fit: "cover" | "contain" | "inside" | "outside" | undefined
    width: number | undefined,
    height: number | undefined
    quality: number | undefined,
    format: "png" | "webp" | "png" | "tiff" | undefined
}

export function thumbnail(fileId: string, options: ThumbnailOptions) {
    return `${base}${fileId}?access_token=${getSession()}&${serialize(options)}`
}
