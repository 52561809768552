import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import { HEADER, NAVBAR } from '../../config';
import NavbarVertical, { NEWS_URL_PATH } from './navbar/NavbarVertical';
import useProject from '../../utils/useProject';
import { Project_News, useGetUrgentActiveNewsQuery } from '../../generated/graphql';
import NewsMessage from '../../components/NewsMessage';
import { fToGQLRepresentation } from '../../utils/formatTime';

type MainStyleProps = {
  collapseClick: boolean;
};

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

export default function DashboardLayout() {
  const { collapseClick, isCollapse } = useCollapseDrawer();

  const [open, setOpen] = useState(false);

  let currentPath = window.location.pathname;
  const urgentNewsDisplayedTwice = currentPath === NEWS_URL_PATH;

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        {!urgentNewsDisplayedTwice && <UrgentNews />}
        <Outlet />
      </MainStyle>
    </Box>
  );
}

function UrgentNews() {
  const [projectId] = useProject();
  const [currentDate] = useState(new Date());

  const urgentActiveNews: Project_News[] | undefined = useGetUrgentActiveNewsQuery({
    variables: {
      selectedProjectId: projectId,
      currentTime: fToGQLRepresentation(currentDate),
    },
  }).data?.Project_News;

  return (
    <Box>
      {urgentActiveNews &&
        urgentActiveNews.map((newsMessage) => (
          <NewsMessage key={'news-message-' + newsMessage.id} newsMessage={newsMessage} />
        ))}
    </Box>
  );
}
