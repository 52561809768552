import {
  ApolloClient,
  ApolloLink,
  concat,
  DefaultOptions,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { getSession } from './utils/jwt';
import { toast } from 'react-toastify';

const loadHeaders = () => {
  const token = getSession();
  if (token) {
    return {
      Authorization: `Bearer ${token}`,
    };
  }
  return {};
};

const fetcher = (...args: any) =>
  // @ts-ignore
  window.fetch(...args);
const use_localhost = process.env.REACT_APP_USE_LOCAL;

const httpUri = use_localhost
  ? `http://docker.for.mac.localhost:8080/v1/graphql`
  : `https://graphql-admin.selectcode.dev/v1/graphql`;
const wsUri = `wss://graphql-admin.selectcode.dev/v1/graphql`;

const httpLink = new HttpLink({
  uri: httpUri,
  fetch: fetcher,
});
const wsLink = new WebSocketLink({
  uri: wsUri,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: {
      headers: loadHeaders(),
    },
  },
});

const whitelistedErrors = ['unauthorized'];

const errorLink = new ApolloLink((operation, forward) =>
  forward(operation).map(({ data, errors }) => {
    if (errors) {
      errors.forEach((error: any) => {
        if (!error) return;
        for (const e of whitelistedErrors) {
          if (error.message.includes(e)) {
            return;
          }
        }
        if (error.message.includes("not found in type: 'query_root'")) {
          console.error('Authentication is not valid');
          toast.error('Authentication Error. Please try to log in again.');
          // resetAuth()
        } else {
          toast('API Error occurred: ' + error.message, { type: 'error' });
          console.error(`GraphQL error: ${error.name} -> ${error.message}`, error);
          // Capture Sentry Exception
          // captureException(error)
        }
      });
    }
    return { data, errors };
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  concat(errorLink, httpLink)
);

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: loadHeaders(),
  });
  return forward(operation);
});

/**
 * We disable the cache within the default options as it seems to lead to the following issues:<br>
 * <ul>
 *   <li>displaying old project data (if switching to project did not work)</li>
 *   <li>not showing updated data from the CMS after reloading the page</li>
 * </ul>
 *
 */
const apolloDefaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
  },
  query: {
    fetchPolicy: 'no-cache',
  },
};

export const apolloClient = new ApolloClient({
  link: concat(authMiddleware, splitLink),
  cache: new InMemoryCache(),
  defaultOptions: apolloDefaultOptions,
});
