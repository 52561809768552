// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Container, Stack, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import LoginForm from '../sections/login/LoginForm';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const mdUp = useResponsive('up', 'md');

  const CardLeft = styled(SectionStyle)(() => ({
    background: 'url("/assets/backgrounds/launch.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }));

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Logo />
        </HeaderStyle>

        {mdUp && <CardLeft />}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="column" alignItems="left" sx={{ mb: 3 }}>
              <img src="/logo/rocket_animated.svg" alt="Rakete" height="100px" width="100px" />
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Bei SelectProject anmelden
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Bitte geben Sie Ihre Anmeldeinformationen ein.
                </Typography>
              </Box>
            </Stack>
            <LoginForm />
            <Typography sx={{ color: 'text.secondary' }}>
              Wenn Sie noch keinen Login für SelectProject erhalten haben, wenden Sie sich an Ihren
              Projektmanager.
            </Typography>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
