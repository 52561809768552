import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession, setRefreshToken } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/auth';
import { URLS } from '../config';
import { useUserInfo } from '../hooks/useUserInfo';
import LogRocket from 'logrocket';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const [getUserInfo, setUserInfo] = useUserInfo();

  const setUserData = async (accessToken: string) => {
    const user = (
      await axios.get(URLS.directus.routes.currentUserProfile + '?access_token=' + accessToken, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
    ).data.data;

    console.log(user);

    const roles = {
      //customer_user
      '8048acc7-0a3b-497f-a73e-4ab64d1d548a': {
        isManager: false,
        isCustomer: true,
      },
      //customer_manager
      '9edfad19-1f78-4efa-8778-24ca7222cd2b': {
        isManager: true,
        isCustomer: true,
      },
      //employee_user
      'c1f49ab5-4de9-4999-bfef-6561b3d024d9': {
        isManager: false,
        isCustomer: false,
      },
      //employee_manager
      '7c64f057-295d-4c68-a98f-5cc96176b8eb': {
        isManager: true,
        isCustomer: false,
      },
      // Admin Role
      'a70e697d-e134-4192-bfab-e4d04f09570c': {
        isManager: true,
        isCustomer: false,
      },
    };

    // This is an example script - don't forget to change it!
    LogRocket.identify(user.email, {
      name: user.first_name + ' ' + user.last_name,
      company: user.companyId,
    });

    // @ts-ignore
    delighted.survey({
      email: user.email,
      name: user.first_name + ' ' + user.last_name,
      properties: {
        company: user.companyId,
      },
    });

    setUserInfo({
      name: user.first_name + ' ' + user.last_name,
      // @ts-ignore
      ...roles[user.role],
      loggedIn: true,
      companyId: user.companyId,
      avatarFile: user.avatar,
    });
    return user;
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const user = setUserData(accessToken);

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    const response = await axios.post(
      URLS.directus.routes.login,
      {
        email,
        password,
      },
      { withCredentials: true }
    );
    const accessToken = response.data.data.access_token;
    const refreshToken = response.data.data.refresh_token;

    setRefreshToken(refreshToken);
    setSession(accessToken);

    const user = setUserData(accessToken);

    dispatch({
      type: Types.Login,
      payload: {
        user,
      },
    });
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    setRefreshToken(null);
    dispatch({ type: Types.Logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
