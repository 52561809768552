import { styled } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Card,
  Divider,
  IconButton,
  Link,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { useUserInfo } from '../../../hooks/useUserInfo';
import { thumbnail } from '../../../utils/directusAssets';
import { useGetCustomerQuery } from '../../../generated/graphql';
import Iconify from '../../../components/Iconify';
import MenuPopover from '../../../components/MenuPopover';
import React, { useState } from 'react';
import useAuth from '../../../hooks/useAuth';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  // backgroundColor: theme.palette.grey[500_12],
  borderColor: theme.palette.grey[500_12],
  border: 2,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

type Props = {
  isCollapse: boolean | undefined;
};

export default function NavbarAccount({ isCollapse }: Props) {
  const [user] = useUserInfo();
  const auth = useAuth();
  const customer = useGetCustomerQuery();

  const [menuElement, setMenuElement] = useState<HTMLButtonElement | null>(null);

  const customerName = customer.data?.Customer[0].name ?? '';

  function onLogOut() {
    auth.logout();
  }

  function closeMenu() {
    setMenuElement(null);
  }

  const openMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setMenuElement(e.currentTarget);
  };

  return (
    <Link underline="none" color="inherit">
      <Card
        sx={{
          boxShadow: 0,
          bgcolor: (theme) => theme.palette.grey.A100,
        }}
      >
        <RootStyle
          sx={{
            ...(isCollapse && {
              bgcolor: 'transparent',
            }),
          }}
        >
          <Stack direction="row" alignItems="center" overflow="hidden">
            <Avatar
              src={thumbnail(user.avatarFile, {
                fit: undefined,
                format: undefined,
                height: undefined,
                quality: undefined,
                width: undefined,
              })}
              alt=""
            />
            <Box
              sx={{
                ml: 2,
                overflow: 'hidden',
                transition: (theme) =>
                  theme.transitions.create('width', {
                    duration: theme.transitions.duration.shorter,
                  }),
                ...(isCollapse && {
                  ml: 0,
                  width: 0,
                }),
              }}
            >
              <Typography variant="subtitle2" noWrap textOverflow="ellipsis" overflow="hidden">
                {user.name}
              </Typography>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                {customerName}
              </Typography>
            </Box>

            <IconButton onClick={openMenu}>
              <Iconify icon={'eva:chevron-right-fill'} width={20} height={20} />
            </IconButton>

            <MenuPopover
              open={Boolean(menuElement)}
              anchorEl={menuElement}
              onClose={closeMenu}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              arrow="left-top"
              sx={{
                mt: -1,
                '& .MuiMenuItem-root': {
                  px: 1,
                  typography: 'body2',
                  borderRadius: 0.75,
                  '& svg': { mr: 2, width: 20, height: 20 },
                },
              }}
            >
              <Typography>{user.name}</Typography>
              <Divider sx={{ my: 1 }} />
              <MenuItem
                onClick={() => {
                  closeMenu();
                  onLogOut();
                }}
              >
                <Iconify icon={'eva:log-out-fill'} />
                Abmelden
              </MenuItem>
            </MenuPopover>
          </Stack>
        </RootStyle>
      </Card>
    </Link>
  );
}
