import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, CardProps, Box } from '@mui/material';
import { ColorSchema } from '../theme/palette';
import Iconify from './Iconify';
import { Project_News } from '../generated/graphql';
import { fFromGQLRepresentation } from '../utils/formatTime';

const IconWrapperStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(6),
  height: theme.spacing(6),
  justifyContent: 'center',
  marginBottom: theme.spacing(1),
}));

interface NewsMessageProps extends CardProps {
  newsMessage: Project_News;
}

export default function NewsMessage({ newsMessage, sx, ...other }: NewsMessageProps) {
  function getIconByVariant(variant: ColorSchema) {
    switch (variant) {
      case 'warning':
        return 'ic:round-warning-amber';
      case 'error':
        return 'ic:baseline-report-gmailerrorred';
      case 'success':
        return 'ic:baseline-check';
      default:
        return 'ic:outline-info';
    }
  }

  const color = newsMessage.variant as ColorSchema;

  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        py: 1,
        mb: 2,
        boxShadow: 0,
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      <IconWrapperStyle
        sx={{
          color: (theme) => theme.palette[color].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
              theme.palette[color].dark,
              0.24
            )} 100%)`,
          ml: 1,
        }}
      >
        <Iconify icon={getIconByVariant(color)} width={32} height={32} />
      </IconWrapperStyle>

      <Box sx={{ ml: 2, width: '95%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6">{newsMessage.title}</Typography>
          <Typography variant={'body2'} sx={{ ml: 1 }}>
            {fFromGQLRepresentation(newsMessage.showFrom)}
          </Typography>
        </Box>

        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          {newsMessage.details}
        </Typography>
      </Box>
    </Card>
  );
}
