import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  date: any;
  json: any;
  jsonb: any;
  numeric: any;
  time: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** columns and relationships of "Customer" */
export type Customer = {
  __typename?: 'Customer';
  /** An array relationship */
  Projects: Array<Project>;
  /** An aggregate relationship */
  Projects_aggregate: Project_Aggregate;
  address?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['timestamptz']>;
  date_updated?: Maybe<Scalars['timestamptz']>;
  employees?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  logo?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  since_date?: Maybe<Scalars['timestamp']>;
  user_created?: Maybe<Scalars['uuid']>;
  user_updated?: Maybe<Scalars['uuid']>;
  website_url?: Maybe<Scalars['String']>;
  year_of_foundation?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "Customer" */
export type CustomerProjectsArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};


/** columns and relationships of "Customer" */
export type CustomerProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};

/** aggregated selection of "Customer" */
export type Customer_Aggregate = {
  __typename?: 'Customer_aggregate';
  aggregate?: Maybe<Customer_Aggregate_Fields>;
  nodes: Array<Customer>;
};

/** aggregate fields of "Customer" */
export type Customer_Aggregate_Fields = {
  __typename?: 'Customer_aggregate_fields';
  avg?: Maybe<Customer_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Max_Fields>;
  min?: Maybe<Customer_Min_Fields>;
  stddev?: Maybe<Customer_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Sum_Fields>;
  var_pop?: Maybe<Customer_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Var_Samp_Fields>;
  variance?: Maybe<Customer_Variance_Fields>;
};


/** aggregate fields of "Customer" */
export type Customer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Customer_Avg_Fields = {
  __typename?: 'Customer_avg_fields';
  employees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year_of_foundation?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Customer". All fields are combined with a logical 'AND'. */
export type Customer_Bool_Exp = {
  Projects?: InputMaybe<Project_Bool_Exp>;
  Projects_aggregate?: InputMaybe<Project_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Customer_Bool_Exp>>;
  _not?: InputMaybe<Customer_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  date_created?: InputMaybe<Timestamptz_Comparison_Exp>;
  date_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  employees?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  logo?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  since_date?: InputMaybe<Timestamp_Comparison_Exp>;
  user_created?: InputMaybe<Uuid_Comparison_Exp>;
  user_updated?: InputMaybe<Uuid_Comparison_Exp>;
  website_url?: InputMaybe<String_Comparison_Exp>;
  year_of_foundation?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Customer" */
export enum Customer_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerPkey = 'Customer_pkey'
}

/** input type for incrementing numeric columns in table "Customer" */
export type Customer_Inc_Input = {
  employees?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  year_of_foundation?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Customer" */
export type Customer_Insert_Input = {
  Projects?: InputMaybe<Project_Arr_Rel_Insert_Input>;
  address?: InputMaybe<Scalars['String']>;
  date_created?: InputMaybe<Scalars['timestamptz']>;
  date_updated?: InputMaybe<Scalars['timestamptz']>;
  employees?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  logo?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  since_date?: InputMaybe<Scalars['timestamp']>;
  user_created?: InputMaybe<Scalars['uuid']>;
  user_updated?: InputMaybe<Scalars['uuid']>;
  website_url?: InputMaybe<Scalars['String']>;
  year_of_foundation?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Customer_Max_Fields = {
  __typename?: 'Customer_max_fields';
  address?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['timestamptz']>;
  date_updated?: Maybe<Scalars['timestamptz']>;
  employees?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  logo?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  since_date?: Maybe<Scalars['timestamp']>;
  user_created?: Maybe<Scalars['uuid']>;
  user_updated?: Maybe<Scalars['uuid']>;
  website_url?: Maybe<Scalars['String']>;
  year_of_foundation?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Customer_Min_Fields = {
  __typename?: 'Customer_min_fields';
  address?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['timestamptz']>;
  date_updated?: Maybe<Scalars['timestamptz']>;
  employees?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  logo?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  since_date?: Maybe<Scalars['timestamp']>;
  user_created?: Maybe<Scalars['uuid']>;
  user_updated?: Maybe<Scalars['uuid']>;
  website_url?: Maybe<Scalars['String']>;
  year_of_foundation?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "Customer" */
export type Customer_Mutation_Response = {
  __typename?: 'Customer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer>;
};

/** input type for inserting object relation for remote table "Customer" */
export type Customer_Obj_Rel_Insert_Input = {
  data: Customer_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_On_Conflict>;
};

/** on_conflict condition type for table "Customer" */
export type Customer_On_Conflict = {
  constraint: Customer_Constraint;
  update_columns?: Array<Customer_Update_Column>;
  where?: InputMaybe<Customer_Bool_Exp>;
};

/** Ordering options when selecting data from "Customer". */
export type Customer_Order_By = {
  Projects_aggregate?: InputMaybe<Project_Aggregate_Order_By>;
  address?: InputMaybe<Order_By>;
  date_created?: InputMaybe<Order_By>;
  date_updated?: InputMaybe<Order_By>;
  employees?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  since_date?: InputMaybe<Order_By>;
  user_created?: InputMaybe<Order_By>;
  user_updated?: InputMaybe<Order_By>;
  website_url?: InputMaybe<Order_By>;
  year_of_foundation?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Customer */
export type Customer_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Customer" */
export enum Customer_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  DateCreated = 'date_created',
  /** column name */
  DateUpdated = 'date_updated',
  /** column name */
  Employees = 'employees',
  /** column name */
  Id = 'id',
  /** column name */
  Logo = 'logo',
  /** column name */
  Name = 'name',
  /** column name */
  SinceDate = 'since_date',
  /** column name */
  UserCreated = 'user_created',
  /** column name */
  UserUpdated = 'user_updated',
  /** column name */
  WebsiteUrl = 'website_url',
  /** column name */
  YearOfFoundation = 'year_of_foundation'
}

/** input type for updating data in table "Customer" */
export type Customer_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  date_created?: InputMaybe<Scalars['timestamptz']>;
  date_updated?: InputMaybe<Scalars['timestamptz']>;
  employees?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  logo?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  since_date?: InputMaybe<Scalars['timestamp']>;
  user_created?: InputMaybe<Scalars['uuid']>;
  user_updated?: InputMaybe<Scalars['uuid']>;
  website_url?: InputMaybe<Scalars['String']>;
  year_of_foundation?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Customer_Stddev_Fields = {
  __typename?: 'Customer_stddev_fields';
  employees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year_of_foundation?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Customer_Stddev_Pop_Fields = {
  __typename?: 'Customer_stddev_pop_fields';
  employees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year_of_foundation?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Customer_Stddev_Samp_Fields = {
  __typename?: 'Customer_stddev_samp_fields';
  employees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year_of_foundation?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "Customer" */
export type Customer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  date_created?: InputMaybe<Scalars['timestamptz']>;
  date_updated?: InputMaybe<Scalars['timestamptz']>;
  employees?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  logo?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  since_date?: InputMaybe<Scalars['timestamp']>;
  user_created?: InputMaybe<Scalars['uuid']>;
  user_updated?: InputMaybe<Scalars['uuid']>;
  website_url?: InputMaybe<Scalars['String']>;
  year_of_foundation?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Customer_Sum_Fields = {
  __typename?: 'Customer_sum_fields';
  employees?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  year_of_foundation?: Maybe<Scalars['Int']>;
};

/** update columns of table "Customer" */
export enum Customer_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  DateCreated = 'date_created',
  /** column name */
  DateUpdated = 'date_updated',
  /** column name */
  Employees = 'employees',
  /** column name */
  Id = 'id',
  /** column name */
  Logo = 'logo',
  /** column name */
  Name = 'name',
  /** column name */
  SinceDate = 'since_date',
  /** column name */
  UserCreated = 'user_created',
  /** column name */
  UserUpdated = 'user_updated',
  /** column name */
  WebsiteUrl = 'website_url',
  /** column name */
  YearOfFoundation = 'year_of_foundation'
}

export type Customer_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Var_Pop_Fields = {
  __typename?: 'Customer_var_pop_fields';
  employees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year_of_foundation?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Customer_Var_Samp_Fields = {
  __typename?: 'Customer_var_samp_fields';
  employees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year_of_foundation?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Customer_Variance_Fields = {
  __typename?: 'Customer_variance_fields';
  employees?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year_of_foundation?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Employee" */
export type Employee = {
  __typename?: 'Employee';
  /** An array relationship */
  Contracts: Array<Employee_Contract>;
  /** An aggregate relationship */
  Contracts_aggregate: Employee_Contract_Aggregate;
  /** An array relationship */
  Hardware: Array<Hardware>;
  /** An aggregate relationship */
  Hardware_aggregate: Hardware_Aggregate;
  /** An array relationship */
  Memberships: Array<Project_Member>;
  /** An aggregate relationship */
  Memberships_aggregate: Project_Member_Aggregate;
  avatar_url?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  mobile_phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  since_date?: Maybe<Scalars['time']>;
  skills?: Maybe<Scalars['json']>;
};


/** columns and relationships of "Employee" */
export type EmployeeContractsArgs = {
  distinct_on?: InputMaybe<Array<Employee_Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Contract_Order_By>>;
  where?: InputMaybe<Employee_Contract_Bool_Exp>;
};


/** columns and relationships of "Employee" */
export type EmployeeContracts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Contract_Order_By>>;
  where?: InputMaybe<Employee_Contract_Bool_Exp>;
};


/** columns and relationships of "Employee" */
export type EmployeeHardwareArgs = {
  distinct_on?: InputMaybe<Array<Hardware_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Order_By>>;
  where?: InputMaybe<Hardware_Bool_Exp>;
};


/** columns and relationships of "Employee" */
export type EmployeeHardware_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hardware_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Order_By>>;
  where?: InputMaybe<Hardware_Bool_Exp>;
};


/** columns and relationships of "Employee" */
export type EmployeeMembershipsArgs = {
  distinct_on?: InputMaybe<Array<Project_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Member_Order_By>>;
  where?: InputMaybe<Project_Member_Bool_Exp>;
};


/** columns and relationships of "Employee" */
export type EmployeeMemberships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Member_Order_By>>;
  where?: InputMaybe<Project_Member_Bool_Exp>;
};


/** columns and relationships of "Employee" */
export type EmployeeSkillsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "Employee_Contract" */
export type Employee_Contract = {
  __typename?: 'Employee_Contract';
  /** An object relationship */
  Employee?: Maybe<Employee>;
  employee_id?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  hours_friday?: Maybe<Scalars['numeric']>;
  hours_monday?: Maybe<Scalars['numeric']>;
  hours_thursday?: Maybe<Scalars['numeric']>;
  hours_tuesday?: Maybe<Scalars['numeric']>;
  hours_wednesday?: Maybe<Scalars['numeric']>;
  id: Scalars['Int'];
  start_date?: Maybe<Scalars['date']>;
  wage_per_hour?: Maybe<Scalars['numeric']>;
  wage_total?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "Employee_Contract" */
export type Employee_Contract_Aggregate = {
  __typename?: 'Employee_Contract_aggregate';
  aggregate?: Maybe<Employee_Contract_Aggregate_Fields>;
  nodes: Array<Employee_Contract>;
};

export type Employee_Contract_Aggregate_Bool_Exp = {
  count?: InputMaybe<Employee_Contract_Aggregate_Bool_Exp_Count>;
};

export type Employee_Contract_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Employee_Contract_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employee_Contract_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Employee_Contract" */
export type Employee_Contract_Aggregate_Fields = {
  __typename?: 'Employee_Contract_aggregate_fields';
  avg?: Maybe<Employee_Contract_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Employee_Contract_Max_Fields>;
  min?: Maybe<Employee_Contract_Min_Fields>;
  stddev?: Maybe<Employee_Contract_Stddev_Fields>;
  stddev_pop?: Maybe<Employee_Contract_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Employee_Contract_Stddev_Samp_Fields>;
  sum?: Maybe<Employee_Contract_Sum_Fields>;
  var_pop?: Maybe<Employee_Contract_Var_Pop_Fields>;
  var_samp?: Maybe<Employee_Contract_Var_Samp_Fields>;
  variance?: Maybe<Employee_Contract_Variance_Fields>;
};


/** aggregate fields of "Employee_Contract" */
export type Employee_Contract_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Employee_Contract_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Employee_Contract" */
export type Employee_Contract_Aggregate_Order_By = {
  avg?: InputMaybe<Employee_Contract_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Employee_Contract_Max_Order_By>;
  min?: InputMaybe<Employee_Contract_Min_Order_By>;
  stddev?: InputMaybe<Employee_Contract_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Employee_Contract_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Employee_Contract_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Employee_Contract_Sum_Order_By>;
  var_pop?: InputMaybe<Employee_Contract_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Employee_Contract_Var_Samp_Order_By>;
  variance?: InputMaybe<Employee_Contract_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Employee_Contract" */
export type Employee_Contract_Arr_Rel_Insert_Input = {
  data: Array<Employee_Contract_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Employee_Contract_On_Conflict>;
};

/** aggregate avg on columns */
export type Employee_Contract_Avg_Fields = {
  __typename?: 'Employee_Contract_avg_fields';
  hours_friday?: Maybe<Scalars['Float']>;
  hours_monday?: Maybe<Scalars['Float']>;
  hours_thursday?: Maybe<Scalars['Float']>;
  hours_tuesday?: Maybe<Scalars['Float']>;
  hours_wednesday?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wage_per_hour?: Maybe<Scalars['Float']>;
  wage_total?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Employee_Contract" */
export type Employee_Contract_Avg_Order_By = {
  hours_friday?: InputMaybe<Order_By>;
  hours_monday?: InputMaybe<Order_By>;
  hours_thursday?: InputMaybe<Order_By>;
  hours_tuesday?: InputMaybe<Order_By>;
  hours_wednesday?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  wage_per_hour?: InputMaybe<Order_By>;
  wage_total?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Employee_Contract". All fields are combined with a logical 'AND'. */
export type Employee_Contract_Bool_Exp = {
  Employee?: InputMaybe<Employee_Bool_Exp>;
  _and?: InputMaybe<Array<Employee_Contract_Bool_Exp>>;
  _not?: InputMaybe<Employee_Contract_Bool_Exp>;
  _or?: InputMaybe<Array<Employee_Contract_Bool_Exp>>;
  employee_id?: InputMaybe<String_Comparison_Exp>;
  end_date?: InputMaybe<Date_Comparison_Exp>;
  hours_friday?: InputMaybe<Numeric_Comparison_Exp>;
  hours_monday?: InputMaybe<Numeric_Comparison_Exp>;
  hours_thursday?: InputMaybe<Numeric_Comparison_Exp>;
  hours_tuesday?: InputMaybe<Numeric_Comparison_Exp>;
  hours_wednesday?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
  wage_per_hour?: InputMaybe<Numeric_Comparison_Exp>;
  wage_total?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "Employee_Contract" */
export enum Employee_Contract_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmployeeContractPkey = 'Employee_Contract_pkey'
}

/** input type for incrementing numeric columns in table "Employee_Contract" */
export type Employee_Contract_Inc_Input = {
  hours_friday?: InputMaybe<Scalars['numeric']>;
  hours_monday?: InputMaybe<Scalars['numeric']>;
  hours_thursday?: InputMaybe<Scalars['numeric']>;
  hours_tuesday?: InputMaybe<Scalars['numeric']>;
  hours_wednesday?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  wage_per_hour?: InputMaybe<Scalars['numeric']>;
  wage_total?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "Employee_Contract" */
export type Employee_Contract_Insert_Input = {
  Employee?: InputMaybe<Employee_Obj_Rel_Insert_Input>;
  employee_id?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['date']>;
  hours_friday?: InputMaybe<Scalars['numeric']>;
  hours_monday?: InputMaybe<Scalars['numeric']>;
  hours_thursday?: InputMaybe<Scalars['numeric']>;
  hours_tuesday?: InputMaybe<Scalars['numeric']>;
  hours_wednesday?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['date']>;
  wage_per_hour?: InputMaybe<Scalars['numeric']>;
  wage_total?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Employee_Contract_Max_Fields = {
  __typename?: 'Employee_Contract_max_fields';
  employee_id?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  hours_friday?: Maybe<Scalars['numeric']>;
  hours_monday?: Maybe<Scalars['numeric']>;
  hours_thursday?: Maybe<Scalars['numeric']>;
  hours_tuesday?: Maybe<Scalars['numeric']>;
  hours_wednesday?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['date']>;
  wage_per_hour?: Maybe<Scalars['numeric']>;
  wage_total?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "Employee_Contract" */
export type Employee_Contract_Max_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  hours_friday?: InputMaybe<Order_By>;
  hours_monday?: InputMaybe<Order_By>;
  hours_thursday?: InputMaybe<Order_By>;
  hours_tuesday?: InputMaybe<Order_By>;
  hours_wednesday?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  wage_per_hour?: InputMaybe<Order_By>;
  wage_total?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Employee_Contract_Min_Fields = {
  __typename?: 'Employee_Contract_min_fields';
  employee_id?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  hours_friday?: Maybe<Scalars['numeric']>;
  hours_monday?: Maybe<Scalars['numeric']>;
  hours_thursday?: Maybe<Scalars['numeric']>;
  hours_tuesday?: Maybe<Scalars['numeric']>;
  hours_wednesday?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['date']>;
  wage_per_hour?: Maybe<Scalars['numeric']>;
  wage_total?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "Employee_Contract" */
export type Employee_Contract_Min_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  hours_friday?: InputMaybe<Order_By>;
  hours_monday?: InputMaybe<Order_By>;
  hours_thursday?: InputMaybe<Order_By>;
  hours_tuesday?: InputMaybe<Order_By>;
  hours_wednesday?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  wage_per_hour?: InputMaybe<Order_By>;
  wage_total?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Employee_Contract" */
export type Employee_Contract_Mutation_Response = {
  __typename?: 'Employee_Contract_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Employee_Contract>;
};

/** on_conflict condition type for table "Employee_Contract" */
export type Employee_Contract_On_Conflict = {
  constraint: Employee_Contract_Constraint;
  update_columns?: Array<Employee_Contract_Update_Column>;
  where?: InputMaybe<Employee_Contract_Bool_Exp>;
};

/** Ordering options when selecting data from "Employee_Contract". */
export type Employee_Contract_Order_By = {
  Employee?: InputMaybe<Employee_Order_By>;
  employee_id?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  hours_friday?: InputMaybe<Order_By>;
  hours_monday?: InputMaybe<Order_By>;
  hours_thursday?: InputMaybe<Order_By>;
  hours_tuesday?: InputMaybe<Order_By>;
  hours_wednesday?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  wage_per_hour?: InputMaybe<Order_By>;
  wage_total?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Employee_Contract */
export type Employee_Contract_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Employee_Contract" */
export enum Employee_Contract_Select_Column {
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  HoursFriday = 'hours_friday',
  /** column name */
  HoursMonday = 'hours_monday',
  /** column name */
  HoursThursday = 'hours_thursday',
  /** column name */
  HoursTuesday = 'hours_tuesday',
  /** column name */
  HoursWednesday = 'hours_wednesday',
  /** column name */
  Id = 'id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  WagePerHour = 'wage_per_hour',
  /** column name */
  WageTotal = 'wage_total'
}

/** input type for updating data in table "Employee_Contract" */
export type Employee_Contract_Set_Input = {
  employee_id?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['date']>;
  hours_friday?: InputMaybe<Scalars['numeric']>;
  hours_monday?: InputMaybe<Scalars['numeric']>;
  hours_thursday?: InputMaybe<Scalars['numeric']>;
  hours_tuesday?: InputMaybe<Scalars['numeric']>;
  hours_wednesday?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['date']>;
  wage_per_hour?: InputMaybe<Scalars['numeric']>;
  wage_total?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Employee_Contract_Stddev_Fields = {
  __typename?: 'Employee_Contract_stddev_fields';
  hours_friday?: Maybe<Scalars['Float']>;
  hours_monday?: Maybe<Scalars['Float']>;
  hours_thursday?: Maybe<Scalars['Float']>;
  hours_tuesday?: Maybe<Scalars['Float']>;
  hours_wednesday?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wage_per_hour?: Maybe<Scalars['Float']>;
  wage_total?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Employee_Contract" */
export type Employee_Contract_Stddev_Order_By = {
  hours_friday?: InputMaybe<Order_By>;
  hours_monday?: InputMaybe<Order_By>;
  hours_thursday?: InputMaybe<Order_By>;
  hours_tuesday?: InputMaybe<Order_By>;
  hours_wednesday?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  wage_per_hour?: InputMaybe<Order_By>;
  wage_total?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Employee_Contract_Stddev_Pop_Fields = {
  __typename?: 'Employee_Contract_stddev_pop_fields';
  hours_friday?: Maybe<Scalars['Float']>;
  hours_monday?: Maybe<Scalars['Float']>;
  hours_thursday?: Maybe<Scalars['Float']>;
  hours_tuesday?: Maybe<Scalars['Float']>;
  hours_wednesday?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wage_per_hour?: Maybe<Scalars['Float']>;
  wage_total?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Employee_Contract" */
export type Employee_Contract_Stddev_Pop_Order_By = {
  hours_friday?: InputMaybe<Order_By>;
  hours_monday?: InputMaybe<Order_By>;
  hours_thursday?: InputMaybe<Order_By>;
  hours_tuesday?: InputMaybe<Order_By>;
  hours_wednesday?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  wage_per_hour?: InputMaybe<Order_By>;
  wage_total?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Employee_Contract_Stddev_Samp_Fields = {
  __typename?: 'Employee_Contract_stddev_samp_fields';
  hours_friday?: Maybe<Scalars['Float']>;
  hours_monday?: Maybe<Scalars['Float']>;
  hours_thursday?: Maybe<Scalars['Float']>;
  hours_tuesday?: Maybe<Scalars['Float']>;
  hours_wednesday?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wage_per_hour?: Maybe<Scalars['Float']>;
  wage_total?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Employee_Contract" */
export type Employee_Contract_Stddev_Samp_Order_By = {
  hours_friday?: InputMaybe<Order_By>;
  hours_monday?: InputMaybe<Order_By>;
  hours_thursday?: InputMaybe<Order_By>;
  hours_tuesday?: InputMaybe<Order_By>;
  hours_wednesday?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  wage_per_hour?: InputMaybe<Order_By>;
  wage_total?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Employee_Contract" */
export type Employee_Contract_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Employee_Contract_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Employee_Contract_Stream_Cursor_Value_Input = {
  employee_id?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['date']>;
  hours_friday?: InputMaybe<Scalars['numeric']>;
  hours_monday?: InputMaybe<Scalars['numeric']>;
  hours_thursday?: InputMaybe<Scalars['numeric']>;
  hours_tuesday?: InputMaybe<Scalars['numeric']>;
  hours_wednesday?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['date']>;
  wage_per_hour?: InputMaybe<Scalars['numeric']>;
  wage_total?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Employee_Contract_Sum_Fields = {
  __typename?: 'Employee_Contract_sum_fields';
  hours_friday?: Maybe<Scalars['numeric']>;
  hours_monday?: Maybe<Scalars['numeric']>;
  hours_thursday?: Maybe<Scalars['numeric']>;
  hours_tuesday?: Maybe<Scalars['numeric']>;
  hours_wednesday?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  wage_per_hour?: Maybe<Scalars['numeric']>;
  wage_total?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "Employee_Contract" */
export type Employee_Contract_Sum_Order_By = {
  hours_friday?: InputMaybe<Order_By>;
  hours_monday?: InputMaybe<Order_By>;
  hours_thursday?: InputMaybe<Order_By>;
  hours_tuesday?: InputMaybe<Order_By>;
  hours_wednesday?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  wage_per_hour?: InputMaybe<Order_By>;
  wage_total?: InputMaybe<Order_By>;
};

/** update columns of table "Employee_Contract" */
export enum Employee_Contract_Update_Column {
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  HoursFriday = 'hours_friday',
  /** column name */
  HoursMonday = 'hours_monday',
  /** column name */
  HoursThursday = 'hours_thursday',
  /** column name */
  HoursTuesday = 'hours_tuesday',
  /** column name */
  HoursWednesday = 'hours_wednesday',
  /** column name */
  Id = 'id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  WagePerHour = 'wage_per_hour',
  /** column name */
  WageTotal = 'wage_total'
}

export type Employee_Contract_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Employee_Contract_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Employee_Contract_Set_Input>;
  /** filter the rows which have to be updated */
  where: Employee_Contract_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Employee_Contract_Var_Pop_Fields = {
  __typename?: 'Employee_Contract_var_pop_fields';
  hours_friday?: Maybe<Scalars['Float']>;
  hours_monday?: Maybe<Scalars['Float']>;
  hours_thursday?: Maybe<Scalars['Float']>;
  hours_tuesday?: Maybe<Scalars['Float']>;
  hours_wednesday?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wage_per_hour?: Maybe<Scalars['Float']>;
  wage_total?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Employee_Contract" */
export type Employee_Contract_Var_Pop_Order_By = {
  hours_friday?: InputMaybe<Order_By>;
  hours_monday?: InputMaybe<Order_By>;
  hours_thursday?: InputMaybe<Order_By>;
  hours_tuesday?: InputMaybe<Order_By>;
  hours_wednesday?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  wage_per_hour?: InputMaybe<Order_By>;
  wage_total?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Employee_Contract_Var_Samp_Fields = {
  __typename?: 'Employee_Contract_var_samp_fields';
  hours_friday?: Maybe<Scalars['Float']>;
  hours_monday?: Maybe<Scalars['Float']>;
  hours_thursday?: Maybe<Scalars['Float']>;
  hours_tuesday?: Maybe<Scalars['Float']>;
  hours_wednesday?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wage_per_hour?: Maybe<Scalars['Float']>;
  wage_total?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Employee_Contract" */
export type Employee_Contract_Var_Samp_Order_By = {
  hours_friday?: InputMaybe<Order_By>;
  hours_monday?: InputMaybe<Order_By>;
  hours_thursday?: InputMaybe<Order_By>;
  hours_tuesday?: InputMaybe<Order_By>;
  hours_wednesday?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  wage_per_hour?: InputMaybe<Order_By>;
  wage_total?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Employee_Contract_Variance_Fields = {
  __typename?: 'Employee_Contract_variance_fields';
  hours_friday?: Maybe<Scalars['Float']>;
  hours_monday?: Maybe<Scalars['Float']>;
  hours_thursday?: Maybe<Scalars['Float']>;
  hours_tuesday?: Maybe<Scalars['Float']>;
  hours_wednesday?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wage_per_hour?: Maybe<Scalars['Float']>;
  wage_total?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Employee_Contract" */
export type Employee_Contract_Variance_Order_By = {
  hours_friday?: InputMaybe<Order_By>;
  hours_monday?: InputMaybe<Order_By>;
  hours_thursday?: InputMaybe<Order_By>;
  hours_tuesday?: InputMaybe<Order_By>;
  hours_wednesday?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  wage_per_hour?: InputMaybe<Order_By>;
  wage_total?: InputMaybe<Order_By>;
};

/** aggregated selection of "Employee" */
export type Employee_Aggregate = {
  __typename?: 'Employee_aggregate';
  aggregate?: Maybe<Employee_Aggregate_Fields>;
  nodes: Array<Employee>;
};

/** aggregate fields of "Employee" */
export type Employee_Aggregate_Fields = {
  __typename?: 'Employee_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Employee_Max_Fields>;
  min?: Maybe<Employee_Min_Fields>;
};


/** aggregate fields of "Employee" */
export type Employee_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Employee_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "Employee". All fields are combined with a logical 'AND'. */
export type Employee_Bool_Exp = {
  Contracts?: InputMaybe<Employee_Contract_Bool_Exp>;
  Contracts_aggregate?: InputMaybe<Employee_Contract_Aggregate_Bool_Exp>;
  Hardware?: InputMaybe<Hardware_Bool_Exp>;
  Hardware_aggregate?: InputMaybe<Hardware_Aggregate_Bool_Exp>;
  Memberships?: InputMaybe<Project_Member_Bool_Exp>;
  Memberships_aggregate?: InputMaybe<Project_Member_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Employee_Bool_Exp>>;
  _not?: InputMaybe<Employee_Bool_Exp>;
  _or?: InputMaybe<Array<Employee_Bool_Exp>>;
  avatar_url?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  mobile_phone?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  since_date?: InputMaybe<Time_Comparison_Exp>;
  skills?: InputMaybe<Json_Comparison_Exp>;
};

/** unique or primary key constraints on table "Employee" */
export enum Employee_Constraint {
  /** unique or primary key constraint on columns "email" */
  EmployeePkey = 'Employee_pkey'
}

/** input type for inserting data into table "Employee" */
export type Employee_Insert_Input = {
  Contracts?: InputMaybe<Employee_Contract_Arr_Rel_Insert_Input>;
  Hardware?: InputMaybe<Hardware_Arr_Rel_Insert_Input>;
  Memberships?: InputMaybe<Project_Member_Arr_Rel_Insert_Input>;
  avatar_url?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  mobile_phone?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  since_date?: InputMaybe<Scalars['time']>;
  skills?: InputMaybe<Scalars['json']>;
};

/** aggregate max on columns */
export type Employee_Max_Fields = {
  __typename?: 'Employee_max_fields';
  avatar_url?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mobile_phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Employee_Min_Fields = {
  __typename?: 'Employee_min_fields';
  avatar_url?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mobile_phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Employee" */
export type Employee_Mutation_Response = {
  __typename?: 'Employee_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Employee>;
};

/** input type for inserting object relation for remote table "Employee" */
export type Employee_Obj_Rel_Insert_Input = {
  data: Employee_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Employee_On_Conflict>;
};

/** on_conflict condition type for table "Employee" */
export type Employee_On_Conflict = {
  constraint: Employee_Constraint;
  update_columns?: Array<Employee_Update_Column>;
  where?: InputMaybe<Employee_Bool_Exp>;
};

/** Ordering options when selecting data from "Employee". */
export type Employee_Order_By = {
  Contracts_aggregate?: InputMaybe<Employee_Contract_Aggregate_Order_By>;
  Hardware_aggregate?: InputMaybe<Hardware_Aggregate_Order_By>;
  Memberships_aggregate?: InputMaybe<Project_Member_Aggregate_Order_By>;
  avatar_url?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  mobile_phone?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  since_date?: InputMaybe<Order_By>;
  skills?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Employee */
export type Employee_Pk_Columns_Input = {
  email: Scalars['String'];
};

/** select columns of table "Employee" */
export enum Employee_Select_Column {
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  Email = 'email',
  /** column name */
  MobilePhone = 'mobile_phone',
  /** column name */
  Name = 'name',
  /** column name */
  SinceDate = 'since_date',
  /** column name */
  Skills = 'skills'
}

/** input type for updating data in table "Employee" */
export type Employee_Set_Input = {
  avatar_url?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  mobile_phone?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  since_date?: InputMaybe<Scalars['time']>;
  skills?: InputMaybe<Scalars['json']>;
};

/** Streaming cursor of the table "Employee" */
export type Employee_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Employee_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Employee_Stream_Cursor_Value_Input = {
  avatar_url?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  mobile_phone?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  since_date?: InputMaybe<Scalars['time']>;
  skills?: InputMaybe<Scalars['json']>;
};

/** update columns of table "Employee" */
export enum Employee_Update_Column {
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  Email = 'email',
  /** column name */
  MobilePhone = 'mobile_phone',
  /** column name */
  Name = 'name',
  /** column name */
  SinceDate = 'since_date',
  /** column name */
  Skills = 'skills'
}

export type Employee_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Employee_Set_Input>;
  /** filter the rows which have to be updated */
  where: Employee_Bool_Exp;
};

/** columns and relationships of "Hardware" */
export type Hardware = {
  __typename?: 'Hardware';
  /** An object relationship */
  Employee?: Maybe<Employee>;
  KARTON?: Maybe<Scalars['String']>;
  assigned_employee?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['timestamptz']>;
  date_updated?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  image?: Maybe<Scalars['uuid']>;
  invoice?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  net_new_price?: Maybe<Scalars['numeric']>;
  notes?: Maybe<Scalars['String']>;
  order_date?: Maybe<Scalars['date']>;
  order_link?: Maybe<Scalars['String']>;
  order_number?: Maybe<Scalars['String']>;
  serial_number?: Maybe<Scalars['String']>;
  specs?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_created?: Maybe<Scalars['uuid']>;
  user_updated?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "Hardware_Inventory" */
export type Hardware_Inventory = {
  __typename?: 'Hardware_Inventory';
  id: Scalars['Int'];
  image?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  stock?: Maybe<Scalars['Int']>;
  target_stock?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "Hardware_Inventory" */
export type Hardware_Inventory_Aggregate = {
  __typename?: 'Hardware_Inventory_aggregate';
  aggregate?: Maybe<Hardware_Inventory_Aggregate_Fields>;
  nodes: Array<Hardware_Inventory>;
};

/** aggregate fields of "Hardware_Inventory" */
export type Hardware_Inventory_Aggregate_Fields = {
  __typename?: 'Hardware_Inventory_aggregate_fields';
  avg?: Maybe<Hardware_Inventory_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Hardware_Inventory_Max_Fields>;
  min?: Maybe<Hardware_Inventory_Min_Fields>;
  stddev?: Maybe<Hardware_Inventory_Stddev_Fields>;
  stddev_pop?: Maybe<Hardware_Inventory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hardware_Inventory_Stddev_Samp_Fields>;
  sum?: Maybe<Hardware_Inventory_Sum_Fields>;
  var_pop?: Maybe<Hardware_Inventory_Var_Pop_Fields>;
  var_samp?: Maybe<Hardware_Inventory_Var_Samp_Fields>;
  variance?: Maybe<Hardware_Inventory_Variance_Fields>;
};


/** aggregate fields of "Hardware_Inventory" */
export type Hardware_Inventory_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hardware_Inventory_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Hardware_Inventory_Avg_Fields = {
  __typename?: 'Hardware_Inventory_avg_fields';
  id?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Float']>;
  target_stock?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Hardware_Inventory". All fields are combined with a logical 'AND'. */
export type Hardware_Inventory_Bool_Exp = {
  _and?: InputMaybe<Array<Hardware_Inventory_Bool_Exp>>;
  _not?: InputMaybe<Hardware_Inventory_Bool_Exp>;
  _or?: InputMaybe<Array<Hardware_Inventory_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  image?: InputMaybe<Uuid_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  stock?: InputMaybe<Int_Comparison_Exp>;
  target_stock?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Hardware_Inventory" */
export enum Hardware_Inventory_Constraint {
  /** unique or primary key constraint on columns "id" */
  HardwareInventoryPkey = 'Hardware_Inventory_pkey'
}

/** input type for incrementing numeric columns in table "Hardware_Inventory" */
export type Hardware_Inventory_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  stock?: InputMaybe<Scalars['Int']>;
  target_stock?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Hardware_Inventory" */
export type Hardware_Inventory_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  stock?: InputMaybe<Scalars['Int']>;
  target_stock?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Hardware_Inventory_Max_Fields = {
  __typename?: 'Hardware_Inventory_max_fields';
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  stock?: Maybe<Scalars['Int']>;
  target_stock?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Hardware_Inventory_Min_Fields = {
  __typename?: 'Hardware_Inventory_min_fields';
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  stock?: Maybe<Scalars['Int']>;
  target_stock?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "Hardware_Inventory" */
export type Hardware_Inventory_Mutation_Response = {
  __typename?: 'Hardware_Inventory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hardware_Inventory>;
};

/** on_conflict condition type for table "Hardware_Inventory" */
export type Hardware_Inventory_On_Conflict = {
  constraint: Hardware_Inventory_Constraint;
  update_columns?: Array<Hardware_Inventory_Update_Column>;
  where?: InputMaybe<Hardware_Inventory_Bool_Exp>;
};

/** Ordering options when selecting data from "Hardware_Inventory". */
export type Hardware_Inventory_Order_By = {
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  stock?: InputMaybe<Order_By>;
  target_stock?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Hardware_Inventory */
export type Hardware_Inventory_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Hardware_Inventory" */
export enum Hardware_Inventory_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Link = 'link',
  /** column name */
  Name = 'name',
  /** column name */
  Stock = 'stock',
  /** column name */
  TargetStock = 'target_stock'
}

/** input type for updating data in table "Hardware_Inventory" */
export type Hardware_Inventory_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  stock?: InputMaybe<Scalars['Int']>;
  target_stock?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Hardware_Inventory_Stddev_Fields = {
  __typename?: 'Hardware_Inventory_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Float']>;
  target_stock?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Hardware_Inventory_Stddev_Pop_Fields = {
  __typename?: 'Hardware_Inventory_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Float']>;
  target_stock?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Hardware_Inventory_Stddev_Samp_Fields = {
  __typename?: 'Hardware_Inventory_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Float']>;
  target_stock?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "Hardware_Inventory" */
export type Hardware_Inventory_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Hardware_Inventory_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hardware_Inventory_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  stock?: InputMaybe<Scalars['Int']>;
  target_stock?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Hardware_Inventory_Sum_Fields = {
  __typename?: 'Hardware_Inventory_sum_fields';
  id?: Maybe<Scalars['Int']>;
  stock?: Maybe<Scalars['Int']>;
  target_stock?: Maybe<Scalars['Int']>;
};

/** update columns of table "Hardware_Inventory" */
export enum Hardware_Inventory_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Link = 'link',
  /** column name */
  Name = 'name',
  /** column name */
  Stock = 'stock',
  /** column name */
  TargetStock = 'target_stock'
}

export type Hardware_Inventory_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Hardware_Inventory_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hardware_Inventory_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hardware_Inventory_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Hardware_Inventory_Var_Pop_Fields = {
  __typename?: 'Hardware_Inventory_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Float']>;
  target_stock?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Hardware_Inventory_Var_Samp_Fields = {
  __typename?: 'Hardware_Inventory_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Float']>;
  target_stock?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Hardware_Inventory_Variance_Fields = {
  __typename?: 'Hardware_Inventory_variance_fields';
  id?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Float']>;
  target_stock?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "Hardware" */
export type Hardware_Aggregate = {
  __typename?: 'Hardware_aggregate';
  aggregate?: Maybe<Hardware_Aggregate_Fields>;
  nodes: Array<Hardware>;
};

export type Hardware_Aggregate_Bool_Exp = {
  count?: InputMaybe<Hardware_Aggregate_Bool_Exp_Count>;
};

export type Hardware_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Hardware_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Hardware_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Hardware" */
export type Hardware_Aggregate_Fields = {
  __typename?: 'Hardware_aggregate_fields';
  avg?: Maybe<Hardware_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Hardware_Max_Fields>;
  min?: Maybe<Hardware_Min_Fields>;
  stddev?: Maybe<Hardware_Stddev_Fields>;
  stddev_pop?: Maybe<Hardware_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hardware_Stddev_Samp_Fields>;
  sum?: Maybe<Hardware_Sum_Fields>;
  var_pop?: Maybe<Hardware_Var_Pop_Fields>;
  var_samp?: Maybe<Hardware_Var_Samp_Fields>;
  variance?: Maybe<Hardware_Variance_Fields>;
};


/** aggregate fields of "Hardware" */
export type Hardware_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hardware_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Hardware" */
export type Hardware_Aggregate_Order_By = {
  avg?: InputMaybe<Hardware_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Hardware_Max_Order_By>;
  min?: InputMaybe<Hardware_Min_Order_By>;
  stddev?: InputMaybe<Hardware_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Hardware_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Hardware_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Hardware_Sum_Order_By>;
  var_pop?: InputMaybe<Hardware_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Hardware_Var_Samp_Order_By>;
  variance?: InputMaybe<Hardware_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Hardware" */
export type Hardware_Arr_Rel_Insert_Input = {
  data: Array<Hardware_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Hardware_On_Conflict>;
};

/** aggregate avg on columns */
export type Hardware_Avg_Fields = {
  __typename?: 'Hardware_avg_fields';
  net_new_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Hardware" */
export type Hardware_Avg_Order_By = {
  net_new_price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Hardware". All fields are combined with a logical 'AND'. */
export type Hardware_Bool_Exp = {
  Employee?: InputMaybe<Employee_Bool_Exp>;
  KARTON?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Hardware_Bool_Exp>>;
  _not?: InputMaybe<Hardware_Bool_Exp>;
  _or?: InputMaybe<Array<Hardware_Bool_Exp>>;
  assigned_employee?: InputMaybe<String_Comparison_Exp>;
  date_created?: InputMaybe<Timestamptz_Comparison_Exp>;
  date_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  image?: InputMaybe<Uuid_Comparison_Exp>;
  invoice?: InputMaybe<Uuid_Comparison_Exp>;
  location?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  net_new_price?: InputMaybe<Numeric_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  order_date?: InputMaybe<Date_Comparison_Exp>;
  order_link?: InputMaybe<String_Comparison_Exp>;
  order_number?: InputMaybe<String_Comparison_Exp>;
  serial_number?: InputMaybe<String_Comparison_Exp>;
  specs?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  user_created?: InputMaybe<Uuid_Comparison_Exp>;
  user_updated?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "Hardware" */
export enum Hardware_Constraint {
  /** unique or primary key constraint on columns "id" */
  HardwarePkey = 'Hardware_pkey'
}

/** input type for incrementing numeric columns in table "Hardware" */
export type Hardware_Inc_Input = {
  net_new_price?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "Hardware" */
export type Hardware_Insert_Input = {
  Employee?: InputMaybe<Employee_Obj_Rel_Insert_Input>;
  KARTON?: InputMaybe<Scalars['String']>;
  assigned_employee?: InputMaybe<Scalars['String']>;
  date_created?: InputMaybe<Scalars['timestamptz']>;
  date_updated?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['uuid']>;
  invoice?: InputMaybe<Scalars['uuid']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  net_new_price?: InputMaybe<Scalars['numeric']>;
  notes?: InputMaybe<Scalars['String']>;
  order_date?: InputMaybe<Scalars['date']>;
  order_link?: InputMaybe<Scalars['String']>;
  order_number?: InputMaybe<Scalars['String']>;
  serial_number?: InputMaybe<Scalars['String']>;
  specs?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Scalars['uuid']>;
  user_updated?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Hardware_Max_Fields = {
  __typename?: 'Hardware_max_fields';
  KARTON?: Maybe<Scalars['String']>;
  assigned_employee?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['timestamptz']>;
  date_updated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['uuid']>;
  invoice?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  net_new_price?: Maybe<Scalars['numeric']>;
  notes?: Maybe<Scalars['String']>;
  order_date?: Maybe<Scalars['date']>;
  order_link?: Maybe<Scalars['String']>;
  order_number?: Maybe<Scalars['String']>;
  serial_number?: Maybe<Scalars['String']>;
  specs?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_created?: Maybe<Scalars['uuid']>;
  user_updated?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "Hardware" */
export type Hardware_Max_Order_By = {
  KARTON?: InputMaybe<Order_By>;
  assigned_employee?: InputMaybe<Order_By>;
  date_created?: InputMaybe<Order_By>;
  date_updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  net_new_price?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  order_date?: InputMaybe<Order_By>;
  order_link?: InputMaybe<Order_By>;
  order_number?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  specs?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_created?: InputMaybe<Order_By>;
  user_updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Hardware_Min_Fields = {
  __typename?: 'Hardware_min_fields';
  KARTON?: Maybe<Scalars['String']>;
  assigned_employee?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['timestamptz']>;
  date_updated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['uuid']>;
  invoice?: Maybe<Scalars['uuid']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  net_new_price?: Maybe<Scalars['numeric']>;
  notes?: Maybe<Scalars['String']>;
  order_date?: Maybe<Scalars['date']>;
  order_link?: Maybe<Scalars['String']>;
  order_number?: Maybe<Scalars['String']>;
  serial_number?: Maybe<Scalars['String']>;
  specs?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_created?: Maybe<Scalars['uuid']>;
  user_updated?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "Hardware" */
export type Hardware_Min_Order_By = {
  KARTON?: InputMaybe<Order_By>;
  assigned_employee?: InputMaybe<Order_By>;
  date_created?: InputMaybe<Order_By>;
  date_updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  net_new_price?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  order_date?: InputMaybe<Order_By>;
  order_link?: InputMaybe<Order_By>;
  order_number?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  specs?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_created?: InputMaybe<Order_By>;
  user_updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Hardware" */
export type Hardware_Mutation_Response = {
  __typename?: 'Hardware_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hardware>;
};

/** on_conflict condition type for table "Hardware" */
export type Hardware_On_Conflict = {
  constraint: Hardware_Constraint;
  update_columns?: Array<Hardware_Update_Column>;
  where?: InputMaybe<Hardware_Bool_Exp>;
};

/** Ordering options when selecting data from "Hardware". */
export type Hardware_Order_By = {
  Employee?: InputMaybe<Employee_Order_By>;
  KARTON?: InputMaybe<Order_By>;
  assigned_employee?: InputMaybe<Order_By>;
  date_created?: InputMaybe<Order_By>;
  date_updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  net_new_price?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  order_date?: InputMaybe<Order_By>;
  order_link?: InputMaybe<Order_By>;
  order_number?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  specs?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_created?: InputMaybe<Order_By>;
  user_updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Hardware */
export type Hardware_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "Hardware" */
export enum Hardware_Select_Column {
  /** column name */
  Karton = 'KARTON',
  /** column name */
  AssignedEmployee = 'assigned_employee',
  /** column name */
  DateCreated = 'date_created',
  /** column name */
  DateUpdated = 'date_updated',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Invoice = 'invoice',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  NetNewPrice = 'net_new_price',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrderDate = 'order_date',
  /** column name */
  OrderLink = 'order_link',
  /** column name */
  OrderNumber = 'order_number',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  Specs = 'specs',
  /** column name */
  Type = 'type',
  /** column name */
  UserCreated = 'user_created',
  /** column name */
  UserUpdated = 'user_updated'
}

/** input type for updating data in table "Hardware" */
export type Hardware_Set_Input = {
  KARTON?: InputMaybe<Scalars['String']>;
  assigned_employee?: InputMaybe<Scalars['String']>;
  date_created?: InputMaybe<Scalars['timestamptz']>;
  date_updated?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['uuid']>;
  invoice?: InputMaybe<Scalars['uuid']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  net_new_price?: InputMaybe<Scalars['numeric']>;
  notes?: InputMaybe<Scalars['String']>;
  order_date?: InputMaybe<Scalars['date']>;
  order_link?: InputMaybe<Scalars['String']>;
  order_number?: InputMaybe<Scalars['String']>;
  serial_number?: InputMaybe<Scalars['String']>;
  specs?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Scalars['uuid']>;
  user_updated?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Hardware_Stddev_Fields = {
  __typename?: 'Hardware_stddev_fields';
  net_new_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Hardware" */
export type Hardware_Stddev_Order_By = {
  net_new_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Hardware_Stddev_Pop_Fields = {
  __typename?: 'Hardware_stddev_pop_fields';
  net_new_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Hardware" */
export type Hardware_Stddev_Pop_Order_By = {
  net_new_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Hardware_Stddev_Samp_Fields = {
  __typename?: 'Hardware_stddev_samp_fields';
  net_new_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Hardware" */
export type Hardware_Stddev_Samp_Order_By = {
  net_new_price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Hardware" */
export type Hardware_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Hardware_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hardware_Stream_Cursor_Value_Input = {
  KARTON?: InputMaybe<Scalars['String']>;
  assigned_employee?: InputMaybe<Scalars['String']>;
  date_created?: InputMaybe<Scalars['timestamptz']>;
  date_updated?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['uuid']>;
  invoice?: InputMaybe<Scalars['uuid']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  net_new_price?: InputMaybe<Scalars['numeric']>;
  notes?: InputMaybe<Scalars['String']>;
  order_date?: InputMaybe<Scalars['date']>;
  order_link?: InputMaybe<Scalars['String']>;
  order_number?: InputMaybe<Scalars['String']>;
  serial_number?: InputMaybe<Scalars['String']>;
  specs?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Scalars['uuid']>;
  user_updated?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Hardware_Sum_Fields = {
  __typename?: 'Hardware_sum_fields';
  net_new_price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "Hardware" */
export type Hardware_Sum_Order_By = {
  net_new_price?: InputMaybe<Order_By>;
};

/** update columns of table "Hardware" */
export enum Hardware_Update_Column {
  /** column name */
  Karton = 'KARTON',
  /** column name */
  AssignedEmployee = 'assigned_employee',
  /** column name */
  DateCreated = 'date_created',
  /** column name */
  DateUpdated = 'date_updated',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Invoice = 'invoice',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  NetNewPrice = 'net_new_price',
  /** column name */
  Notes = 'notes',
  /** column name */
  OrderDate = 'order_date',
  /** column name */
  OrderLink = 'order_link',
  /** column name */
  OrderNumber = 'order_number',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  Specs = 'specs',
  /** column name */
  Type = 'type',
  /** column name */
  UserCreated = 'user_created',
  /** column name */
  UserUpdated = 'user_updated'
}

export type Hardware_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Hardware_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hardware_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hardware_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Hardware_Var_Pop_Fields = {
  __typename?: 'Hardware_var_pop_fields';
  net_new_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Hardware" */
export type Hardware_Var_Pop_Order_By = {
  net_new_price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Hardware_Var_Samp_Fields = {
  __typename?: 'Hardware_var_samp_fields';
  net_new_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Hardware" */
export type Hardware_Var_Samp_Order_By = {
  net_new_price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Hardware_Variance_Fields = {
  __typename?: 'Hardware_variance_fields';
  net_new_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Hardware" */
export type Hardware_Variance_Order_By = {
  net_new_price?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** columns and relationships of "Project" */
export type Project = {
  __typename?: 'Project';
  /** An array relationship */
  Contracts: Array<Project_Contract>;
  /** An aggregate relationship */
  Contracts_aggregate: Project_Contract_Aggregate;
  /** An array relationship */
  Costs: Array<Project_Invoice_Cost>;
  /** An aggregate relationship */
  Costs_aggregate: Project_Invoice_Cost_Aggregate;
  /** An object relationship */
  Customer?: Maybe<Customer>;
  /** An array relationship */
  Everhour: Array<Project_To_Everhour>;
  /** An aggregate relationship */
  Everhour_aggregate: Project_To_Everhour_Aggregate;
  /** An array relationship */
  News: Array<Project_News>;
  /** An aggregate relationship */
  News_aggregate: Project_News_Aggregate;
  /** An array relationship */
  Project_Members: Array<Project_Member>;
  /** An aggregate relationship */
  Project_Members_aggregate: Project_Member_Aggregate;
  /** An array relationship */
  Services: Array<Project_Service>;
  /** An aggregate relationship */
  Services_aggregate: Project_Service_Aggregate;
  active?: Maybe<Scalars['Boolean']>;
  config_show_tasks_estimates?: Maybe<Scalars['Boolean']>;
  customer_id?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['timestamptz']>;
  date_updated?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamp']>;
  technologies?: Maybe<Scalars['json']>;
  user_created?: Maybe<Scalars['uuid']>;
  user_updated?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "Project" */
export type ProjectContractsArgs = {
  distinct_on?: InputMaybe<Array<Project_Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Contract_Order_By>>;
  where?: InputMaybe<Project_Contract_Bool_Exp>;
};


/** columns and relationships of "Project" */
export type ProjectContracts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Contract_Order_By>>;
  where?: InputMaybe<Project_Contract_Bool_Exp>;
};


/** columns and relationships of "Project" */
export type ProjectCostsArgs = {
  distinct_on?: InputMaybe<Array<Project_Invoice_Cost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Invoice_Cost_Order_By>>;
  where?: InputMaybe<Project_Invoice_Cost_Bool_Exp>;
};


/** columns and relationships of "Project" */
export type ProjectCosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Invoice_Cost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Invoice_Cost_Order_By>>;
  where?: InputMaybe<Project_Invoice_Cost_Bool_Exp>;
};


/** columns and relationships of "Project" */
export type ProjectEverhourArgs = {
  distinct_on?: InputMaybe<Array<Project_To_Everhour_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_To_Everhour_Order_By>>;
  where?: InputMaybe<Project_To_Everhour_Bool_Exp>;
};


/** columns and relationships of "Project" */
export type ProjectEverhour_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_To_Everhour_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_To_Everhour_Order_By>>;
  where?: InputMaybe<Project_To_Everhour_Bool_Exp>;
};


/** columns and relationships of "Project" */
export type ProjectNewsArgs = {
  distinct_on?: InputMaybe<Array<Project_News_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_News_Order_By>>;
  where?: InputMaybe<Project_News_Bool_Exp>;
};


/** columns and relationships of "Project" */
export type ProjectNews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_News_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_News_Order_By>>;
  where?: InputMaybe<Project_News_Bool_Exp>;
};


/** columns and relationships of "Project" */
export type ProjectProject_MembersArgs = {
  distinct_on?: InputMaybe<Array<Project_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Member_Order_By>>;
  where?: InputMaybe<Project_Member_Bool_Exp>;
};


/** columns and relationships of "Project" */
export type ProjectProject_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Member_Order_By>>;
  where?: InputMaybe<Project_Member_Bool_Exp>;
};


/** columns and relationships of "Project" */
export type ProjectServicesArgs = {
  distinct_on?: InputMaybe<Array<Project_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Service_Order_By>>;
  where?: InputMaybe<Project_Service_Bool_Exp>;
};


/** columns and relationships of "Project" */
export type ProjectServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Service_Order_By>>;
  where?: InputMaybe<Project_Service_Bool_Exp>;
};


/** columns and relationships of "Project" */
export type ProjectTechnologiesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "Project_Contract" */
export type Project_Contract = {
  __typename?: 'Project_Contract';
  /** An object relationship */
  Project?: Maybe<Project>;
  date_created?: Maybe<Scalars['timestamptz']>;
  date_updated?: Maybe<Scalars['timestamptz']>;
  down_payment?: Maybe<Scalars['numeric']>;
  end_date?: Maybe<Scalars['date']>;
  fixed_price?: Maybe<Scalars['numeric']>;
  id: Scalars['Int'];
  is_fixed_price?: Maybe<Scalars['Boolean']>;
  price_per_hour?: Maybe<Scalars['numeric']>;
  project_id?: Maybe<Scalars['Int']>;
  start_date: Scalars['date'];
  user_created?: Maybe<Scalars['uuid']>;
  user_updated?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "Project_Contract" */
export type Project_Contract_Aggregate = {
  __typename?: 'Project_Contract_aggregate';
  aggregate?: Maybe<Project_Contract_Aggregate_Fields>;
  nodes: Array<Project_Contract>;
};

export type Project_Contract_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Project_Contract_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Project_Contract_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Project_Contract_Aggregate_Bool_Exp_Count>;
};

export type Project_Contract_Aggregate_Bool_Exp_Bool_And = {
  arguments: Project_Contract_Select_Column_Project_Contract_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Contract_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Project_Contract_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Project_Contract_Select_Column_Project_Contract_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Contract_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Project_Contract_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Contract_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Contract_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Project_Contract" */
export type Project_Contract_Aggregate_Fields = {
  __typename?: 'Project_Contract_aggregate_fields';
  avg?: Maybe<Project_Contract_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Contract_Max_Fields>;
  min?: Maybe<Project_Contract_Min_Fields>;
  stddev?: Maybe<Project_Contract_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Contract_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Contract_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Contract_Sum_Fields>;
  var_pop?: Maybe<Project_Contract_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Contract_Var_Samp_Fields>;
  variance?: Maybe<Project_Contract_Variance_Fields>;
};


/** aggregate fields of "Project_Contract" */
export type Project_Contract_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Contract_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Project_Contract" */
export type Project_Contract_Aggregate_Order_By = {
  avg?: InputMaybe<Project_Contract_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Contract_Max_Order_By>;
  min?: InputMaybe<Project_Contract_Min_Order_By>;
  stddev?: InputMaybe<Project_Contract_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Project_Contract_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Project_Contract_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Project_Contract_Sum_Order_By>;
  var_pop?: InputMaybe<Project_Contract_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Project_Contract_Var_Samp_Order_By>;
  variance?: InputMaybe<Project_Contract_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Project_Contract" */
export type Project_Contract_Arr_Rel_Insert_Input = {
  data: Array<Project_Contract_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Contract_On_Conflict>;
};

/** aggregate avg on columns */
export type Project_Contract_Avg_Fields = {
  __typename?: 'Project_Contract_avg_fields';
  down_payment?: Maybe<Scalars['Float']>;
  fixed_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_per_hour?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Project_Contract" */
export type Project_Contract_Avg_Order_By = {
  down_payment?: InputMaybe<Order_By>;
  fixed_price?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price_per_hour?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Project_Contract". All fields are combined with a logical 'AND'. */
export type Project_Contract_Bool_Exp = {
  Project?: InputMaybe<Project_Bool_Exp>;
  _and?: InputMaybe<Array<Project_Contract_Bool_Exp>>;
  _not?: InputMaybe<Project_Contract_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Contract_Bool_Exp>>;
  date_created?: InputMaybe<Timestamptz_Comparison_Exp>;
  date_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  down_payment?: InputMaybe<Numeric_Comparison_Exp>;
  end_date?: InputMaybe<Date_Comparison_Exp>;
  fixed_price?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_fixed_price?: InputMaybe<Boolean_Comparison_Exp>;
  price_per_hour?: InputMaybe<Numeric_Comparison_Exp>;
  project_id?: InputMaybe<Int_Comparison_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
  user_created?: InputMaybe<Uuid_Comparison_Exp>;
  user_updated?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "Project_Contract" */
export enum Project_Contract_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectContractPkey = 'Project_Contract_pkey'
}

/** input type for incrementing numeric columns in table "Project_Contract" */
export type Project_Contract_Inc_Input = {
  down_payment?: InputMaybe<Scalars['numeric']>;
  fixed_price?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  price_per_hour?: InputMaybe<Scalars['numeric']>;
  project_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Project_Contract" */
export type Project_Contract_Insert_Input = {
  Project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  date_created?: InputMaybe<Scalars['timestamptz']>;
  date_updated?: InputMaybe<Scalars['timestamptz']>;
  down_payment?: InputMaybe<Scalars['numeric']>;
  end_date?: InputMaybe<Scalars['date']>;
  fixed_price?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  is_fixed_price?: InputMaybe<Scalars['Boolean']>;
  price_per_hour?: InputMaybe<Scalars['numeric']>;
  project_id?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['date']>;
  user_created?: InputMaybe<Scalars['uuid']>;
  user_updated?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Project_Contract_Max_Fields = {
  __typename?: 'Project_Contract_max_fields';
  date_created?: Maybe<Scalars['timestamptz']>;
  date_updated?: Maybe<Scalars['timestamptz']>;
  down_payment?: Maybe<Scalars['numeric']>;
  end_date?: Maybe<Scalars['date']>;
  fixed_price?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  price_per_hour?: Maybe<Scalars['numeric']>;
  project_id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['date']>;
  user_created?: Maybe<Scalars['uuid']>;
  user_updated?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "Project_Contract" */
export type Project_Contract_Max_Order_By = {
  date_created?: InputMaybe<Order_By>;
  date_updated?: InputMaybe<Order_By>;
  down_payment?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  fixed_price?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price_per_hour?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  user_created?: InputMaybe<Order_By>;
  user_updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Contract_Min_Fields = {
  __typename?: 'Project_Contract_min_fields';
  date_created?: Maybe<Scalars['timestamptz']>;
  date_updated?: Maybe<Scalars['timestamptz']>;
  down_payment?: Maybe<Scalars['numeric']>;
  end_date?: Maybe<Scalars['date']>;
  fixed_price?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  price_per_hour?: Maybe<Scalars['numeric']>;
  project_id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['date']>;
  user_created?: Maybe<Scalars['uuid']>;
  user_updated?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "Project_Contract" */
export type Project_Contract_Min_Order_By = {
  date_created?: InputMaybe<Order_By>;
  date_updated?: InputMaybe<Order_By>;
  down_payment?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  fixed_price?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price_per_hour?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  user_created?: InputMaybe<Order_By>;
  user_updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Project_Contract" */
export type Project_Contract_Mutation_Response = {
  __typename?: 'Project_Contract_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Contract>;
};

/** on_conflict condition type for table "Project_Contract" */
export type Project_Contract_On_Conflict = {
  constraint: Project_Contract_Constraint;
  update_columns?: Array<Project_Contract_Update_Column>;
  where?: InputMaybe<Project_Contract_Bool_Exp>;
};

/** Ordering options when selecting data from "Project_Contract". */
export type Project_Contract_Order_By = {
  Project?: InputMaybe<Project_Order_By>;
  date_created?: InputMaybe<Order_By>;
  date_updated?: InputMaybe<Order_By>;
  down_payment?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  fixed_price?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_fixed_price?: InputMaybe<Order_By>;
  price_per_hour?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  user_created?: InputMaybe<Order_By>;
  user_updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Project_Contract */
export type Project_Contract_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Project_Contract" */
export enum Project_Contract_Select_Column {
  /** column name */
  DateCreated = 'date_created',
  /** column name */
  DateUpdated = 'date_updated',
  /** column name */
  DownPayment = 'down_payment',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  FixedPrice = 'fixed_price',
  /** column name */
  Id = 'id',
  /** column name */
  IsFixedPrice = 'is_fixed_price',
  /** column name */
  PricePerHour = 'price_per_hour',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UserCreated = 'user_created',
  /** column name */
  UserUpdated = 'user_updated'
}

/** select "Project_Contract_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Project_Contract" */
export enum Project_Contract_Select_Column_Project_Contract_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsFixedPrice = 'is_fixed_price'
}

/** select "Project_Contract_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Project_Contract" */
export enum Project_Contract_Select_Column_Project_Contract_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsFixedPrice = 'is_fixed_price'
}

/** input type for updating data in table "Project_Contract" */
export type Project_Contract_Set_Input = {
  date_created?: InputMaybe<Scalars['timestamptz']>;
  date_updated?: InputMaybe<Scalars['timestamptz']>;
  down_payment?: InputMaybe<Scalars['numeric']>;
  end_date?: InputMaybe<Scalars['date']>;
  fixed_price?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  is_fixed_price?: InputMaybe<Scalars['Boolean']>;
  price_per_hour?: InputMaybe<Scalars['numeric']>;
  project_id?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['date']>;
  user_created?: InputMaybe<Scalars['uuid']>;
  user_updated?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Project_Contract_Stddev_Fields = {
  __typename?: 'Project_Contract_stddev_fields';
  down_payment?: Maybe<Scalars['Float']>;
  fixed_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_per_hour?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Project_Contract" */
export type Project_Contract_Stddev_Order_By = {
  down_payment?: InputMaybe<Order_By>;
  fixed_price?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price_per_hour?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Project_Contract_Stddev_Pop_Fields = {
  __typename?: 'Project_Contract_stddev_pop_fields';
  down_payment?: Maybe<Scalars['Float']>;
  fixed_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_per_hour?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Project_Contract" */
export type Project_Contract_Stddev_Pop_Order_By = {
  down_payment?: InputMaybe<Order_By>;
  fixed_price?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price_per_hour?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Project_Contract_Stddev_Samp_Fields = {
  __typename?: 'Project_Contract_stddev_samp_fields';
  down_payment?: Maybe<Scalars['Float']>;
  fixed_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_per_hour?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Project_Contract" */
export type Project_Contract_Stddev_Samp_Order_By = {
  down_payment?: InputMaybe<Order_By>;
  fixed_price?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price_per_hour?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Project_Contract" */
export type Project_Contract_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Contract_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Contract_Stream_Cursor_Value_Input = {
  date_created?: InputMaybe<Scalars['timestamptz']>;
  date_updated?: InputMaybe<Scalars['timestamptz']>;
  down_payment?: InputMaybe<Scalars['numeric']>;
  end_date?: InputMaybe<Scalars['date']>;
  fixed_price?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  is_fixed_price?: InputMaybe<Scalars['Boolean']>;
  price_per_hour?: InputMaybe<Scalars['numeric']>;
  project_id?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['date']>;
  user_created?: InputMaybe<Scalars['uuid']>;
  user_updated?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Project_Contract_Sum_Fields = {
  __typename?: 'Project_Contract_sum_fields';
  down_payment?: Maybe<Scalars['numeric']>;
  fixed_price?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  price_per_hour?: Maybe<Scalars['numeric']>;
  project_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Project_Contract" */
export type Project_Contract_Sum_Order_By = {
  down_payment?: InputMaybe<Order_By>;
  fixed_price?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price_per_hour?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** update columns of table "Project_Contract" */
export enum Project_Contract_Update_Column {
  /** column name */
  DateCreated = 'date_created',
  /** column name */
  DateUpdated = 'date_updated',
  /** column name */
  DownPayment = 'down_payment',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  FixedPrice = 'fixed_price',
  /** column name */
  Id = 'id',
  /** column name */
  IsFixedPrice = 'is_fixed_price',
  /** column name */
  PricePerHour = 'price_per_hour',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UserCreated = 'user_created',
  /** column name */
  UserUpdated = 'user_updated'
}

export type Project_Contract_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Contract_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Contract_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Contract_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Contract_Var_Pop_Fields = {
  __typename?: 'Project_Contract_var_pop_fields';
  down_payment?: Maybe<Scalars['Float']>;
  fixed_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_per_hour?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Project_Contract" */
export type Project_Contract_Var_Pop_Order_By = {
  down_payment?: InputMaybe<Order_By>;
  fixed_price?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price_per_hour?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Project_Contract_Var_Samp_Fields = {
  __typename?: 'Project_Contract_var_samp_fields';
  down_payment?: Maybe<Scalars['Float']>;
  fixed_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_per_hour?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Project_Contract" */
export type Project_Contract_Var_Samp_Order_By = {
  down_payment?: InputMaybe<Order_By>;
  fixed_price?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price_per_hour?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Project_Contract_Variance_Fields = {
  __typename?: 'Project_Contract_variance_fields';
  down_payment?: Maybe<Scalars['Float']>;
  fixed_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_per_hour?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Project_Contract" */
export type Project_Contract_Variance_Order_By = {
  down_payment?: InputMaybe<Order_By>;
  fixed_price?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price_per_hour?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "Project_Invoice" */
export type Project_Invoice = {
  __typename?: 'Project_Invoice';
  /** An array relationship */
  Project_Costs: Array<Project_Invoice_Cost>;
  /** An aggregate relationship */
  Project_Costs_aggregate: Project_Invoice_Cost_Aggregate;
  amount_eur?: Maybe<Scalars['numeric']>;
  client_name?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  invoice_date?: Maybe<Scalars['date']>;
  invoice_file?: Maybe<Scalars['uuid']>;
  paid?: Maybe<Scalars['Boolean']>;
  short_description?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  user_created?: Maybe<Scalars['uuid']>;
  visible?: Maybe<Scalars['Boolean']>;
};


/** columns and relationships of "Project_Invoice" */
export type Project_InvoiceProject_CostsArgs = {
  distinct_on?: InputMaybe<Array<Project_Invoice_Cost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Invoice_Cost_Order_By>>;
  where?: InputMaybe<Project_Invoice_Cost_Bool_Exp>;
};


/** columns and relationships of "Project_Invoice" */
export type Project_InvoiceProject_Costs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Invoice_Cost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Invoice_Cost_Order_By>>;
  where?: InputMaybe<Project_Invoice_Cost_Bool_Exp>;
};

/** columns and relationships of "Project_Invoice_Cost" */
export type Project_Invoice_Cost = {
  __typename?: 'Project_Invoice_Cost';
  /** An object relationship */
  Invoice: Project_Invoice;
  /** An object relationship */
  Project: Project;
  amount_eur: Scalars['numeric'];
  id: Scalars['Int'];
  invoice_id: Scalars['String'];
  project_id: Scalars['Int'];
};

/** aggregated selection of "Project_Invoice_Cost" */
export type Project_Invoice_Cost_Aggregate = {
  __typename?: 'Project_Invoice_Cost_aggregate';
  aggregate?: Maybe<Project_Invoice_Cost_Aggregate_Fields>;
  nodes: Array<Project_Invoice_Cost>;
};

export type Project_Invoice_Cost_Aggregate_Bool_Exp = {
  count?: InputMaybe<Project_Invoice_Cost_Aggregate_Bool_Exp_Count>;
};

export type Project_Invoice_Cost_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Invoice_Cost_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Invoice_Cost_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Project_Invoice_Cost" */
export type Project_Invoice_Cost_Aggregate_Fields = {
  __typename?: 'Project_Invoice_Cost_aggregate_fields';
  avg?: Maybe<Project_Invoice_Cost_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Invoice_Cost_Max_Fields>;
  min?: Maybe<Project_Invoice_Cost_Min_Fields>;
  stddev?: Maybe<Project_Invoice_Cost_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Invoice_Cost_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Invoice_Cost_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Invoice_Cost_Sum_Fields>;
  var_pop?: Maybe<Project_Invoice_Cost_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Invoice_Cost_Var_Samp_Fields>;
  variance?: Maybe<Project_Invoice_Cost_Variance_Fields>;
};


/** aggregate fields of "Project_Invoice_Cost" */
export type Project_Invoice_Cost_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Invoice_Cost_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Project_Invoice_Cost" */
export type Project_Invoice_Cost_Aggregate_Order_By = {
  avg?: InputMaybe<Project_Invoice_Cost_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Invoice_Cost_Max_Order_By>;
  min?: InputMaybe<Project_Invoice_Cost_Min_Order_By>;
  stddev?: InputMaybe<Project_Invoice_Cost_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Project_Invoice_Cost_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Project_Invoice_Cost_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Project_Invoice_Cost_Sum_Order_By>;
  var_pop?: InputMaybe<Project_Invoice_Cost_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Project_Invoice_Cost_Var_Samp_Order_By>;
  variance?: InputMaybe<Project_Invoice_Cost_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Project_Invoice_Cost" */
export type Project_Invoice_Cost_Arr_Rel_Insert_Input = {
  data: Array<Project_Invoice_Cost_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Invoice_Cost_On_Conflict>;
};

/** aggregate avg on columns */
export type Project_Invoice_Cost_Avg_Fields = {
  __typename?: 'Project_Invoice_Cost_avg_fields';
  amount_eur?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Project_Invoice_Cost" */
export type Project_Invoice_Cost_Avg_Order_By = {
  amount_eur?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Project_Invoice_Cost". All fields are combined with a logical 'AND'. */
export type Project_Invoice_Cost_Bool_Exp = {
  Invoice?: InputMaybe<Project_Invoice_Bool_Exp>;
  Project?: InputMaybe<Project_Bool_Exp>;
  _and?: InputMaybe<Array<Project_Invoice_Cost_Bool_Exp>>;
  _not?: InputMaybe<Project_Invoice_Cost_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Invoice_Cost_Bool_Exp>>;
  amount_eur?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  invoice_id?: InputMaybe<String_Comparison_Exp>;
  project_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Project_Invoice_Cost" */
export enum Project_Invoice_Cost_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectInvoiceCostPkey = 'Project_Invoice_Cost_pkey'
}

/** input type for incrementing numeric columns in table "Project_Invoice_Cost" */
export type Project_Invoice_Cost_Inc_Input = {
  amount_eur?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Project_Invoice_Cost" */
export type Project_Invoice_Cost_Insert_Input = {
  Invoice?: InputMaybe<Project_Invoice_Obj_Rel_Insert_Input>;
  Project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  amount_eur?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Project_Invoice_Cost_Max_Fields = {
  __typename?: 'Project_Invoice_Cost_max_fields';
  amount_eur?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  invoice_id?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Project_Invoice_Cost" */
export type Project_Invoice_Cost_Max_Order_By = {
  amount_eur?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Invoice_Cost_Min_Fields = {
  __typename?: 'Project_Invoice_Cost_min_fields';
  amount_eur?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  invoice_id?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Project_Invoice_Cost" */
export type Project_Invoice_Cost_Min_Order_By = {
  amount_eur?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Project_Invoice_Cost" */
export type Project_Invoice_Cost_Mutation_Response = {
  __typename?: 'Project_Invoice_Cost_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Invoice_Cost>;
};

/** on_conflict condition type for table "Project_Invoice_Cost" */
export type Project_Invoice_Cost_On_Conflict = {
  constraint: Project_Invoice_Cost_Constraint;
  update_columns?: Array<Project_Invoice_Cost_Update_Column>;
  where?: InputMaybe<Project_Invoice_Cost_Bool_Exp>;
};

/** Ordering options when selecting data from "Project_Invoice_Cost". */
export type Project_Invoice_Cost_Order_By = {
  Invoice?: InputMaybe<Project_Invoice_Order_By>;
  Project?: InputMaybe<Project_Order_By>;
  amount_eur?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Project_Invoice_Cost */
export type Project_Invoice_Cost_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Project_Invoice_Cost" */
export enum Project_Invoice_Cost_Select_Column {
  /** column name */
  AmountEur = 'amount_eur',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  ProjectId = 'project_id'
}

/** input type for updating data in table "Project_Invoice_Cost" */
export type Project_Invoice_Cost_Set_Input = {
  amount_eur?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Project_Invoice_Cost_Stddev_Fields = {
  __typename?: 'Project_Invoice_Cost_stddev_fields';
  amount_eur?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Project_Invoice_Cost" */
export type Project_Invoice_Cost_Stddev_Order_By = {
  amount_eur?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Project_Invoice_Cost_Stddev_Pop_Fields = {
  __typename?: 'Project_Invoice_Cost_stddev_pop_fields';
  amount_eur?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Project_Invoice_Cost" */
export type Project_Invoice_Cost_Stddev_Pop_Order_By = {
  amount_eur?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Project_Invoice_Cost_Stddev_Samp_Fields = {
  __typename?: 'Project_Invoice_Cost_stddev_samp_fields';
  amount_eur?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Project_Invoice_Cost" */
export type Project_Invoice_Cost_Stddev_Samp_Order_By = {
  amount_eur?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Project_Invoice_Cost" */
export type Project_Invoice_Cost_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Invoice_Cost_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Invoice_Cost_Stream_Cursor_Value_Input = {
  amount_eur?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Project_Invoice_Cost_Sum_Fields = {
  __typename?: 'Project_Invoice_Cost_sum_fields';
  amount_eur?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Project_Invoice_Cost" */
export type Project_Invoice_Cost_Sum_Order_By = {
  amount_eur?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** update columns of table "Project_Invoice_Cost" */
export enum Project_Invoice_Cost_Update_Column {
  /** column name */
  AmountEur = 'amount_eur',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  ProjectId = 'project_id'
}

export type Project_Invoice_Cost_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Invoice_Cost_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Invoice_Cost_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Invoice_Cost_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Invoice_Cost_Var_Pop_Fields = {
  __typename?: 'Project_Invoice_Cost_var_pop_fields';
  amount_eur?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Project_Invoice_Cost" */
export type Project_Invoice_Cost_Var_Pop_Order_By = {
  amount_eur?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Project_Invoice_Cost_Var_Samp_Fields = {
  __typename?: 'Project_Invoice_Cost_var_samp_fields';
  amount_eur?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Project_Invoice_Cost" */
export type Project_Invoice_Cost_Var_Samp_Order_By = {
  amount_eur?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Project_Invoice_Cost_Variance_Fields = {
  __typename?: 'Project_Invoice_Cost_variance_fields';
  amount_eur?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Project_Invoice_Cost" */
export type Project_Invoice_Cost_Variance_Order_By = {
  amount_eur?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregated selection of "Project_Invoice" */
export type Project_Invoice_Aggregate = {
  __typename?: 'Project_Invoice_aggregate';
  aggregate?: Maybe<Project_Invoice_Aggregate_Fields>;
  nodes: Array<Project_Invoice>;
};

/** aggregate fields of "Project_Invoice" */
export type Project_Invoice_Aggregate_Fields = {
  __typename?: 'Project_Invoice_aggregate_fields';
  avg?: Maybe<Project_Invoice_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Invoice_Max_Fields>;
  min?: Maybe<Project_Invoice_Min_Fields>;
  stddev?: Maybe<Project_Invoice_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Invoice_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Invoice_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Invoice_Sum_Fields>;
  var_pop?: Maybe<Project_Invoice_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Invoice_Var_Samp_Fields>;
  variance?: Maybe<Project_Invoice_Variance_Fields>;
};


/** aggregate fields of "Project_Invoice" */
export type Project_Invoice_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Invoice_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Project_Invoice_Avg_Fields = {
  __typename?: 'Project_Invoice_avg_fields';
  amount_eur?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Project_Invoice". All fields are combined with a logical 'AND'. */
export type Project_Invoice_Bool_Exp = {
  Project_Costs?: InputMaybe<Project_Invoice_Cost_Bool_Exp>;
  Project_Costs_aggregate?: InputMaybe<Project_Invoice_Cost_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Project_Invoice_Bool_Exp>>;
  _not?: InputMaybe<Project_Invoice_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Invoice_Bool_Exp>>;
  amount_eur?: InputMaybe<Numeric_Comparison_Exp>;
  client_name?: InputMaybe<String_Comparison_Exp>;
  date_created?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  invoice_date?: InputMaybe<Date_Comparison_Exp>;
  invoice_file?: InputMaybe<Uuid_Comparison_Exp>;
  paid?: InputMaybe<Boolean_Comparison_Exp>;
  short_description?: InputMaybe<String_Comparison_Exp>;
  summary?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  user_created?: InputMaybe<Uuid_Comparison_Exp>;
  visible?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "Project_Invoice" */
export enum Project_Invoice_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectInvoicePkey = 'Project_Invoice_pkey'
}

/** input type for incrementing numeric columns in table "Project_Invoice" */
export type Project_Invoice_Inc_Input = {
  amount_eur?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "Project_Invoice" */
export type Project_Invoice_Insert_Input = {
  Project_Costs?: InputMaybe<Project_Invoice_Cost_Arr_Rel_Insert_Input>;
  amount_eur?: InputMaybe<Scalars['numeric']>;
  client_name?: InputMaybe<Scalars['String']>;
  date_created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  invoice_date?: InputMaybe<Scalars['date']>;
  invoice_file?: InputMaybe<Scalars['uuid']>;
  paid?: InputMaybe<Scalars['Boolean']>;
  short_description?: InputMaybe<Scalars['String']>;
  summary?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Scalars['uuid']>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Project_Invoice_Max_Fields = {
  __typename?: 'Project_Invoice_max_fields';
  amount_eur?: Maybe<Scalars['numeric']>;
  client_name?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  invoice_date?: Maybe<Scalars['date']>;
  invoice_file?: Maybe<Scalars['uuid']>;
  short_description?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  user_created?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Project_Invoice_Min_Fields = {
  __typename?: 'Project_Invoice_min_fields';
  amount_eur?: Maybe<Scalars['numeric']>;
  client_name?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  invoice_date?: Maybe<Scalars['date']>;
  invoice_file?: Maybe<Scalars['uuid']>;
  short_description?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  user_created?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "Project_Invoice" */
export type Project_Invoice_Mutation_Response = {
  __typename?: 'Project_Invoice_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Invoice>;
};

/** input type for inserting object relation for remote table "Project_Invoice" */
export type Project_Invoice_Obj_Rel_Insert_Input = {
  data: Project_Invoice_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Invoice_On_Conflict>;
};

/** on_conflict condition type for table "Project_Invoice" */
export type Project_Invoice_On_Conflict = {
  constraint: Project_Invoice_Constraint;
  update_columns?: Array<Project_Invoice_Update_Column>;
  where?: InputMaybe<Project_Invoice_Bool_Exp>;
};

/** Ordering options when selecting data from "Project_Invoice". */
export type Project_Invoice_Order_By = {
  Project_Costs_aggregate?: InputMaybe<Project_Invoice_Cost_Aggregate_Order_By>;
  amount_eur?: InputMaybe<Order_By>;
  client_name?: InputMaybe<Order_By>;
  date_created?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_date?: InputMaybe<Order_By>;
  invoice_file?: InputMaybe<Order_By>;
  paid?: InputMaybe<Order_By>;
  short_description?: InputMaybe<Order_By>;
  summary?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  user_created?: InputMaybe<Order_By>;
  visible?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Project_Invoice */
export type Project_Invoice_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "Project_Invoice" */
export enum Project_Invoice_Select_Column {
  /** column name */
  AmountEur = 'amount_eur',
  /** column name */
  ClientName = 'client_name',
  /** column name */
  DateCreated = 'date_created',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceDate = 'invoice_date',
  /** column name */
  InvoiceFile = 'invoice_file',
  /** column name */
  Paid = 'paid',
  /** column name */
  ShortDescription = 'short_description',
  /** column name */
  Summary = 'summary',
  /** column name */
  Title = 'title',
  /** column name */
  UserCreated = 'user_created',
  /** column name */
  Visible = 'visible'
}

/** input type for updating data in table "Project_Invoice" */
export type Project_Invoice_Set_Input = {
  amount_eur?: InputMaybe<Scalars['numeric']>;
  client_name?: InputMaybe<Scalars['String']>;
  date_created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  invoice_date?: InputMaybe<Scalars['date']>;
  invoice_file?: InputMaybe<Scalars['uuid']>;
  paid?: InputMaybe<Scalars['Boolean']>;
  short_description?: InputMaybe<Scalars['String']>;
  summary?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Scalars['uuid']>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Project_Invoice_Stddev_Fields = {
  __typename?: 'Project_Invoice_stddev_fields';
  amount_eur?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Project_Invoice_Stddev_Pop_Fields = {
  __typename?: 'Project_Invoice_stddev_pop_fields';
  amount_eur?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Project_Invoice_Stddev_Samp_Fields = {
  __typename?: 'Project_Invoice_stddev_samp_fields';
  amount_eur?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "Project_Invoice" */
export type Project_Invoice_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Invoice_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Invoice_Stream_Cursor_Value_Input = {
  amount_eur?: InputMaybe<Scalars['numeric']>;
  client_name?: InputMaybe<Scalars['String']>;
  date_created?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  invoice_date?: InputMaybe<Scalars['date']>;
  invoice_file?: InputMaybe<Scalars['uuid']>;
  paid?: InputMaybe<Scalars['Boolean']>;
  short_description?: InputMaybe<Scalars['String']>;
  summary?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  user_created?: InputMaybe<Scalars['uuid']>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Project_Invoice_Sum_Fields = {
  __typename?: 'Project_Invoice_sum_fields';
  amount_eur?: Maybe<Scalars['numeric']>;
};

/** update columns of table "Project_Invoice" */
export enum Project_Invoice_Update_Column {
  /** column name */
  AmountEur = 'amount_eur',
  /** column name */
  ClientName = 'client_name',
  /** column name */
  DateCreated = 'date_created',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceDate = 'invoice_date',
  /** column name */
  InvoiceFile = 'invoice_file',
  /** column name */
  Paid = 'paid',
  /** column name */
  ShortDescription = 'short_description',
  /** column name */
  Summary = 'summary',
  /** column name */
  Title = 'title',
  /** column name */
  UserCreated = 'user_created',
  /** column name */
  Visible = 'visible'
}

export type Project_Invoice_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Invoice_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Invoice_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Invoice_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Invoice_Var_Pop_Fields = {
  __typename?: 'Project_Invoice_var_pop_fields';
  amount_eur?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Project_Invoice_Var_Samp_Fields = {
  __typename?: 'Project_Invoice_var_samp_fields';
  amount_eur?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Project_Invoice_Variance_Fields = {
  __typename?: 'Project_Invoice_variance_fields';
  amount_eur?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Project_Member" */
export type Project_Member = {
  __typename?: 'Project_Member';
  /** An object relationship */
  Employee: Employee;
  /** An object relationship */
  Project: Project;
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  end_date?: Maybe<Scalars['date']>;
  id: Scalars['Int'];
  project_id: Scalars['Int'];
  role_activity?: Maybe<Scalars['String']>;
  role_area?: Maybe<Scalars['String']>;
  role_position?: Maybe<Scalars['String']>;
  start_date: Scalars['date'];
};

/** aggregated selection of "Project_Member" */
export type Project_Member_Aggregate = {
  __typename?: 'Project_Member_aggregate';
  aggregate?: Maybe<Project_Member_Aggregate_Fields>;
  nodes: Array<Project_Member>;
};

export type Project_Member_Aggregate_Bool_Exp = {
  count?: InputMaybe<Project_Member_Aggregate_Bool_Exp_Count>;
};

export type Project_Member_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Member_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Project_Member" */
export type Project_Member_Aggregate_Fields = {
  __typename?: 'Project_Member_aggregate_fields';
  avg?: Maybe<Project_Member_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Member_Max_Fields>;
  min?: Maybe<Project_Member_Min_Fields>;
  stddev?: Maybe<Project_Member_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Member_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Member_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Member_Sum_Fields>;
  var_pop?: Maybe<Project_Member_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Member_Var_Samp_Fields>;
  variance?: Maybe<Project_Member_Variance_Fields>;
};


/** aggregate fields of "Project_Member" */
export type Project_Member_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Project_Member" */
export type Project_Member_Aggregate_Order_By = {
  avg?: InputMaybe<Project_Member_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Member_Max_Order_By>;
  min?: InputMaybe<Project_Member_Min_Order_By>;
  stddev?: InputMaybe<Project_Member_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Project_Member_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Project_Member_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Project_Member_Sum_Order_By>;
  var_pop?: InputMaybe<Project_Member_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Project_Member_Var_Samp_Order_By>;
  variance?: InputMaybe<Project_Member_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Project_Member" */
export type Project_Member_Arr_Rel_Insert_Input = {
  data: Array<Project_Member_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Member_On_Conflict>;
};

/** aggregate avg on columns */
export type Project_Member_Avg_Fields = {
  __typename?: 'Project_Member_avg_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Project_Member" */
export type Project_Member_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Project_Member". All fields are combined with a logical 'AND'. */
export type Project_Member_Bool_Exp = {
  Employee?: InputMaybe<Employee_Bool_Exp>;
  Project?: InputMaybe<Project_Bool_Exp>;
  _and?: InputMaybe<Array<Project_Member_Bool_Exp>>;
  _not?: InputMaybe<Project_Member_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Member_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  end_date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  project_id?: InputMaybe<Int_Comparison_Exp>;
  role_activity?: InputMaybe<String_Comparison_Exp>;
  role_area?: InputMaybe<String_Comparison_Exp>;
  role_position?: InputMaybe<String_Comparison_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
};

/** unique or primary key constraints on table "Project_Member" */
export enum Project_Member_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectMemberPkey = 'Project_Member_pkey'
}

/** input type for incrementing numeric columns in table "Project_Member" */
export type Project_Member_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Project_Member" */
export type Project_Member_Insert_Input = {
  Employee?: InputMaybe<Employee_Obj_Rel_Insert_Input>;
  Project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['Int']>;
  role_activity?: InputMaybe<Scalars['String']>;
  role_area?: InputMaybe<Scalars['String']>;
  role_position?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['date']>;
};

/** aggregate max on columns */
export type Project_Member_Max_Fields = {
  __typename?: 'Project_Member_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['Int']>;
  role_activity?: Maybe<Scalars['String']>;
  role_area?: Maybe<Scalars['String']>;
  role_position?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
};

/** order by max() on columns of table "Project_Member" */
export type Project_Member_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  role_activity?: InputMaybe<Order_By>;
  role_area?: InputMaybe<Order_By>;
  role_position?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Member_Min_Fields = {
  __typename?: 'Project_Member_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['Int']>;
  role_activity?: Maybe<Scalars['String']>;
  role_area?: Maybe<Scalars['String']>;
  role_position?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
};

/** order by min() on columns of table "Project_Member" */
export type Project_Member_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  role_activity?: InputMaybe<Order_By>;
  role_area?: InputMaybe<Order_By>;
  role_position?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Project_Member" */
export type Project_Member_Mutation_Response = {
  __typename?: 'Project_Member_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Member>;
};

/** on_conflict condition type for table "Project_Member" */
export type Project_Member_On_Conflict = {
  constraint: Project_Member_Constraint;
  update_columns?: Array<Project_Member_Update_Column>;
  where?: InputMaybe<Project_Member_Bool_Exp>;
};

/** Ordering options when selecting data from "Project_Member". */
export type Project_Member_Order_By = {
  Employee?: InputMaybe<Employee_Order_By>;
  Project?: InputMaybe<Project_Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  role_activity?: InputMaybe<Order_By>;
  role_area?: InputMaybe<Order_By>;
  role_position?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Project_Member */
export type Project_Member_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Project_Member" */
export enum Project_Member_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  RoleActivity = 'role_activity',
  /** column name */
  RoleArea = 'role_area',
  /** column name */
  RolePosition = 'role_position',
  /** column name */
  StartDate = 'start_date'
}

/** input type for updating data in table "Project_Member" */
export type Project_Member_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['Int']>;
  role_activity?: InputMaybe<Scalars['String']>;
  role_area?: InputMaybe<Scalars['String']>;
  role_position?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['date']>;
};

/** aggregate stddev on columns */
export type Project_Member_Stddev_Fields = {
  __typename?: 'Project_Member_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Project_Member" */
export type Project_Member_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Project_Member_Stddev_Pop_Fields = {
  __typename?: 'Project_Member_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Project_Member" */
export type Project_Member_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Project_Member_Stddev_Samp_Fields = {
  __typename?: 'Project_Member_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Project_Member" */
export type Project_Member_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Project_Member" */
export type Project_Member_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Member_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Member_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['Int']>;
  role_activity?: InputMaybe<Scalars['String']>;
  role_area?: InputMaybe<Scalars['String']>;
  role_position?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['date']>;
};

/** aggregate sum on columns */
export type Project_Member_Sum_Fields = {
  __typename?: 'Project_Member_sum_fields';
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Project_Member" */
export type Project_Member_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** update columns of table "Project_Member" */
export enum Project_Member_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  RoleActivity = 'role_activity',
  /** column name */
  RoleArea = 'role_area',
  /** column name */
  RolePosition = 'role_position',
  /** column name */
  StartDate = 'start_date'
}

export type Project_Member_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Member_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Member_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Member_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Member_Var_Pop_Fields = {
  __typename?: 'Project_Member_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Project_Member" */
export type Project_Member_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Project_Member_Var_Samp_Fields = {
  __typename?: 'Project_Member_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Project_Member" */
export type Project_Member_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Project_Member_Variance_Fields = {
  __typename?: 'Project_Member_variance_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Project_Member" */
export type Project_Member_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "Project_News" */
export type Project_News = {
  __typename?: 'Project_News';
  /** An object relationship */
  Project?: Maybe<Project>;
  d?: Maybe<Scalars['Int']>;
  details?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  project_id?: Maybe<Scalars['Int']>;
  showFrom: Scalars['timestamptz'];
  showUntil: Scalars['timestamptz'];
  title?: Maybe<Scalars['String']>;
  urgent?: Maybe<Scalars['Boolean']>;
  variant?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Project_News" */
export type Project_News_Aggregate = {
  __typename?: 'Project_News_aggregate';
  aggregate?: Maybe<Project_News_Aggregate_Fields>;
  nodes: Array<Project_News>;
};

export type Project_News_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Project_News_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Project_News_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Project_News_Aggregate_Bool_Exp_Count>;
};

export type Project_News_Aggregate_Bool_Exp_Bool_And = {
  arguments: Project_News_Select_Column_Project_News_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_News_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Project_News_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Project_News_Select_Column_Project_News_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_News_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Project_News_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_News_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_News_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Project_News" */
export type Project_News_Aggregate_Fields = {
  __typename?: 'Project_News_aggregate_fields';
  avg?: Maybe<Project_News_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_News_Max_Fields>;
  min?: Maybe<Project_News_Min_Fields>;
  stddev?: Maybe<Project_News_Stddev_Fields>;
  stddev_pop?: Maybe<Project_News_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_News_Stddev_Samp_Fields>;
  sum?: Maybe<Project_News_Sum_Fields>;
  var_pop?: Maybe<Project_News_Var_Pop_Fields>;
  var_samp?: Maybe<Project_News_Var_Samp_Fields>;
  variance?: Maybe<Project_News_Variance_Fields>;
};


/** aggregate fields of "Project_News" */
export type Project_News_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_News_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Project_News" */
export type Project_News_Aggregate_Order_By = {
  avg?: InputMaybe<Project_News_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_News_Max_Order_By>;
  min?: InputMaybe<Project_News_Min_Order_By>;
  stddev?: InputMaybe<Project_News_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Project_News_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Project_News_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Project_News_Sum_Order_By>;
  var_pop?: InputMaybe<Project_News_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Project_News_Var_Samp_Order_By>;
  variance?: InputMaybe<Project_News_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Project_News" */
export type Project_News_Arr_Rel_Insert_Input = {
  data: Array<Project_News_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_News_On_Conflict>;
};

/** aggregate avg on columns */
export type Project_News_Avg_Fields = {
  __typename?: 'Project_News_avg_fields';
  d?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Project_News" */
export type Project_News_Avg_Order_By = {
  d?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Project_News". All fields are combined with a logical 'AND'. */
export type Project_News_Bool_Exp = {
  Project?: InputMaybe<Project_Bool_Exp>;
  _and?: InputMaybe<Array<Project_News_Bool_Exp>>;
  _not?: InputMaybe<Project_News_Bool_Exp>;
  _or?: InputMaybe<Array<Project_News_Bool_Exp>>;
  d?: InputMaybe<Int_Comparison_Exp>;
  details?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  project_id?: InputMaybe<Int_Comparison_Exp>;
  showFrom?: InputMaybe<Timestamptz_Comparison_Exp>;
  showUntil?: InputMaybe<Timestamptz_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  urgent?: InputMaybe<Boolean_Comparison_Exp>;
  variant?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Project_News" */
export enum Project_News_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectNewsPkey = 'Project_News_pkey'
}

/** input type for incrementing numeric columns in table "Project_News" */
export type Project_News_Inc_Input = {
  d?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Project_News" */
export type Project_News_Insert_Input = {
  Project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  d?: InputMaybe<Scalars['Int']>;
  details?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['Int']>;
  showFrom?: InputMaybe<Scalars['timestamptz']>;
  showUntil?: InputMaybe<Scalars['timestamptz']>;
  title?: InputMaybe<Scalars['String']>;
  urgent?: InputMaybe<Scalars['Boolean']>;
  variant?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Project_News_Max_Fields = {
  __typename?: 'Project_News_max_fields';
  d?: Maybe<Scalars['Int']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['Int']>;
  showFrom?: Maybe<Scalars['timestamptz']>;
  showUntil?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Project_News" */
export type Project_News_Max_Order_By = {
  d?: InputMaybe<Order_By>;
  details?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  showFrom?: InputMaybe<Order_By>;
  showUntil?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  variant?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_News_Min_Fields = {
  __typename?: 'Project_News_min_fields';
  d?: Maybe<Scalars['Int']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['Int']>;
  showFrom?: Maybe<Scalars['timestamptz']>;
  showUntil?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Project_News" */
export type Project_News_Min_Order_By = {
  d?: InputMaybe<Order_By>;
  details?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  showFrom?: InputMaybe<Order_By>;
  showUntil?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  variant?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Project_News" */
export type Project_News_Mutation_Response = {
  __typename?: 'Project_News_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_News>;
};

/** on_conflict condition type for table "Project_News" */
export type Project_News_On_Conflict = {
  constraint: Project_News_Constraint;
  update_columns?: Array<Project_News_Update_Column>;
  where?: InputMaybe<Project_News_Bool_Exp>;
};

/** Ordering options when selecting data from "Project_News". */
export type Project_News_Order_By = {
  Project?: InputMaybe<Project_Order_By>;
  d?: InputMaybe<Order_By>;
  details?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  showFrom?: InputMaybe<Order_By>;
  showUntil?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  urgent?: InputMaybe<Order_By>;
  variant?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Project_News */
export type Project_News_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Project_News" */
export enum Project_News_Select_Column {
  /** column name */
  D = 'd',
  /** column name */
  Details = 'details',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ShowFrom = 'showFrom',
  /** column name */
  ShowUntil = 'showUntil',
  /** column name */
  Title = 'title',
  /** column name */
  Urgent = 'urgent',
  /** column name */
  Variant = 'variant'
}

/** select "Project_News_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Project_News" */
export enum Project_News_Select_Column_Project_News_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Urgent = 'urgent'
}

/** select "Project_News_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Project_News" */
export enum Project_News_Select_Column_Project_News_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Urgent = 'urgent'
}

/** input type for updating data in table "Project_News" */
export type Project_News_Set_Input = {
  d?: InputMaybe<Scalars['Int']>;
  details?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['Int']>;
  showFrom?: InputMaybe<Scalars['timestamptz']>;
  showUntil?: InputMaybe<Scalars['timestamptz']>;
  title?: InputMaybe<Scalars['String']>;
  urgent?: InputMaybe<Scalars['Boolean']>;
  variant?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Project_News_Stddev_Fields = {
  __typename?: 'Project_News_stddev_fields';
  d?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Project_News" */
export type Project_News_Stddev_Order_By = {
  d?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Project_News_Stddev_Pop_Fields = {
  __typename?: 'Project_News_stddev_pop_fields';
  d?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Project_News" */
export type Project_News_Stddev_Pop_Order_By = {
  d?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Project_News_Stddev_Samp_Fields = {
  __typename?: 'Project_News_stddev_samp_fields';
  d?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Project_News" */
export type Project_News_Stddev_Samp_Order_By = {
  d?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Project_News" */
export type Project_News_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_News_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_News_Stream_Cursor_Value_Input = {
  d?: InputMaybe<Scalars['Int']>;
  details?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['Int']>;
  showFrom?: InputMaybe<Scalars['timestamptz']>;
  showUntil?: InputMaybe<Scalars['timestamptz']>;
  title?: InputMaybe<Scalars['String']>;
  urgent?: InputMaybe<Scalars['Boolean']>;
  variant?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Project_News_Sum_Fields = {
  __typename?: 'Project_News_sum_fields';
  d?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Project_News" */
export type Project_News_Sum_Order_By = {
  d?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** update columns of table "Project_News" */
export enum Project_News_Update_Column {
  /** column name */
  D = 'd',
  /** column name */
  Details = 'details',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ShowFrom = 'showFrom',
  /** column name */
  ShowUntil = 'showUntil',
  /** column name */
  Title = 'title',
  /** column name */
  Urgent = 'urgent',
  /** column name */
  Variant = 'variant'
}

export type Project_News_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_News_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_News_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_News_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_News_Var_Pop_Fields = {
  __typename?: 'Project_News_var_pop_fields';
  d?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Project_News" */
export type Project_News_Var_Pop_Order_By = {
  d?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Project_News_Var_Samp_Fields = {
  __typename?: 'Project_News_var_samp_fields';
  d?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Project_News" */
export type Project_News_Var_Samp_Order_By = {
  d?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Project_News_Variance_Fields = {
  __typename?: 'Project_News_variance_fields';
  d?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Project_News" */
export type Project_News_Variance_Order_By = {
  d?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "Project_Service" */
export type Project_Service = {
  __typename?: 'Project_Service';
  /** An object relationship */
  Project?: Maybe<Project>;
  /** An object relationship */
  Service_Catalog?: Maybe<Service_Catalog>;
  display_iframe?: Maybe<Scalars['Boolean']>;
  help_text?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  link?: Maybe<Scalars['String']>;
  price_per_month?: Maybe<Scalars['numeric']>;
  project_id?: Maybe<Scalars['Int']>;
  service_catalog_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Project_Service" */
export type Project_Service_Aggregate = {
  __typename?: 'Project_Service_aggregate';
  aggregate?: Maybe<Project_Service_Aggregate_Fields>;
  nodes: Array<Project_Service>;
};

export type Project_Service_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Project_Service_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Project_Service_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Project_Service_Aggregate_Bool_Exp_Count>;
};

export type Project_Service_Aggregate_Bool_Exp_Bool_And = {
  arguments: Project_Service_Select_Column_Project_Service_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Service_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Project_Service_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Project_Service_Select_Column_Project_Service_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Service_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Project_Service_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Service_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Service_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Project_Service" */
export type Project_Service_Aggregate_Fields = {
  __typename?: 'Project_Service_aggregate_fields';
  avg?: Maybe<Project_Service_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Service_Max_Fields>;
  min?: Maybe<Project_Service_Min_Fields>;
  stddev?: Maybe<Project_Service_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Service_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Service_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Service_Sum_Fields>;
  var_pop?: Maybe<Project_Service_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Service_Var_Samp_Fields>;
  variance?: Maybe<Project_Service_Variance_Fields>;
};


/** aggregate fields of "Project_Service" */
export type Project_Service_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Service_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Project_Service" */
export type Project_Service_Aggregate_Order_By = {
  avg?: InputMaybe<Project_Service_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Service_Max_Order_By>;
  min?: InputMaybe<Project_Service_Min_Order_By>;
  stddev?: InputMaybe<Project_Service_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Project_Service_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Project_Service_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Project_Service_Sum_Order_By>;
  var_pop?: InputMaybe<Project_Service_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Project_Service_Var_Samp_Order_By>;
  variance?: InputMaybe<Project_Service_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Project_Service" */
export type Project_Service_Arr_Rel_Insert_Input = {
  data: Array<Project_Service_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Service_On_Conflict>;
};

/** aggregate avg on columns */
export type Project_Service_Avg_Fields = {
  __typename?: 'Project_Service_avg_fields';
  id?: Maybe<Scalars['Float']>;
  price_per_month?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
  service_catalog_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Project_Service" */
export type Project_Service_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  price_per_month?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  service_catalog_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Project_Service". All fields are combined with a logical 'AND'. */
export type Project_Service_Bool_Exp = {
  Project?: InputMaybe<Project_Bool_Exp>;
  Service_Catalog?: InputMaybe<Service_Catalog_Bool_Exp>;
  _and?: InputMaybe<Array<Project_Service_Bool_Exp>>;
  _not?: InputMaybe<Project_Service_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Service_Bool_Exp>>;
  display_iframe?: InputMaybe<Boolean_Comparison_Exp>;
  help_text?: InputMaybe<String_Comparison_Exp>;
  icon?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  price_per_month?: InputMaybe<Numeric_Comparison_Exp>;
  project_id?: InputMaybe<Int_Comparison_Exp>;
  service_catalog_id?: InputMaybe<Int_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Project_Service" */
export enum Project_Service_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectServicePkey = 'Project_Service_pkey'
}

/** input type for incrementing numeric columns in table "Project_Service" */
export type Project_Service_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  price_per_month?: InputMaybe<Scalars['numeric']>;
  project_id?: InputMaybe<Scalars['Int']>;
  service_catalog_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Project_Service" */
export type Project_Service_Insert_Input = {
  Project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  Service_Catalog?: InputMaybe<Service_Catalog_Obj_Rel_Insert_Input>;
  display_iframe?: InputMaybe<Scalars['Boolean']>;
  help_text?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  link?: InputMaybe<Scalars['String']>;
  price_per_month?: InputMaybe<Scalars['numeric']>;
  project_id?: InputMaybe<Scalars['Int']>;
  service_catalog_id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Project_Service_Max_Fields = {
  __typename?: 'Project_Service_max_fields';
  help_text?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  price_per_month?: Maybe<Scalars['numeric']>;
  project_id?: Maybe<Scalars['Int']>;
  service_catalog_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Project_Service" */
export type Project_Service_Max_Order_By = {
  help_text?: InputMaybe<Order_By>;
  icon?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  price_per_month?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  service_catalog_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Service_Min_Fields = {
  __typename?: 'Project_Service_min_fields';
  help_text?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  price_per_month?: Maybe<Scalars['numeric']>;
  project_id?: Maybe<Scalars['Int']>;
  service_catalog_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Project_Service" */
export type Project_Service_Min_Order_By = {
  help_text?: InputMaybe<Order_By>;
  icon?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  price_per_month?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  service_catalog_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Project_Service" */
export type Project_Service_Mutation_Response = {
  __typename?: 'Project_Service_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Service>;
};

/** on_conflict condition type for table "Project_Service" */
export type Project_Service_On_Conflict = {
  constraint: Project_Service_Constraint;
  update_columns?: Array<Project_Service_Update_Column>;
  where?: InputMaybe<Project_Service_Bool_Exp>;
};

/** Ordering options when selecting data from "Project_Service". */
export type Project_Service_Order_By = {
  Project?: InputMaybe<Project_Order_By>;
  Service_Catalog?: InputMaybe<Service_Catalog_Order_By>;
  display_iframe?: InputMaybe<Order_By>;
  help_text?: InputMaybe<Order_By>;
  icon?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  price_per_month?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  service_catalog_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Project_Service */
export type Project_Service_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Project_Service" */
export enum Project_Service_Select_Column {
  /** column name */
  DisplayIframe = 'display_iframe',
  /** column name */
  HelpText = 'help_text',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  PricePerMonth = 'price_per_month',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ServiceCatalogId = 'service_catalog_id',
  /** column name */
  Title = 'title'
}

/** select "Project_Service_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Project_Service" */
export enum Project_Service_Select_Column_Project_Service_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  DisplayIframe = 'display_iframe'
}

/** select "Project_Service_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Project_Service" */
export enum Project_Service_Select_Column_Project_Service_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  DisplayIframe = 'display_iframe'
}

/** input type for updating data in table "Project_Service" */
export type Project_Service_Set_Input = {
  display_iframe?: InputMaybe<Scalars['Boolean']>;
  help_text?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  link?: InputMaybe<Scalars['String']>;
  price_per_month?: InputMaybe<Scalars['numeric']>;
  project_id?: InputMaybe<Scalars['Int']>;
  service_catalog_id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Project_Service_Stddev_Fields = {
  __typename?: 'Project_Service_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  price_per_month?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
  service_catalog_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Project_Service" */
export type Project_Service_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  price_per_month?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  service_catalog_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Project_Service_Stddev_Pop_Fields = {
  __typename?: 'Project_Service_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price_per_month?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
  service_catalog_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Project_Service" */
export type Project_Service_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  price_per_month?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  service_catalog_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Project_Service_Stddev_Samp_Fields = {
  __typename?: 'Project_Service_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price_per_month?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
  service_catalog_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Project_Service" */
export type Project_Service_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  price_per_month?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  service_catalog_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Project_Service" */
export type Project_Service_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Service_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Service_Stream_Cursor_Value_Input = {
  display_iframe?: InputMaybe<Scalars['Boolean']>;
  help_text?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  link?: InputMaybe<Scalars['String']>;
  price_per_month?: InputMaybe<Scalars['numeric']>;
  project_id?: InputMaybe<Scalars['Int']>;
  service_catalog_id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Project_Service_Sum_Fields = {
  __typename?: 'Project_Service_sum_fields';
  id?: Maybe<Scalars['Int']>;
  price_per_month?: Maybe<Scalars['numeric']>;
  project_id?: Maybe<Scalars['Int']>;
  service_catalog_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Project_Service" */
export type Project_Service_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  price_per_month?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  service_catalog_id?: InputMaybe<Order_By>;
};

/** update columns of table "Project_Service" */
export enum Project_Service_Update_Column {
  /** column name */
  DisplayIframe = 'display_iframe',
  /** column name */
  HelpText = 'help_text',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  PricePerMonth = 'price_per_month',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ServiceCatalogId = 'service_catalog_id',
  /** column name */
  Title = 'title'
}

export type Project_Service_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Service_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Service_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Service_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Service_Var_Pop_Fields = {
  __typename?: 'Project_Service_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  price_per_month?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
  service_catalog_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Project_Service" */
export type Project_Service_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  price_per_month?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  service_catalog_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Project_Service_Var_Samp_Fields = {
  __typename?: 'Project_Service_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  price_per_month?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
  service_catalog_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Project_Service" */
export type Project_Service_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  price_per_month?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  service_catalog_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Project_Service_Variance_Fields = {
  __typename?: 'Project_Service_variance_fields';
  id?: Maybe<Scalars['Float']>;
  price_per_month?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
  service_catalog_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Project_Service" */
export type Project_Service_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  price_per_month?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  service_catalog_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "Project_To_Everhour" */
export type Project_To_Everhour = {
  __typename?: 'Project_To_Everhour';
  /** An object relationship */
  Project?: Maybe<Project>;
  /** An array relationship */
  Tasks: Array<Everhour_Tasks>;
  /** An aggregate relationship */
  Tasks_aggregate: Everhour_Tasks_Aggregate;
  everhour_id?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  project_id?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "Project_To_Everhour" */
export type Project_To_EverhourTasksArgs = {
  distinct_on?: InputMaybe<Array<Everhour_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Everhour_Tasks_Order_By>>;
  where?: InputMaybe<Everhour_Tasks_Bool_Exp>;
};


/** columns and relationships of "Project_To_Everhour" */
export type Project_To_EverhourTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Everhour_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Everhour_Tasks_Order_By>>;
  where?: InputMaybe<Everhour_Tasks_Bool_Exp>;
};

/** aggregated selection of "Project_To_Everhour" */
export type Project_To_Everhour_Aggregate = {
  __typename?: 'Project_To_Everhour_aggregate';
  aggregate?: Maybe<Project_To_Everhour_Aggregate_Fields>;
  nodes: Array<Project_To_Everhour>;
};

export type Project_To_Everhour_Aggregate_Bool_Exp = {
  count?: InputMaybe<Project_To_Everhour_Aggregate_Bool_Exp_Count>;
};

export type Project_To_Everhour_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_To_Everhour_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_To_Everhour_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Project_To_Everhour" */
export type Project_To_Everhour_Aggregate_Fields = {
  __typename?: 'Project_To_Everhour_aggregate_fields';
  avg?: Maybe<Project_To_Everhour_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_To_Everhour_Max_Fields>;
  min?: Maybe<Project_To_Everhour_Min_Fields>;
  stddev?: Maybe<Project_To_Everhour_Stddev_Fields>;
  stddev_pop?: Maybe<Project_To_Everhour_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_To_Everhour_Stddev_Samp_Fields>;
  sum?: Maybe<Project_To_Everhour_Sum_Fields>;
  var_pop?: Maybe<Project_To_Everhour_Var_Pop_Fields>;
  var_samp?: Maybe<Project_To_Everhour_Var_Samp_Fields>;
  variance?: Maybe<Project_To_Everhour_Variance_Fields>;
};


/** aggregate fields of "Project_To_Everhour" */
export type Project_To_Everhour_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_To_Everhour_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Project_To_Everhour" */
export type Project_To_Everhour_Aggregate_Order_By = {
  avg?: InputMaybe<Project_To_Everhour_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_To_Everhour_Max_Order_By>;
  min?: InputMaybe<Project_To_Everhour_Min_Order_By>;
  stddev?: InputMaybe<Project_To_Everhour_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Project_To_Everhour_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Project_To_Everhour_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Project_To_Everhour_Sum_Order_By>;
  var_pop?: InputMaybe<Project_To_Everhour_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Project_To_Everhour_Var_Samp_Order_By>;
  variance?: InputMaybe<Project_To_Everhour_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Project_To_Everhour" */
export type Project_To_Everhour_Arr_Rel_Insert_Input = {
  data: Array<Project_To_Everhour_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_To_Everhour_On_Conflict>;
};

/** aggregate avg on columns */
export type Project_To_Everhour_Avg_Fields = {
  __typename?: 'Project_To_Everhour_avg_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Project_To_Everhour" */
export type Project_To_Everhour_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Project_To_Everhour". All fields are combined with a logical 'AND'. */
export type Project_To_Everhour_Bool_Exp = {
  Project?: InputMaybe<Project_Bool_Exp>;
  Tasks?: InputMaybe<Everhour_Tasks_Bool_Exp>;
  Tasks_aggregate?: InputMaybe<Everhour_Tasks_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Project_To_Everhour_Bool_Exp>>;
  _not?: InputMaybe<Project_To_Everhour_Bool_Exp>;
  _or?: InputMaybe<Array<Project_To_Everhour_Bool_Exp>>;
  everhour_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  project_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Project_To_Everhour" */
export enum Project_To_Everhour_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectToEverhourPkey = 'Project_To_Everhour_pkey'
}

/** input type for incrementing numeric columns in table "Project_To_Everhour" */
export type Project_To_Everhour_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Project_To_Everhour" */
export type Project_To_Everhour_Insert_Input = {
  Project?: InputMaybe<Project_Obj_Rel_Insert_Input>;
  Tasks?: InputMaybe<Everhour_Tasks_Arr_Rel_Insert_Input>;
  everhour_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Project_To_Everhour_Max_Fields = {
  __typename?: 'Project_To_Everhour_max_fields';
  everhour_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Project_To_Everhour" */
export type Project_To_Everhour_Max_Order_By = {
  everhour_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_To_Everhour_Min_Fields = {
  __typename?: 'Project_To_Everhour_min_fields';
  everhour_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Project_To_Everhour" */
export type Project_To_Everhour_Min_Order_By = {
  everhour_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Project_To_Everhour" */
export type Project_To_Everhour_Mutation_Response = {
  __typename?: 'Project_To_Everhour_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_To_Everhour>;
};

/** input type for inserting object relation for remote table "Project_To_Everhour" */
export type Project_To_Everhour_Obj_Rel_Insert_Input = {
  data: Project_To_Everhour_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_To_Everhour_On_Conflict>;
};

/** on_conflict condition type for table "Project_To_Everhour" */
export type Project_To_Everhour_On_Conflict = {
  constraint: Project_To_Everhour_Constraint;
  update_columns?: Array<Project_To_Everhour_Update_Column>;
  where?: InputMaybe<Project_To_Everhour_Bool_Exp>;
};

/** Ordering options when selecting data from "Project_To_Everhour". */
export type Project_To_Everhour_Order_By = {
  Project?: InputMaybe<Project_Order_By>;
  Tasks_aggregate?: InputMaybe<Everhour_Tasks_Aggregate_Order_By>;
  everhour_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Project_To_Everhour */
export type Project_To_Everhour_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Project_To_Everhour" */
export enum Project_To_Everhour_Select_Column {
  /** column name */
  EverhourId = 'everhour_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id'
}

/** input type for updating data in table "Project_To_Everhour" */
export type Project_To_Everhour_Set_Input = {
  everhour_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Project_To_Everhour_Stddev_Fields = {
  __typename?: 'Project_To_Everhour_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Project_To_Everhour" */
export type Project_To_Everhour_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Project_To_Everhour_Stddev_Pop_Fields = {
  __typename?: 'Project_To_Everhour_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Project_To_Everhour" */
export type Project_To_Everhour_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Project_To_Everhour_Stddev_Samp_Fields = {
  __typename?: 'Project_To_Everhour_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Project_To_Everhour" */
export type Project_To_Everhour_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Project_To_Everhour" */
export type Project_To_Everhour_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_To_Everhour_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_To_Everhour_Stream_Cursor_Value_Input = {
  everhour_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Project_To_Everhour_Sum_Fields = {
  __typename?: 'Project_To_Everhour_sum_fields';
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Project_To_Everhour" */
export type Project_To_Everhour_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** update columns of table "Project_To_Everhour" */
export enum Project_To_Everhour_Update_Column {
  /** column name */
  EverhourId = 'everhour_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id'
}

export type Project_To_Everhour_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_To_Everhour_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_To_Everhour_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_To_Everhour_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_To_Everhour_Var_Pop_Fields = {
  __typename?: 'Project_To_Everhour_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Project_To_Everhour" */
export type Project_To_Everhour_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Project_To_Everhour_Var_Samp_Fields = {
  __typename?: 'Project_To_Everhour_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Project_To_Everhour" */
export type Project_To_Everhour_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Project_To_Everhour_Variance_Fields = {
  __typename?: 'Project_To_Everhour_variance_fields';
  id?: Maybe<Scalars['Float']>;
  project_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Project_To_Everhour" */
export type Project_To_Everhour_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregated selection of "Project" */
export type Project_Aggregate = {
  __typename?: 'Project_aggregate';
  aggregate?: Maybe<Project_Aggregate_Fields>;
  nodes: Array<Project>;
};

export type Project_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Project_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Project_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Project_Aggregate_Bool_Exp_Count>;
};

export type Project_Aggregate_Bool_Exp_Bool_And = {
  arguments: Project_Select_Column_Project_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Project_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Project_Select_Column_Project_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Project_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Project" */
export type Project_Aggregate_Fields = {
  __typename?: 'Project_aggregate_fields';
  avg?: Maybe<Project_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Max_Fields>;
  min?: Maybe<Project_Min_Fields>;
  stddev?: Maybe<Project_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Sum_Fields>;
  var_pop?: Maybe<Project_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Var_Samp_Fields>;
  variance?: Maybe<Project_Variance_Fields>;
};


/** aggregate fields of "Project" */
export type Project_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Project" */
export type Project_Aggregate_Order_By = {
  avg?: InputMaybe<Project_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Max_Order_By>;
  min?: InputMaybe<Project_Min_Order_By>;
  stddev?: InputMaybe<Project_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Project_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Project_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Project_Sum_Order_By>;
  var_pop?: InputMaybe<Project_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Project_Var_Samp_Order_By>;
  variance?: InputMaybe<Project_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Project" */
export type Project_Arr_Rel_Insert_Input = {
  data: Array<Project_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_On_Conflict>;
};

/** aggregate avg on columns */
export type Project_Avg_Fields = {
  __typename?: 'Project_avg_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Project" */
export type Project_Avg_Order_By = {
  customer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Project". All fields are combined with a logical 'AND'. */
export type Project_Bool_Exp = {
  Contracts?: InputMaybe<Project_Contract_Bool_Exp>;
  Contracts_aggregate?: InputMaybe<Project_Contract_Aggregate_Bool_Exp>;
  Costs?: InputMaybe<Project_Invoice_Cost_Bool_Exp>;
  Costs_aggregate?: InputMaybe<Project_Invoice_Cost_Aggregate_Bool_Exp>;
  Customer?: InputMaybe<Customer_Bool_Exp>;
  Everhour?: InputMaybe<Project_To_Everhour_Bool_Exp>;
  Everhour_aggregate?: InputMaybe<Project_To_Everhour_Aggregate_Bool_Exp>;
  News?: InputMaybe<Project_News_Bool_Exp>;
  News_aggregate?: InputMaybe<Project_News_Aggregate_Bool_Exp>;
  Project_Members?: InputMaybe<Project_Member_Bool_Exp>;
  Project_Members_aggregate?: InputMaybe<Project_Member_Aggregate_Bool_Exp>;
  Services?: InputMaybe<Project_Service_Bool_Exp>;
  Services_aggregate?: InputMaybe<Project_Service_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Project_Bool_Exp>>;
  _not?: InputMaybe<Project_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  config_show_tasks_estimates?: InputMaybe<Boolean_Comparison_Exp>;
  customer_id?: InputMaybe<Int_Comparison_Exp>;
  date_created?: InputMaybe<Timestamptz_Comparison_Exp>;
  date_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  started_at?: InputMaybe<Timestamp_Comparison_Exp>;
  technologies?: InputMaybe<Json_Comparison_Exp>;
  user_created?: InputMaybe<Uuid_Comparison_Exp>;
  user_updated?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "Project" */
export enum Project_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectPkey = 'Project_pkey'
}

/** input type for incrementing numeric columns in table "Project" */
export type Project_Inc_Input = {
  customer_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Project" */
export type Project_Insert_Input = {
  Contracts?: InputMaybe<Project_Contract_Arr_Rel_Insert_Input>;
  Costs?: InputMaybe<Project_Invoice_Cost_Arr_Rel_Insert_Input>;
  Customer?: InputMaybe<Customer_Obj_Rel_Insert_Input>;
  Everhour?: InputMaybe<Project_To_Everhour_Arr_Rel_Insert_Input>;
  News?: InputMaybe<Project_News_Arr_Rel_Insert_Input>;
  Project_Members?: InputMaybe<Project_Member_Arr_Rel_Insert_Input>;
  Services?: InputMaybe<Project_Service_Arr_Rel_Insert_Input>;
  active?: InputMaybe<Scalars['Boolean']>;
  config_show_tasks_estimates?: InputMaybe<Scalars['Boolean']>;
  customer_id?: InputMaybe<Scalars['Int']>;
  date_created?: InputMaybe<Scalars['timestamptz']>;
  date_updated?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['timestamp']>;
  technologies?: InputMaybe<Scalars['json']>;
  user_created?: InputMaybe<Scalars['uuid']>;
  user_updated?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Project_Max_Fields = {
  __typename?: 'Project_max_fields';
  customer_id?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['timestamptz']>;
  date_updated?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamp']>;
  user_created?: Maybe<Scalars['uuid']>;
  user_updated?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "Project" */
export type Project_Max_Order_By = {
  customer_id?: InputMaybe<Order_By>;
  date_created?: InputMaybe<Order_By>;
  date_updated?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
  user_created?: InputMaybe<Order_By>;
  user_updated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Min_Fields = {
  __typename?: 'Project_min_fields';
  customer_id?: Maybe<Scalars['Int']>;
  date_created?: Maybe<Scalars['timestamptz']>;
  date_updated?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamp']>;
  user_created?: Maybe<Scalars['uuid']>;
  user_updated?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "Project" */
export type Project_Min_Order_By = {
  customer_id?: InputMaybe<Order_By>;
  date_created?: InputMaybe<Order_By>;
  date_updated?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
  user_created?: InputMaybe<Order_By>;
  user_updated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Project" */
export type Project_Mutation_Response = {
  __typename?: 'Project_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project>;
};

/** input type for inserting object relation for remote table "Project" */
export type Project_Obj_Rel_Insert_Input = {
  data: Project_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_On_Conflict>;
};

/** on_conflict condition type for table "Project" */
export type Project_On_Conflict = {
  constraint: Project_Constraint;
  update_columns?: Array<Project_Update_Column>;
  where?: InputMaybe<Project_Bool_Exp>;
};

/** Ordering options when selecting data from "Project". */
export type Project_Order_By = {
  Contracts_aggregate?: InputMaybe<Project_Contract_Aggregate_Order_By>;
  Costs_aggregate?: InputMaybe<Project_Invoice_Cost_Aggregate_Order_By>;
  Customer?: InputMaybe<Customer_Order_By>;
  Everhour_aggregate?: InputMaybe<Project_To_Everhour_Aggregate_Order_By>;
  News_aggregate?: InputMaybe<Project_News_Aggregate_Order_By>;
  Project_Members_aggregate?: InputMaybe<Project_Member_Aggregate_Order_By>;
  Services_aggregate?: InputMaybe<Project_Service_Aggregate_Order_By>;
  active?: InputMaybe<Order_By>;
  config_show_tasks_estimates?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  date_created?: InputMaybe<Order_By>;
  date_updated?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
  technologies?: InputMaybe<Order_By>;
  user_created?: InputMaybe<Order_By>;
  user_updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Project */
export type Project_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Project" */
export enum Project_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ConfigShowTasksEstimates = 'config_show_tasks_estimates',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  DateCreated = 'date_created',
  /** column name */
  DateUpdated = 'date_updated',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  Technologies = 'technologies',
  /** column name */
  UserCreated = 'user_created',
  /** column name */
  UserUpdated = 'user_updated'
}

/** select "Project_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Project" */
export enum Project_Select_Column_Project_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  ConfigShowTasksEstimates = 'config_show_tasks_estimates'
}

/** select "Project_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Project" */
export enum Project_Select_Column_Project_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  ConfigShowTasksEstimates = 'config_show_tasks_estimates'
}

/** input type for updating data in table "Project" */
export type Project_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  config_show_tasks_estimates?: InputMaybe<Scalars['Boolean']>;
  customer_id?: InputMaybe<Scalars['Int']>;
  date_created?: InputMaybe<Scalars['timestamptz']>;
  date_updated?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['timestamp']>;
  technologies?: InputMaybe<Scalars['json']>;
  user_created?: InputMaybe<Scalars['uuid']>;
  user_updated?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Project_Stddev_Fields = {
  __typename?: 'Project_stddev_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Project" */
export type Project_Stddev_Order_By = {
  customer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Project_Stddev_Pop_Fields = {
  __typename?: 'Project_stddev_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Project" */
export type Project_Stddev_Pop_Order_By = {
  customer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Project_Stddev_Samp_Fields = {
  __typename?: 'Project_stddev_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Project" */
export type Project_Stddev_Samp_Order_By = {
  customer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "Project" */
export type Project_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  config_show_tasks_estimates?: InputMaybe<Scalars['Boolean']>;
  customer_id?: InputMaybe<Scalars['Int']>;
  date_created?: InputMaybe<Scalars['timestamptz']>;
  date_updated?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['timestamp']>;
  technologies?: InputMaybe<Scalars['json']>;
  user_created?: InputMaybe<Scalars['uuid']>;
  user_updated?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Project_Sum_Fields = {
  __typename?: 'Project_sum_fields';
  customer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Project" */
export type Project_Sum_Order_By = {
  customer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "Project" */
export enum Project_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ConfigShowTasksEstimates = 'config_show_tasks_estimates',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  DateCreated = 'date_created',
  /** column name */
  DateUpdated = 'date_updated',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  Technologies = 'technologies',
  /** column name */
  UserCreated = 'user_created',
  /** column name */
  UserUpdated = 'user_updated'
}

export type Project_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Var_Pop_Fields = {
  __typename?: 'Project_var_pop_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Project" */
export type Project_Var_Pop_Order_By = {
  customer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Project_Var_Samp_Fields = {
  __typename?: 'Project_var_samp_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Project" */
export type Project_Var_Samp_Order_By = {
  customer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Project_Variance_Fields = {
  __typename?: 'Project_variance_fields';
  customer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Project" */
export type Project_Variance_Order_By = {
  customer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "Service_Catalog" */
export type Service_Catalog = {
  __typename?: 'Service_Catalog';
  /** An array relationship */
  Project_Services: Array<Project_Service>;
  /** An aggregate relationship */
  Project_Services_aggregate: Project_Service_Aggregate;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};


/** columns and relationships of "Service_Catalog" */
export type Service_CatalogProject_ServicesArgs = {
  distinct_on?: InputMaybe<Array<Project_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Service_Order_By>>;
  where?: InputMaybe<Project_Service_Bool_Exp>;
};


/** columns and relationships of "Service_Catalog" */
export type Service_CatalogProject_Services_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Service_Order_By>>;
  where?: InputMaybe<Project_Service_Bool_Exp>;
};

/** aggregated selection of "Service_Catalog" */
export type Service_Catalog_Aggregate = {
  __typename?: 'Service_Catalog_aggregate';
  aggregate?: Maybe<Service_Catalog_Aggregate_Fields>;
  nodes: Array<Service_Catalog>;
};

/** aggregate fields of "Service_Catalog" */
export type Service_Catalog_Aggregate_Fields = {
  __typename?: 'Service_Catalog_aggregate_fields';
  avg?: Maybe<Service_Catalog_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Service_Catalog_Max_Fields>;
  min?: Maybe<Service_Catalog_Min_Fields>;
  stddev?: Maybe<Service_Catalog_Stddev_Fields>;
  stddev_pop?: Maybe<Service_Catalog_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Service_Catalog_Stddev_Samp_Fields>;
  sum?: Maybe<Service_Catalog_Sum_Fields>;
  var_pop?: Maybe<Service_Catalog_Var_Pop_Fields>;
  var_samp?: Maybe<Service_Catalog_Var_Samp_Fields>;
  variance?: Maybe<Service_Catalog_Variance_Fields>;
};


/** aggregate fields of "Service_Catalog" */
export type Service_Catalog_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Service_Catalog_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Service_Catalog_Avg_Fields = {
  __typename?: 'Service_Catalog_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Service_Catalog". All fields are combined with a logical 'AND'. */
export type Service_Catalog_Bool_Exp = {
  Project_Services?: InputMaybe<Project_Service_Bool_Exp>;
  Project_Services_aggregate?: InputMaybe<Project_Service_Aggregate_Bool_Exp>;
  _and?: InputMaybe<Array<Service_Catalog_Bool_Exp>>;
  _not?: InputMaybe<Service_Catalog_Bool_Exp>;
  _or?: InputMaybe<Array<Service_Catalog_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  icon?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Service_Catalog" */
export enum Service_Catalog_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceCatalogPkey = 'Service_Catalog_pkey'
}

/** input type for incrementing numeric columns in table "Service_Catalog" */
export type Service_Catalog_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Service_Catalog" */
export type Service_Catalog_Insert_Input = {
  Project_Services?: InputMaybe<Project_Service_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Service_Catalog_Max_Fields = {
  __typename?: 'Service_Catalog_max_fields';
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Service_Catalog_Min_Fields = {
  __typename?: 'Service_Catalog_min_fields';
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Service_Catalog" */
export type Service_Catalog_Mutation_Response = {
  __typename?: 'Service_Catalog_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Service_Catalog>;
};

/** input type for inserting object relation for remote table "Service_Catalog" */
export type Service_Catalog_Obj_Rel_Insert_Input = {
  data: Service_Catalog_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Service_Catalog_On_Conflict>;
};

/** on_conflict condition type for table "Service_Catalog" */
export type Service_Catalog_On_Conflict = {
  constraint: Service_Catalog_Constraint;
  update_columns?: Array<Service_Catalog_Update_Column>;
  where?: InputMaybe<Service_Catalog_Bool_Exp>;
};

/** Ordering options when selecting data from "Service_Catalog". */
export type Service_Catalog_Order_By = {
  Project_Services_aggregate?: InputMaybe<Project_Service_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  icon?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Service_Catalog */
export type Service_Catalog_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Service_Catalog" */
export enum Service_Catalog_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "Service_Catalog" */
export type Service_Catalog_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Service_Catalog_Stddev_Fields = {
  __typename?: 'Service_Catalog_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Service_Catalog_Stddev_Pop_Fields = {
  __typename?: 'Service_Catalog_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Service_Catalog_Stddev_Samp_Fields = {
  __typename?: 'Service_Catalog_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "Service_Catalog" */
export type Service_Catalog_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Service_Catalog_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Service_Catalog_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Service_Catalog_Sum_Fields = {
  __typename?: 'Service_Catalog_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "Service_Catalog" */
export enum Service_Catalog_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Service_Catalog_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Service_Catalog_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Service_Catalog_Set_Input>;
  /** filter the rows which have to be updated */
  where: Service_Catalog_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Service_Catalog_Var_Pop_Fields = {
  __typename?: 'Service_Catalog_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Service_Catalog_Var_Samp_Fields = {
  __typename?: 'Service_Catalog_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Service_Catalog_Variance_Fields = {
  __typename?: 'Service_Catalog_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "everhour.tasks" */
export type Everhour_Tasks = {
  __typename?: 'everhour_tasks';
  completed?: Maybe<Scalars['Boolean']>;
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  estimate_time?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  iteration: Scalars['String'];
  labels?: Maybe<Scalars['jsonb']>;
  name: Scalars['String'];
  project_id: Scalars['String'];
  status: Scalars['String'];
  /** An object relationship */
  to_project?: Maybe<Project_To_Everhour>;
  total_time: Scalars['Int'];
  url: Scalars['String'];
};


/** columns and relationships of "everhour.tasks" */
export type Everhour_TasksLabelsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "everhour.tasks" */
export type Everhour_Tasks_Aggregate = {
  __typename?: 'everhour_tasks_aggregate';
  aggregate?: Maybe<Everhour_Tasks_Aggregate_Fields>;
  nodes: Array<Everhour_Tasks>;
};

export type Everhour_Tasks_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Everhour_Tasks_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Everhour_Tasks_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Everhour_Tasks_Aggregate_Bool_Exp_Count>;
};

export type Everhour_Tasks_Aggregate_Bool_Exp_Bool_And = {
  arguments: Everhour_Tasks_Select_Column_Everhour_Tasks_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Everhour_Tasks_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Everhour_Tasks_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Everhour_Tasks_Select_Column_Everhour_Tasks_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Everhour_Tasks_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Everhour_Tasks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Everhour_Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Everhour_Tasks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "everhour.tasks" */
export type Everhour_Tasks_Aggregate_Fields = {
  __typename?: 'everhour_tasks_aggregate_fields';
  avg?: Maybe<Everhour_Tasks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Everhour_Tasks_Max_Fields>;
  min?: Maybe<Everhour_Tasks_Min_Fields>;
  stddev?: Maybe<Everhour_Tasks_Stddev_Fields>;
  stddev_pop?: Maybe<Everhour_Tasks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Everhour_Tasks_Stddev_Samp_Fields>;
  sum?: Maybe<Everhour_Tasks_Sum_Fields>;
  var_pop?: Maybe<Everhour_Tasks_Var_Pop_Fields>;
  var_samp?: Maybe<Everhour_Tasks_Var_Samp_Fields>;
  variance?: Maybe<Everhour_Tasks_Variance_Fields>;
};


/** aggregate fields of "everhour.tasks" */
export type Everhour_Tasks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Everhour_Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "everhour.tasks" */
export type Everhour_Tasks_Aggregate_Order_By = {
  avg?: InputMaybe<Everhour_Tasks_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Everhour_Tasks_Max_Order_By>;
  min?: InputMaybe<Everhour_Tasks_Min_Order_By>;
  stddev?: InputMaybe<Everhour_Tasks_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Everhour_Tasks_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Everhour_Tasks_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Everhour_Tasks_Sum_Order_By>;
  var_pop?: InputMaybe<Everhour_Tasks_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Everhour_Tasks_Var_Samp_Order_By>;
  variance?: InputMaybe<Everhour_Tasks_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Everhour_Tasks_Append_Input = {
  labels?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "everhour.tasks" */
export type Everhour_Tasks_Arr_Rel_Insert_Input = {
  data: Array<Everhour_Tasks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Everhour_Tasks_On_Conflict>;
};

/** aggregate avg on columns */
export type Everhour_Tasks_Avg_Fields = {
  __typename?: 'everhour_tasks_avg_fields';
  estimate_time?: Maybe<Scalars['Float']>;
  total_time?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "everhour.tasks" */
export type Everhour_Tasks_Avg_Order_By = {
  estimate_time?: InputMaybe<Order_By>;
  total_time?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "everhour.tasks". All fields are combined with a logical 'AND'. */
export type Everhour_Tasks_Bool_Exp = {
  _and?: InputMaybe<Array<Everhour_Tasks_Bool_Exp>>;
  _not?: InputMaybe<Everhour_Tasks_Bool_Exp>;
  _or?: InputMaybe<Array<Everhour_Tasks_Bool_Exp>>;
  completed?: InputMaybe<Boolean_Comparison_Exp>;
  completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  estimate_time?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  iteration?: InputMaybe<String_Comparison_Exp>;
  labels?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  project_id?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  to_project?: InputMaybe<Project_To_Everhour_Bool_Exp>;
  total_time?: InputMaybe<Int_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "everhour.tasks" */
export enum Everhour_Tasks_Constraint {
  /** unique or primary key constraint on columns "id" */
  TasksPkey = 'tasks_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Everhour_Tasks_Delete_At_Path_Input = {
  labels?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Everhour_Tasks_Delete_Elem_Input = {
  labels?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Everhour_Tasks_Delete_Key_Input = {
  labels?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "everhour.tasks" */
export type Everhour_Tasks_Inc_Input = {
  estimate_time?: InputMaybe<Scalars['Int']>;
  total_time?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "everhour.tasks" */
export type Everhour_Tasks_Insert_Input = {
  completed?: InputMaybe<Scalars['Boolean']>;
  completed_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  estimate_time?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  iteration?: InputMaybe<Scalars['String']>;
  labels?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  to_project?: InputMaybe<Project_To_Everhour_Obj_Rel_Insert_Input>;
  total_time?: InputMaybe<Scalars['Int']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Everhour_Tasks_Max_Fields = {
  __typename?: 'everhour_tasks_max_fields';
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  estimate_time?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  iteration?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  total_time?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "everhour.tasks" */
export type Everhour_Tasks_Max_Order_By = {
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  estimate_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  iteration?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  total_time?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Everhour_Tasks_Min_Fields = {
  __typename?: 'everhour_tasks_min_fields';
  completed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  estimate_time?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  iteration?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  total_time?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "everhour.tasks" */
export type Everhour_Tasks_Min_Order_By = {
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  estimate_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  iteration?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  total_time?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "everhour.tasks" */
export type Everhour_Tasks_Mutation_Response = {
  __typename?: 'everhour_tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Everhour_Tasks>;
};

/** on_conflict condition type for table "everhour.tasks" */
export type Everhour_Tasks_On_Conflict = {
  constraint: Everhour_Tasks_Constraint;
  update_columns?: Array<Everhour_Tasks_Update_Column>;
  where?: InputMaybe<Everhour_Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "everhour.tasks". */
export type Everhour_Tasks_Order_By = {
  completed?: InputMaybe<Order_By>;
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  estimate_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  iteration?: InputMaybe<Order_By>;
  labels?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  to_project?: InputMaybe<Project_To_Everhour_Order_By>;
  total_time?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: everhour.tasks */
export type Everhour_Tasks_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Everhour_Tasks_Prepend_Input = {
  labels?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "everhour.tasks" */
export enum Everhour_Tasks_Select_Column {
  /** column name */
  Completed = 'completed',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EstimateTime = 'estimate_time',
  /** column name */
  Id = 'id',
  /** column name */
  Iteration = 'iteration',
  /** column name */
  Labels = 'labels',
  /** column name */
  Name = 'name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Status = 'status',
  /** column name */
  TotalTime = 'total_time',
  /** column name */
  Url = 'url'
}

/** select "everhour_tasks_aggregate_bool_exp_bool_and_arguments_columns" columns of table "everhour.tasks" */
export enum Everhour_Tasks_Select_Column_Everhour_Tasks_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Completed = 'completed'
}

/** select "everhour_tasks_aggregate_bool_exp_bool_or_arguments_columns" columns of table "everhour.tasks" */
export enum Everhour_Tasks_Select_Column_Everhour_Tasks_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Completed = 'completed'
}

/** input type for updating data in table "everhour.tasks" */
export type Everhour_Tasks_Set_Input = {
  completed?: InputMaybe<Scalars['Boolean']>;
  completed_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  estimate_time?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  iteration?: InputMaybe<Scalars['String']>;
  labels?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  total_time?: InputMaybe<Scalars['Int']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Everhour_Tasks_Stddev_Fields = {
  __typename?: 'everhour_tasks_stddev_fields';
  estimate_time?: Maybe<Scalars['Float']>;
  total_time?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "everhour.tasks" */
export type Everhour_Tasks_Stddev_Order_By = {
  estimate_time?: InputMaybe<Order_By>;
  total_time?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Everhour_Tasks_Stddev_Pop_Fields = {
  __typename?: 'everhour_tasks_stddev_pop_fields';
  estimate_time?: Maybe<Scalars['Float']>;
  total_time?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "everhour.tasks" */
export type Everhour_Tasks_Stddev_Pop_Order_By = {
  estimate_time?: InputMaybe<Order_By>;
  total_time?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Everhour_Tasks_Stddev_Samp_Fields = {
  __typename?: 'everhour_tasks_stddev_samp_fields';
  estimate_time?: Maybe<Scalars['Float']>;
  total_time?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "everhour.tasks" */
export type Everhour_Tasks_Stddev_Samp_Order_By = {
  estimate_time?: InputMaybe<Order_By>;
  total_time?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "everhour_tasks" */
export type Everhour_Tasks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Everhour_Tasks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Everhour_Tasks_Stream_Cursor_Value_Input = {
  completed?: InputMaybe<Scalars['Boolean']>;
  completed_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  estimate_time?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  iteration?: InputMaybe<Scalars['String']>;
  labels?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  total_time?: InputMaybe<Scalars['Int']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Everhour_Tasks_Sum_Fields = {
  __typename?: 'everhour_tasks_sum_fields';
  estimate_time?: Maybe<Scalars['Int']>;
  total_time?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "everhour.tasks" */
export type Everhour_Tasks_Sum_Order_By = {
  estimate_time?: InputMaybe<Order_By>;
  total_time?: InputMaybe<Order_By>;
};

/** update columns of table "everhour.tasks" */
export enum Everhour_Tasks_Update_Column {
  /** column name */
  Completed = 'completed',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EstimateTime = 'estimate_time',
  /** column name */
  Id = 'id',
  /** column name */
  Iteration = 'iteration',
  /** column name */
  Labels = 'labels',
  /** column name */
  Name = 'name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Status = 'status',
  /** column name */
  TotalTime = 'total_time',
  /** column name */
  Url = 'url'
}

export type Everhour_Tasks_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Everhour_Tasks_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Everhour_Tasks_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Everhour_Tasks_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Everhour_Tasks_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Everhour_Tasks_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Everhour_Tasks_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Everhour_Tasks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Everhour_Tasks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Everhour_Tasks_Var_Pop_Fields = {
  __typename?: 'everhour_tasks_var_pop_fields';
  estimate_time?: Maybe<Scalars['Float']>;
  total_time?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "everhour.tasks" */
export type Everhour_Tasks_Var_Pop_Order_By = {
  estimate_time?: InputMaybe<Order_By>;
  total_time?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Everhour_Tasks_Var_Samp_Fields = {
  __typename?: 'everhour_tasks_var_samp_fields';
  estimate_time?: Maybe<Scalars['Float']>;
  total_time?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "everhour.tasks" */
export type Everhour_Tasks_Var_Samp_Order_By = {
  estimate_time?: InputMaybe<Order_By>;
  total_time?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Everhour_Tasks_Variance_Fields = {
  __typename?: 'everhour_tasks_variance_fields';
  estimate_time?: Maybe<Scalars['Float']>;
  total_time?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "everhour.tasks" */
export type Everhour_Tasks_Variance_Order_By = {
  estimate_time?: InputMaybe<Order_By>;
  total_time?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']>;
  _gt?: InputMaybe<Scalars['json']>;
  _gte?: InputMaybe<Scalars['json']>;
  _in?: InputMaybe<Array<Scalars['json']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['json']>;
  _lte?: InputMaybe<Scalars['json']>;
  _neq?: InputMaybe<Scalars['json']>;
  _nin?: InputMaybe<Array<Scalars['json']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "Customer" */
  delete_Customer?: Maybe<Customer_Mutation_Response>;
  /** delete single row from the table: "Customer" */
  delete_Customer_by_pk?: Maybe<Customer>;
  /** delete data from the table: "Employee" */
  delete_Employee?: Maybe<Employee_Mutation_Response>;
  /** delete data from the table: "Employee_Contract" */
  delete_Employee_Contract?: Maybe<Employee_Contract_Mutation_Response>;
  /** delete single row from the table: "Employee_Contract" */
  delete_Employee_Contract_by_pk?: Maybe<Employee_Contract>;
  /** delete single row from the table: "Employee" */
  delete_Employee_by_pk?: Maybe<Employee>;
  /** delete data from the table: "Hardware" */
  delete_Hardware?: Maybe<Hardware_Mutation_Response>;
  /** delete data from the table: "Hardware_Inventory" */
  delete_Hardware_Inventory?: Maybe<Hardware_Inventory_Mutation_Response>;
  /** delete single row from the table: "Hardware_Inventory" */
  delete_Hardware_Inventory_by_pk?: Maybe<Hardware_Inventory>;
  /** delete single row from the table: "Hardware" */
  delete_Hardware_by_pk?: Maybe<Hardware>;
  /** delete data from the table: "Project" */
  delete_Project?: Maybe<Project_Mutation_Response>;
  /** delete data from the table: "Project_Contract" */
  delete_Project_Contract?: Maybe<Project_Contract_Mutation_Response>;
  /** delete single row from the table: "Project_Contract" */
  delete_Project_Contract_by_pk?: Maybe<Project_Contract>;
  /** delete data from the table: "Project_Invoice" */
  delete_Project_Invoice?: Maybe<Project_Invoice_Mutation_Response>;
  /** delete data from the table: "Project_Invoice_Cost" */
  delete_Project_Invoice_Cost?: Maybe<Project_Invoice_Cost_Mutation_Response>;
  /** delete single row from the table: "Project_Invoice_Cost" */
  delete_Project_Invoice_Cost_by_pk?: Maybe<Project_Invoice_Cost>;
  /** delete single row from the table: "Project_Invoice" */
  delete_Project_Invoice_by_pk?: Maybe<Project_Invoice>;
  /** delete data from the table: "Project_Member" */
  delete_Project_Member?: Maybe<Project_Member_Mutation_Response>;
  /** delete single row from the table: "Project_Member" */
  delete_Project_Member_by_pk?: Maybe<Project_Member>;
  /** delete data from the table: "Project_News" */
  delete_Project_News?: Maybe<Project_News_Mutation_Response>;
  /** delete single row from the table: "Project_News" */
  delete_Project_News_by_pk?: Maybe<Project_News>;
  /** delete data from the table: "Project_Service" */
  delete_Project_Service?: Maybe<Project_Service_Mutation_Response>;
  /** delete single row from the table: "Project_Service" */
  delete_Project_Service_by_pk?: Maybe<Project_Service>;
  /** delete data from the table: "Project_To_Everhour" */
  delete_Project_To_Everhour?: Maybe<Project_To_Everhour_Mutation_Response>;
  /** delete single row from the table: "Project_To_Everhour" */
  delete_Project_To_Everhour_by_pk?: Maybe<Project_To_Everhour>;
  /** delete single row from the table: "Project" */
  delete_Project_by_pk?: Maybe<Project>;
  /** delete data from the table: "Service_Catalog" */
  delete_Service_Catalog?: Maybe<Service_Catalog_Mutation_Response>;
  /** delete single row from the table: "Service_Catalog" */
  delete_Service_Catalog_by_pk?: Maybe<Service_Catalog>;
  /** delete data from the table: "everhour.tasks" */
  delete_everhour_tasks?: Maybe<Everhour_Tasks_Mutation_Response>;
  /** delete single row from the table: "everhour.tasks" */
  delete_everhour_tasks_by_pk?: Maybe<Everhour_Tasks>;
  /** insert data into the table: "Customer" */
  insert_Customer?: Maybe<Customer_Mutation_Response>;
  /** insert a single row into the table: "Customer" */
  insert_Customer_one?: Maybe<Customer>;
  /** insert data into the table: "Employee" */
  insert_Employee?: Maybe<Employee_Mutation_Response>;
  /** insert data into the table: "Employee_Contract" */
  insert_Employee_Contract?: Maybe<Employee_Contract_Mutation_Response>;
  /** insert a single row into the table: "Employee_Contract" */
  insert_Employee_Contract_one?: Maybe<Employee_Contract>;
  /** insert a single row into the table: "Employee" */
  insert_Employee_one?: Maybe<Employee>;
  /** insert data into the table: "Hardware" */
  insert_Hardware?: Maybe<Hardware_Mutation_Response>;
  /** insert data into the table: "Hardware_Inventory" */
  insert_Hardware_Inventory?: Maybe<Hardware_Inventory_Mutation_Response>;
  /** insert a single row into the table: "Hardware_Inventory" */
  insert_Hardware_Inventory_one?: Maybe<Hardware_Inventory>;
  /** insert a single row into the table: "Hardware" */
  insert_Hardware_one?: Maybe<Hardware>;
  /** insert data into the table: "Project" */
  insert_Project?: Maybe<Project_Mutation_Response>;
  /** insert data into the table: "Project_Contract" */
  insert_Project_Contract?: Maybe<Project_Contract_Mutation_Response>;
  /** insert a single row into the table: "Project_Contract" */
  insert_Project_Contract_one?: Maybe<Project_Contract>;
  /** insert data into the table: "Project_Invoice" */
  insert_Project_Invoice?: Maybe<Project_Invoice_Mutation_Response>;
  /** insert data into the table: "Project_Invoice_Cost" */
  insert_Project_Invoice_Cost?: Maybe<Project_Invoice_Cost_Mutation_Response>;
  /** insert a single row into the table: "Project_Invoice_Cost" */
  insert_Project_Invoice_Cost_one?: Maybe<Project_Invoice_Cost>;
  /** insert a single row into the table: "Project_Invoice" */
  insert_Project_Invoice_one?: Maybe<Project_Invoice>;
  /** insert data into the table: "Project_Member" */
  insert_Project_Member?: Maybe<Project_Member_Mutation_Response>;
  /** insert a single row into the table: "Project_Member" */
  insert_Project_Member_one?: Maybe<Project_Member>;
  /** insert data into the table: "Project_News" */
  insert_Project_News?: Maybe<Project_News_Mutation_Response>;
  /** insert a single row into the table: "Project_News" */
  insert_Project_News_one?: Maybe<Project_News>;
  /** insert data into the table: "Project_Service" */
  insert_Project_Service?: Maybe<Project_Service_Mutation_Response>;
  /** insert a single row into the table: "Project_Service" */
  insert_Project_Service_one?: Maybe<Project_Service>;
  /** insert data into the table: "Project_To_Everhour" */
  insert_Project_To_Everhour?: Maybe<Project_To_Everhour_Mutation_Response>;
  /** insert a single row into the table: "Project_To_Everhour" */
  insert_Project_To_Everhour_one?: Maybe<Project_To_Everhour>;
  /** insert a single row into the table: "Project" */
  insert_Project_one?: Maybe<Project>;
  /** insert data into the table: "Service_Catalog" */
  insert_Service_Catalog?: Maybe<Service_Catalog_Mutation_Response>;
  /** insert a single row into the table: "Service_Catalog" */
  insert_Service_Catalog_one?: Maybe<Service_Catalog>;
  /** insert data into the table: "everhour.tasks" */
  insert_everhour_tasks?: Maybe<Everhour_Tasks_Mutation_Response>;
  /** insert a single row into the table: "everhour.tasks" */
  insert_everhour_tasks_one?: Maybe<Everhour_Tasks>;
  /** update data of the table: "Customer" */
  update_Customer?: Maybe<Customer_Mutation_Response>;
  /** update single row of the table: "Customer" */
  update_Customer_by_pk?: Maybe<Customer>;
  /** update multiples rows of table: "Customer" */
  update_Customer_many?: Maybe<Array<Maybe<Customer_Mutation_Response>>>;
  /** update data of the table: "Employee" */
  update_Employee?: Maybe<Employee_Mutation_Response>;
  /** update data of the table: "Employee_Contract" */
  update_Employee_Contract?: Maybe<Employee_Contract_Mutation_Response>;
  /** update single row of the table: "Employee_Contract" */
  update_Employee_Contract_by_pk?: Maybe<Employee_Contract>;
  /** update multiples rows of table: "Employee_Contract" */
  update_Employee_Contract_many?: Maybe<Array<Maybe<Employee_Contract_Mutation_Response>>>;
  /** update single row of the table: "Employee" */
  update_Employee_by_pk?: Maybe<Employee>;
  /** update multiples rows of table: "Employee" */
  update_Employee_many?: Maybe<Array<Maybe<Employee_Mutation_Response>>>;
  /** update data of the table: "Hardware" */
  update_Hardware?: Maybe<Hardware_Mutation_Response>;
  /** update data of the table: "Hardware_Inventory" */
  update_Hardware_Inventory?: Maybe<Hardware_Inventory_Mutation_Response>;
  /** update single row of the table: "Hardware_Inventory" */
  update_Hardware_Inventory_by_pk?: Maybe<Hardware_Inventory>;
  /** update multiples rows of table: "Hardware_Inventory" */
  update_Hardware_Inventory_many?: Maybe<Array<Maybe<Hardware_Inventory_Mutation_Response>>>;
  /** update single row of the table: "Hardware" */
  update_Hardware_by_pk?: Maybe<Hardware>;
  /** update multiples rows of table: "Hardware" */
  update_Hardware_many?: Maybe<Array<Maybe<Hardware_Mutation_Response>>>;
  /** update data of the table: "Project" */
  update_Project?: Maybe<Project_Mutation_Response>;
  /** update data of the table: "Project_Contract" */
  update_Project_Contract?: Maybe<Project_Contract_Mutation_Response>;
  /** update single row of the table: "Project_Contract" */
  update_Project_Contract_by_pk?: Maybe<Project_Contract>;
  /** update multiples rows of table: "Project_Contract" */
  update_Project_Contract_many?: Maybe<Array<Maybe<Project_Contract_Mutation_Response>>>;
  /** update data of the table: "Project_Invoice" */
  update_Project_Invoice?: Maybe<Project_Invoice_Mutation_Response>;
  /** update data of the table: "Project_Invoice_Cost" */
  update_Project_Invoice_Cost?: Maybe<Project_Invoice_Cost_Mutation_Response>;
  /** update single row of the table: "Project_Invoice_Cost" */
  update_Project_Invoice_Cost_by_pk?: Maybe<Project_Invoice_Cost>;
  /** update multiples rows of table: "Project_Invoice_Cost" */
  update_Project_Invoice_Cost_many?: Maybe<Array<Maybe<Project_Invoice_Cost_Mutation_Response>>>;
  /** update single row of the table: "Project_Invoice" */
  update_Project_Invoice_by_pk?: Maybe<Project_Invoice>;
  /** update multiples rows of table: "Project_Invoice" */
  update_Project_Invoice_many?: Maybe<Array<Maybe<Project_Invoice_Mutation_Response>>>;
  /** update data of the table: "Project_Member" */
  update_Project_Member?: Maybe<Project_Member_Mutation_Response>;
  /** update single row of the table: "Project_Member" */
  update_Project_Member_by_pk?: Maybe<Project_Member>;
  /** update multiples rows of table: "Project_Member" */
  update_Project_Member_many?: Maybe<Array<Maybe<Project_Member_Mutation_Response>>>;
  /** update data of the table: "Project_News" */
  update_Project_News?: Maybe<Project_News_Mutation_Response>;
  /** update single row of the table: "Project_News" */
  update_Project_News_by_pk?: Maybe<Project_News>;
  /** update multiples rows of table: "Project_News" */
  update_Project_News_many?: Maybe<Array<Maybe<Project_News_Mutation_Response>>>;
  /** update data of the table: "Project_Service" */
  update_Project_Service?: Maybe<Project_Service_Mutation_Response>;
  /** update single row of the table: "Project_Service" */
  update_Project_Service_by_pk?: Maybe<Project_Service>;
  /** update multiples rows of table: "Project_Service" */
  update_Project_Service_many?: Maybe<Array<Maybe<Project_Service_Mutation_Response>>>;
  /** update data of the table: "Project_To_Everhour" */
  update_Project_To_Everhour?: Maybe<Project_To_Everhour_Mutation_Response>;
  /** update single row of the table: "Project_To_Everhour" */
  update_Project_To_Everhour_by_pk?: Maybe<Project_To_Everhour>;
  /** update multiples rows of table: "Project_To_Everhour" */
  update_Project_To_Everhour_many?: Maybe<Array<Maybe<Project_To_Everhour_Mutation_Response>>>;
  /** update single row of the table: "Project" */
  update_Project_by_pk?: Maybe<Project>;
  /** update multiples rows of table: "Project" */
  update_Project_many?: Maybe<Array<Maybe<Project_Mutation_Response>>>;
  /** update data of the table: "Service_Catalog" */
  update_Service_Catalog?: Maybe<Service_Catalog_Mutation_Response>;
  /** update single row of the table: "Service_Catalog" */
  update_Service_Catalog_by_pk?: Maybe<Service_Catalog>;
  /** update multiples rows of table: "Service_Catalog" */
  update_Service_Catalog_many?: Maybe<Array<Maybe<Service_Catalog_Mutation_Response>>>;
  /** update data of the table: "everhour.tasks" */
  update_everhour_tasks?: Maybe<Everhour_Tasks_Mutation_Response>;
  /** update single row of the table: "everhour.tasks" */
  update_everhour_tasks_by_pk?: Maybe<Everhour_Tasks>;
  /** update multiples rows of table: "everhour.tasks" */
  update_everhour_tasks_many?: Maybe<Array<Maybe<Everhour_Tasks_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_CustomerArgs = {
  where: Customer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customer_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_EmployeeArgs = {
  where: Employee_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Employee_ContractArgs = {
  where: Employee_Contract_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Employee_Contract_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Employee_By_PkArgs = {
  email: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_HardwareArgs = {
  where: Hardware_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hardware_InventoryArgs = {
  where: Hardware_Inventory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hardware_Inventory_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Hardware_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ProjectArgs = {
  where: Project_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_ContractArgs = {
  where: Project_Contract_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_Contract_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Project_InvoiceArgs = {
  where: Project_Invoice_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_Invoice_CostArgs = {
  where: Project_Invoice_Cost_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_Invoice_Cost_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Project_Invoice_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Project_MemberArgs = {
  where: Project_Member_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_Member_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Project_NewsArgs = {
  where: Project_News_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_News_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Project_ServiceArgs = {
  where: Project_Service_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_Service_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Project_To_EverhourArgs = {
  where: Project_To_Everhour_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_To_Everhour_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Project_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Service_CatalogArgs = {
  where: Service_Catalog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Service_Catalog_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Everhour_TasksArgs = {
  where: Everhour_Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Everhour_Tasks_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_CustomerArgs = {
  objects: Array<Customer_Insert_Input>;
  on_conflict?: InputMaybe<Customer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_OneArgs = {
  object: Customer_Insert_Input;
  on_conflict?: InputMaybe<Customer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmployeeArgs = {
  objects: Array<Employee_Insert_Input>;
  on_conflict?: InputMaybe<Employee_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Employee_ContractArgs = {
  objects: Array<Employee_Contract_Insert_Input>;
  on_conflict?: InputMaybe<Employee_Contract_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Employee_Contract_OneArgs = {
  object: Employee_Contract_Insert_Input;
  on_conflict?: InputMaybe<Employee_Contract_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Employee_OneArgs = {
  object: Employee_Insert_Input;
  on_conflict?: InputMaybe<Employee_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_HardwareArgs = {
  objects: Array<Hardware_Insert_Input>;
  on_conflict?: InputMaybe<Hardware_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hardware_InventoryArgs = {
  objects: Array<Hardware_Inventory_Insert_Input>;
  on_conflict?: InputMaybe<Hardware_Inventory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hardware_Inventory_OneArgs = {
  object: Hardware_Inventory_Insert_Input;
  on_conflict?: InputMaybe<Hardware_Inventory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hardware_OneArgs = {
  object: Hardware_Insert_Input;
  on_conflict?: InputMaybe<Hardware_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProjectArgs = {
  objects: Array<Project_Insert_Input>;
  on_conflict?: InputMaybe<Project_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_ContractArgs = {
  objects: Array<Project_Contract_Insert_Input>;
  on_conflict?: InputMaybe<Project_Contract_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_Contract_OneArgs = {
  object: Project_Contract_Insert_Input;
  on_conflict?: InputMaybe<Project_Contract_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_InvoiceArgs = {
  objects: Array<Project_Invoice_Insert_Input>;
  on_conflict?: InputMaybe<Project_Invoice_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_Invoice_CostArgs = {
  objects: Array<Project_Invoice_Cost_Insert_Input>;
  on_conflict?: InputMaybe<Project_Invoice_Cost_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_Invoice_Cost_OneArgs = {
  object: Project_Invoice_Cost_Insert_Input;
  on_conflict?: InputMaybe<Project_Invoice_Cost_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_Invoice_OneArgs = {
  object: Project_Invoice_Insert_Input;
  on_conflict?: InputMaybe<Project_Invoice_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_MemberArgs = {
  objects: Array<Project_Member_Insert_Input>;
  on_conflict?: InputMaybe<Project_Member_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_Member_OneArgs = {
  object: Project_Member_Insert_Input;
  on_conflict?: InputMaybe<Project_Member_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_NewsArgs = {
  objects: Array<Project_News_Insert_Input>;
  on_conflict?: InputMaybe<Project_News_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_News_OneArgs = {
  object: Project_News_Insert_Input;
  on_conflict?: InputMaybe<Project_News_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_ServiceArgs = {
  objects: Array<Project_Service_Insert_Input>;
  on_conflict?: InputMaybe<Project_Service_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_Service_OneArgs = {
  object: Project_Service_Insert_Input;
  on_conflict?: InputMaybe<Project_Service_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_To_EverhourArgs = {
  objects: Array<Project_To_Everhour_Insert_Input>;
  on_conflict?: InputMaybe<Project_To_Everhour_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_To_Everhour_OneArgs = {
  object: Project_To_Everhour_Insert_Input;
  on_conflict?: InputMaybe<Project_To_Everhour_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_OneArgs = {
  object: Project_Insert_Input;
  on_conflict?: InputMaybe<Project_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Service_CatalogArgs = {
  objects: Array<Service_Catalog_Insert_Input>;
  on_conflict?: InputMaybe<Service_Catalog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Service_Catalog_OneArgs = {
  object: Service_Catalog_Insert_Input;
  on_conflict?: InputMaybe<Service_Catalog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Everhour_TasksArgs = {
  objects: Array<Everhour_Tasks_Insert_Input>;
  on_conflict?: InputMaybe<Everhour_Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Everhour_Tasks_OneArgs = {
  object: Everhour_Tasks_Insert_Input;
  on_conflict?: InputMaybe<Everhour_Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_CustomerArgs = {
  _inc?: InputMaybe<Customer_Inc_Input>;
  _set?: InputMaybe<Customer_Set_Input>;
  where: Customer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_By_PkArgs = {
  _inc?: InputMaybe<Customer_Inc_Input>;
  _set?: InputMaybe<Customer_Set_Input>;
  pk_columns: Customer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_ManyArgs = {
  updates: Array<Customer_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EmployeeArgs = {
  _set?: InputMaybe<Employee_Set_Input>;
  where: Employee_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Employee_ContractArgs = {
  _inc?: InputMaybe<Employee_Contract_Inc_Input>;
  _set?: InputMaybe<Employee_Contract_Set_Input>;
  where: Employee_Contract_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Employee_Contract_By_PkArgs = {
  _inc?: InputMaybe<Employee_Contract_Inc_Input>;
  _set?: InputMaybe<Employee_Contract_Set_Input>;
  pk_columns: Employee_Contract_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Employee_Contract_ManyArgs = {
  updates: Array<Employee_Contract_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Employee_By_PkArgs = {
  _set?: InputMaybe<Employee_Set_Input>;
  pk_columns: Employee_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Employee_ManyArgs = {
  updates: Array<Employee_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_HardwareArgs = {
  _inc?: InputMaybe<Hardware_Inc_Input>;
  _set?: InputMaybe<Hardware_Set_Input>;
  where: Hardware_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hardware_InventoryArgs = {
  _inc?: InputMaybe<Hardware_Inventory_Inc_Input>;
  _set?: InputMaybe<Hardware_Inventory_Set_Input>;
  where: Hardware_Inventory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hardware_Inventory_By_PkArgs = {
  _inc?: InputMaybe<Hardware_Inventory_Inc_Input>;
  _set?: InputMaybe<Hardware_Inventory_Set_Input>;
  pk_columns: Hardware_Inventory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Hardware_Inventory_ManyArgs = {
  updates: Array<Hardware_Inventory_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Hardware_By_PkArgs = {
  _inc?: InputMaybe<Hardware_Inc_Input>;
  _set?: InputMaybe<Hardware_Set_Input>;
  pk_columns: Hardware_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Hardware_ManyArgs = {
  updates: Array<Hardware_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProjectArgs = {
  _inc?: InputMaybe<Project_Inc_Input>;
  _set?: InputMaybe<Project_Set_Input>;
  where: Project_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_ContractArgs = {
  _inc?: InputMaybe<Project_Contract_Inc_Input>;
  _set?: InputMaybe<Project_Contract_Set_Input>;
  where: Project_Contract_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_Contract_By_PkArgs = {
  _inc?: InputMaybe<Project_Contract_Inc_Input>;
  _set?: InputMaybe<Project_Contract_Set_Input>;
  pk_columns: Project_Contract_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Project_Contract_ManyArgs = {
  updates: Array<Project_Contract_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Project_InvoiceArgs = {
  _inc?: InputMaybe<Project_Invoice_Inc_Input>;
  _set?: InputMaybe<Project_Invoice_Set_Input>;
  where: Project_Invoice_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_Invoice_CostArgs = {
  _inc?: InputMaybe<Project_Invoice_Cost_Inc_Input>;
  _set?: InputMaybe<Project_Invoice_Cost_Set_Input>;
  where: Project_Invoice_Cost_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_Invoice_Cost_By_PkArgs = {
  _inc?: InputMaybe<Project_Invoice_Cost_Inc_Input>;
  _set?: InputMaybe<Project_Invoice_Cost_Set_Input>;
  pk_columns: Project_Invoice_Cost_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Project_Invoice_Cost_ManyArgs = {
  updates: Array<Project_Invoice_Cost_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Project_Invoice_By_PkArgs = {
  _inc?: InputMaybe<Project_Invoice_Inc_Input>;
  _set?: InputMaybe<Project_Invoice_Set_Input>;
  pk_columns: Project_Invoice_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Project_Invoice_ManyArgs = {
  updates: Array<Project_Invoice_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Project_MemberArgs = {
  _inc?: InputMaybe<Project_Member_Inc_Input>;
  _set?: InputMaybe<Project_Member_Set_Input>;
  where: Project_Member_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_Member_By_PkArgs = {
  _inc?: InputMaybe<Project_Member_Inc_Input>;
  _set?: InputMaybe<Project_Member_Set_Input>;
  pk_columns: Project_Member_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Project_Member_ManyArgs = {
  updates: Array<Project_Member_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Project_NewsArgs = {
  _inc?: InputMaybe<Project_News_Inc_Input>;
  _set?: InputMaybe<Project_News_Set_Input>;
  where: Project_News_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_News_By_PkArgs = {
  _inc?: InputMaybe<Project_News_Inc_Input>;
  _set?: InputMaybe<Project_News_Set_Input>;
  pk_columns: Project_News_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Project_News_ManyArgs = {
  updates: Array<Project_News_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Project_ServiceArgs = {
  _inc?: InputMaybe<Project_Service_Inc_Input>;
  _set?: InputMaybe<Project_Service_Set_Input>;
  where: Project_Service_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_Service_By_PkArgs = {
  _inc?: InputMaybe<Project_Service_Inc_Input>;
  _set?: InputMaybe<Project_Service_Set_Input>;
  pk_columns: Project_Service_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Project_Service_ManyArgs = {
  updates: Array<Project_Service_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Project_To_EverhourArgs = {
  _inc?: InputMaybe<Project_To_Everhour_Inc_Input>;
  _set?: InputMaybe<Project_To_Everhour_Set_Input>;
  where: Project_To_Everhour_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_To_Everhour_By_PkArgs = {
  _inc?: InputMaybe<Project_To_Everhour_Inc_Input>;
  _set?: InputMaybe<Project_To_Everhour_Set_Input>;
  pk_columns: Project_To_Everhour_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Project_To_Everhour_ManyArgs = {
  updates: Array<Project_To_Everhour_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Project_By_PkArgs = {
  _inc?: InputMaybe<Project_Inc_Input>;
  _set?: InputMaybe<Project_Set_Input>;
  pk_columns: Project_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Project_ManyArgs = {
  updates: Array<Project_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Service_CatalogArgs = {
  _inc?: InputMaybe<Service_Catalog_Inc_Input>;
  _set?: InputMaybe<Service_Catalog_Set_Input>;
  where: Service_Catalog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Service_Catalog_By_PkArgs = {
  _inc?: InputMaybe<Service_Catalog_Inc_Input>;
  _set?: InputMaybe<Service_Catalog_Set_Input>;
  pk_columns: Service_Catalog_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Service_Catalog_ManyArgs = {
  updates: Array<Service_Catalog_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Everhour_TasksArgs = {
  _append?: InputMaybe<Everhour_Tasks_Append_Input>;
  _delete_at_path?: InputMaybe<Everhour_Tasks_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Everhour_Tasks_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Everhour_Tasks_Delete_Key_Input>;
  _inc?: InputMaybe<Everhour_Tasks_Inc_Input>;
  _prepend?: InputMaybe<Everhour_Tasks_Prepend_Input>;
  _set?: InputMaybe<Everhour_Tasks_Set_Input>;
  where: Everhour_Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Everhour_Tasks_By_PkArgs = {
  _append?: InputMaybe<Everhour_Tasks_Append_Input>;
  _delete_at_path?: InputMaybe<Everhour_Tasks_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Everhour_Tasks_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Everhour_Tasks_Delete_Key_Input>;
  _inc?: InputMaybe<Everhour_Tasks_Inc_Input>;
  _prepend?: InputMaybe<Everhour_Tasks_Prepend_Input>;
  _set?: InputMaybe<Everhour_Tasks_Set_Input>;
  pk_columns: Everhour_Tasks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Everhour_Tasks_ManyArgs = {
  updates: Array<Everhour_Tasks_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "Customer" */
  Customer: Array<Customer>;
  /** fetch aggregated fields from the table: "Customer" */
  Customer_aggregate: Customer_Aggregate;
  /** fetch data from the table: "Customer" using primary key columns */
  Customer_by_pk?: Maybe<Customer>;
  /** fetch data from the table: "Employee" */
  Employee: Array<Employee>;
  /** fetch data from the table: "Employee_Contract" */
  Employee_Contract: Array<Employee_Contract>;
  /** fetch aggregated fields from the table: "Employee_Contract" */
  Employee_Contract_aggregate: Employee_Contract_Aggregate;
  /** fetch data from the table: "Employee_Contract" using primary key columns */
  Employee_Contract_by_pk?: Maybe<Employee_Contract>;
  /** fetch aggregated fields from the table: "Employee" */
  Employee_aggregate: Employee_Aggregate;
  /** fetch data from the table: "Employee" using primary key columns */
  Employee_by_pk?: Maybe<Employee>;
  /** An array relationship */
  Hardware: Array<Hardware>;
  /** fetch data from the table: "Hardware_Inventory" */
  Hardware_Inventory: Array<Hardware_Inventory>;
  /** fetch aggregated fields from the table: "Hardware_Inventory" */
  Hardware_Inventory_aggregate: Hardware_Inventory_Aggregate;
  /** fetch data from the table: "Hardware_Inventory" using primary key columns */
  Hardware_Inventory_by_pk?: Maybe<Hardware_Inventory>;
  /** An aggregate relationship */
  Hardware_aggregate: Hardware_Aggregate;
  /** fetch data from the table: "Hardware" using primary key columns */
  Hardware_by_pk?: Maybe<Hardware>;
  /** fetch data from the table: "Project" */
  Project: Array<Project>;
  /** fetch data from the table: "Project_Contract" */
  Project_Contract: Array<Project_Contract>;
  /** fetch aggregated fields from the table: "Project_Contract" */
  Project_Contract_aggregate: Project_Contract_Aggregate;
  /** fetch data from the table: "Project_Contract" using primary key columns */
  Project_Contract_by_pk?: Maybe<Project_Contract>;
  /** fetch data from the table: "Project_Invoice" */
  Project_Invoice: Array<Project_Invoice>;
  /** fetch data from the table: "Project_Invoice_Cost" */
  Project_Invoice_Cost: Array<Project_Invoice_Cost>;
  /** fetch aggregated fields from the table: "Project_Invoice_Cost" */
  Project_Invoice_Cost_aggregate: Project_Invoice_Cost_Aggregate;
  /** fetch data from the table: "Project_Invoice_Cost" using primary key columns */
  Project_Invoice_Cost_by_pk?: Maybe<Project_Invoice_Cost>;
  /** fetch aggregated fields from the table: "Project_Invoice" */
  Project_Invoice_aggregate: Project_Invoice_Aggregate;
  /** fetch data from the table: "Project_Invoice" using primary key columns */
  Project_Invoice_by_pk?: Maybe<Project_Invoice>;
  /** fetch data from the table: "Project_Member" */
  Project_Member: Array<Project_Member>;
  /** fetch aggregated fields from the table: "Project_Member" */
  Project_Member_aggregate: Project_Member_Aggregate;
  /** fetch data from the table: "Project_Member" using primary key columns */
  Project_Member_by_pk?: Maybe<Project_Member>;
  /** fetch data from the table: "Project_News" */
  Project_News: Array<Project_News>;
  /** fetch aggregated fields from the table: "Project_News" */
  Project_News_aggregate: Project_News_Aggregate;
  /** fetch data from the table: "Project_News" using primary key columns */
  Project_News_by_pk?: Maybe<Project_News>;
  /** fetch data from the table: "Project_Service" */
  Project_Service: Array<Project_Service>;
  /** fetch aggregated fields from the table: "Project_Service" */
  Project_Service_aggregate: Project_Service_Aggregate;
  /** fetch data from the table: "Project_Service" using primary key columns */
  Project_Service_by_pk?: Maybe<Project_Service>;
  /** fetch data from the table: "Project_To_Everhour" */
  Project_To_Everhour: Array<Project_To_Everhour>;
  /** fetch aggregated fields from the table: "Project_To_Everhour" */
  Project_To_Everhour_aggregate: Project_To_Everhour_Aggregate;
  /** fetch data from the table: "Project_To_Everhour" using primary key columns */
  Project_To_Everhour_by_pk?: Maybe<Project_To_Everhour>;
  /** fetch aggregated fields from the table: "Project" */
  Project_aggregate: Project_Aggregate;
  /** fetch data from the table: "Project" using primary key columns */
  Project_by_pk?: Maybe<Project>;
  /** fetch data from the table: "Service_Catalog" */
  Service_Catalog: Array<Service_Catalog>;
  /** fetch aggregated fields from the table: "Service_Catalog" */
  Service_Catalog_aggregate: Service_Catalog_Aggregate;
  /** fetch data from the table: "Service_Catalog" using primary key columns */
  Service_Catalog_by_pk?: Maybe<Service_Catalog>;
  /** fetch data from the table: "everhour.tasks" */
  everhour_tasks: Array<Everhour_Tasks>;
  /** fetch aggregated fields from the table: "everhour.tasks" */
  everhour_tasks_aggregate: Everhour_Tasks_Aggregate;
  /** fetch data from the table: "everhour.tasks" using primary key columns */
  everhour_tasks_by_pk?: Maybe<Everhour_Tasks>;
};


export type Query_RootCustomerArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_By>>;
  where?: InputMaybe<Customer_Bool_Exp>;
};


export type Query_RootCustomer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_By>>;
  where?: InputMaybe<Customer_Bool_Exp>;
};


export type Query_RootCustomer_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootEmployeeArgs = {
  distinct_on?: InputMaybe<Array<Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Order_By>>;
  where?: InputMaybe<Employee_Bool_Exp>;
};


export type Query_RootEmployee_ContractArgs = {
  distinct_on?: InputMaybe<Array<Employee_Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Contract_Order_By>>;
  where?: InputMaybe<Employee_Contract_Bool_Exp>;
};


export type Query_RootEmployee_Contract_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Contract_Order_By>>;
  where?: InputMaybe<Employee_Contract_Bool_Exp>;
};


export type Query_RootEmployee_Contract_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootEmployee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Order_By>>;
  where?: InputMaybe<Employee_Bool_Exp>;
};


export type Query_RootEmployee_By_PkArgs = {
  email: Scalars['String'];
};


export type Query_RootHardwareArgs = {
  distinct_on?: InputMaybe<Array<Hardware_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Order_By>>;
  where?: InputMaybe<Hardware_Bool_Exp>;
};


export type Query_RootHardware_InventoryArgs = {
  distinct_on?: InputMaybe<Array<Hardware_Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Inventory_Order_By>>;
  where?: InputMaybe<Hardware_Inventory_Bool_Exp>;
};


export type Query_RootHardware_Inventory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hardware_Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Inventory_Order_By>>;
  where?: InputMaybe<Hardware_Inventory_Bool_Exp>;
};


export type Query_RootHardware_Inventory_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootHardware_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hardware_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Order_By>>;
  where?: InputMaybe<Hardware_Bool_Exp>;
};


export type Query_RootHardware_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootProjectArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};


export type Query_RootProject_ContractArgs = {
  distinct_on?: InputMaybe<Array<Project_Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Contract_Order_By>>;
  where?: InputMaybe<Project_Contract_Bool_Exp>;
};


export type Query_RootProject_Contract_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Contract_Order_By>>;
  where?: InputMaybe<Project_Contract_Bool_Exp>;
};


export type Query_RootProject_Contract_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProject_InvoiceArgs = {
  distinct_on?: InputMaybe<Array<Project_Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Invoice_Order_By>>;
  where?: InputMaybe<Project_Invoice_Bool_Exp>;
};


export type Query_RootProject_Invoice_CostArgs = {
  distinct_on?: InputMaybe<Array<Project_Invoice_Cost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Invoice_Cost_Order_By>>;
  where?: InputMaybe<Project_Invoice_Cost_Bool_Exp>;
};


export type Query_RootProject_Invoice_Cost_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Invoice_Cost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Invoice_Cost_Order_By>>;
  where?: InputMaybe<Project_Invoice_Cost_Bool_Exp>;
};


export type Query_RootProject_Invoice_Cost_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProject_Invoice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Invoice_Order_By>>;
  where?: InputMaybe<Project_Invoice_Bool_Exp>;
};


export type Query_RootProject_Invoice_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootProject_MemberArgs = {
  distinct_on?: InputMaybe<Array<Project_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Member_Order_By>>;
  where?: InputMaybe<Project_Member_Bool_Exp>;
};


export type Query_RootProject_Member_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Member_Order_By>>;
  where?: InputMaybe<Project_Member_Bool_Exp>;
};


export type Query_RootProject_Member_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProject_NewsArgs = {
  distinct_on?: InputMaybe<Array<Project_News_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_News_Order_By>>;
  where?: InputMaybe<Project_News_Bool_Exp>;
};


export type Query_RootProject_News_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_News_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_News_Order_By>>;
  where?: InputMaybe<Project_News_Bool_Exp>;
};


export type Query_RootProject_News_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProject_ServiceArgs = {
  distinct_on?: InputMaybe<Array<Project_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Service_Order_By>>;
  where?: InputMaybe<Project_Service_Bool_Exp>;
};


export type Query_RootProject_Service_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Service_Order_By>>;
  where?: InputMaybe<Project_Service_Bool_Exp>;
};


export type Query_RootProject_Service_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProject_To_EverhourArgs = {
  distinct_on?: InputMaybe<Array<Project_To_Everhour_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_To_Everhour_Order_By>>;
  where?: InputMaybe<Project_To_Everhour_Bool_Exp>;
};


export type Query_RootProject_To_Everhour_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_To_Everhour_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_To_Everhour_Order_By>>;
  where?: InputMaybe<Project_To_Everhour_Bool_Exp>;
};


export type Query_RootProject_To_Everhour_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProject_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};


export type Query_RootProject_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootService_CatalogArgs = {
  distinct_on?: InputMaybe<Array<Service_Catalog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Catalog_Order_By>>;
  where?: InputMaybe<Service_Catalog_Bool_Exp>;
};


export type Query_RootService_Catalog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Service_Catalog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Catalog_Order_By>>;
  where?: InputMaybe<Service_Catalog_Bool_Exp>;
};


export type Query_RootService_Catalog_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootEverhour_TasksArgs = {
  distinct_on?: InputMaybe<Array<Everhour_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Everhour_Tasks_Order_By>>;
  where?: InputMaybe<Everhour_Tasks_Bool_Exp>;
};


export type Query_RootEverhour_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Everhour_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Everhour_Tasks_Order_By>>;
  where?: InputMaybe<Everhour_Tasks_Bool_Exp>;
};


export type Query_RootEverhour_Tasks_By_PkArgs = {
  id: Scalars['String'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "Customer" */
  Customer: Array<Customer>;
  /** fetch aggregated fields from the table: "Customer" */
  Customer_aggregate: Customer_Aggregate;
  /** fetch data from the table: "Customer" using primary key columns */
  Customer_by_pk?: Maybe<Customer>;
  /** fetch data from the table in a streaming manner: "Customer" */
  Customer_stream: Array<Customer>;
  /** fetch data from the table: "Employee" */
  Employee: Array<Employee>;
  /** fetch data from the table: "Employee_Contract" */
  Employee_Contract: Array<Employee_Contract>;
  /** fetch aggregated fields from the table: "Employee_Contract" */
  Employee_Contract_aggregate: Employee_Contract_Aggregate;
  /** fetch data from the table: "Employee_Contract" using primary key columns */
  Employee_Contract_by_pk?: Maybe<Employee_Contract>;
  /** fetch data from the table in a streaming manner: "Employee_Contract" */
  Employee_Contract_stream: Array<Employee_Contract>;
  /** fetch aggregated fields from the table: "Employee" */
  Employee_aggregate: Employee_Aggregate;
  /** fetch data from the table: "Employee" using primary key columns */
  Employee_by_pk?: Maybe<Employee>;
  /** fetch data from the table in a streaming manner: "Employee" */
  Employee_stream: Array<Employee>;
  /** An array relationship */
  Hardware: Array<Hardware>;
  /** fetch data from the table: "Hardware_Inventory" */
  Hardware_Inventory: Array<Hardware_Inventory>;
  /** fetch aggregated fields from the table: "Hardware_Inventory" */
  Hardware_Inventory_aggregate: Hardware_Inventory_Aggregate;
  /** fetch data from the table: "Hardware_Inventory" using primary key columns */
  Hardware_Inventory_by_pk?: Maybe<Hardware_Inventory>;
  /** fetch data from the table in a streaming manner: "Hardware_Inventory" */
  Hardware_Inventory_stream: Array<Hardware_Inventory>;
  /** An aggregate relationship */
  Hardware_aggregate: Hardware_Aggregate;
  /** fetch data from the table: "Hardware" using primary key columns */
  Hardware_by_pk?: Maybe<Hardware>;
  /** fetch data from the table in a streaming manner: "Hardware" */
  Hardware_stream: Array<Hardware>;
  /** fetch data from the table: "Project" */
  Project: Array<Project>;
  /** fetch data from the table: "Project_Contract" */
  Project_Contract: Array<Project_Contract>;
  /** fetch aggregated fields from the table: "Project_Contract" */
  Project_Contract_aggregate: Project_Contract_Aggregate;
  /** fetch data from the table: "Project_Contract" using primary key columns */
  Project_Contract_by_pk?: Maybe<Project_Contract>;
  /** fetch data from the table in a streaming manner: "Project_Contract" */
  Project_Contract_stream: Array<Project_Contract>;
  /** fetch data from the table: "Project_Invoice" */
  Project_Invoice: Array<Project_Invoice>;
  /** fetch data from the table: "Project_Invoice_Cost" */
  Project_Invoice_Cost: Array<Project_Invoice_Cost>;
  /** fetch aggregated fields from the table: "Project_Invoice_Cost" */
  Project_Invoice_Cost_aggregate: Project_Invoice_Cost_Aggregate;
  /** fetch data from the table: "Project_Invoice_Cost" using primary key columns */
  Project_Invoice_Cost_by_pk?: Maybe<Project_Invoice_Cost>;
  /** fetch data from the table in a streaming manner: "Project_Invoice_Cost" */
  Project_Invoice_Cost_stream: Array<Project_Invoice_Cost>;
  /** fetch aggregated fields from the table: "Project_Invoice" */
  Project_Invoice_aggregate: Project_Invoice_Aggregate;
  /** fetch data from the table: "Project_Invoice" using primary key columns */
  Project_Invoice_by_pk?: Maybe<Project_Invoice>;
  /** fetch data from the table in a streaming manner: "Project_Invoice" */
  Project_Invoice_stream: Array<Project_Invoice>;
  /** fetch data from the table: "Project_Member" */
  Project_Member: Array<Project_Member>;
  /** fetch aggregated fields from the table: "Project_Member" */
  Project_Member_aggregate: Project_Member_Aggregate;
  /** fetch data from the table: "Project_Member" using primary key columns */
  Project_Member_by_pk?: Maybe<Project_Member>;
  /** fetch data from the table in a streaming manner: "Project_Member" */
  Project_Member_stream: Array<Project_Member>;
  /** fetch data from the table: "Project_News" */
  Project_News: Array<Project_News>;
  /** fetch aggregated fields from the table: "Project_News" */
  Project_News_aggregate: Project_News_Aggregate;
  /** fetch data from the table: "Project_News" using primary key columns */
  Project_News_by_pk?: Maybe<Project_News>;
  /** fetch data from the table in a streaming manner: "Project_News" */
  Project_News_stream: Array<Project_News>;
  /** fetch data from the table: "Project_Service" */
  Project_Service: Array<Project_Service>;
  /** fetch aggregated fields from the table: "Project_Service" */
  Project_Service_aggregate: Project_Service_Aggregate;
  /** fetch data from the table: "Project_Service" using primary key columns */
  Project_Service_by_pk?: Maybe<Project_Service>;
  /** fetch data from the table in a streaming manner: "Project_Service" */
  Project_Service_stream: Array<Project_Service>;
  /** fetch data from the table: "Project_To_Everhour" */
  Project_To_Everhour: Array<Project_To_Everhour>;
  /** fetch aggregated fields from the table: "Project_To_Everhour" */
  Project_To_Everhour_aggregate: Project_To_Everhour_Aggregate;
  /** fetch data from the table: "Project_To_Everhour" using primary key columns */
  Project_To_Everhour_by_pk?: Maybe<Project_To_Everhour>;
  /** fetch data from the table in a streaming manner: "Project_To_Everhour" */
  Project_To_Everhour_stream: Array<Project_To_Everhour>;
  /** fetch aggregated fields from the table: "Project" */
  Project_aggregate: Project_Aggregate;
  /** fetch data from the table: "Project" using primary key columns */
  Project_by_pk?: Maybe<Project>;
  /** fetch data from the table in a streaming manner: "Project" */
  Project_stream: Array<Project>;
  /** fetch data from the table: "Service_Catalog" */
  Service_Catalog: Array<Service_Catalog>;
  /** fetch aggregated fields from the table: "Service_Catalog" */
  Service_Catalog_aggregate: Service_Catalog_Aggregate;
  /** fetch data from the table: "Service_Catalog" using primary key columns */
  Service_Catalog_by_pk?: Maybe<Service_Catalog>;
  /** fetch data from the table in a streaming manner: "Service_Catalog" */
  Service_Catalog_stream: Array<Service_Catalog>;
  /** fetch data from the table: "everhour.tasks" */
  everhour_tasks: Array<Everhour_Tasks>;
  /** fetch aggregated fields from the table: "everhour.tasks" */
  everhour_tasks_aggregate: Everhour_Tasks_Aggregate;
  /** fetch data from the table: "everhour.tasks" using primary key columns */
  everhour_tasks_by_pk?: Maybe<Everhour_Tasks>;
  /** fetch data from the table in a streaming manner: "everhour.tasks" */
  everhour_tasks_stream: Array<Everhour_Tasks>;
};


export type Subscription_RootCustomerArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_By>>;
  where?: InputMaybe<Customer_Bool_Exp>;
};


export type Subscription_RootCustomer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Order_By>>;
  where?: InputMaybe<Customer_Bool_Exp>;
};


export type Subscription_RootCustomer_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCustomer_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Bool_Exp>;
};


export type Subscription_RootEmployeeArgs = {
  distinct_on?: InputMaybe<Array<Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Order_By>>;
  where?: InputMaybe<Employee_Bool_Exp>;
};


export type Subscription_RootEmployee_ContractArgs = {
  distinct_on?: InputMaybe<Array<Employee_Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Contract_Order_By>>;
  where?: InputMaybe<Employee_Contract_Bool_Exp>;
};


export type Subscription_RootEmployee_Contract_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Contract_Order_By>>;
  where?: InputMaybe<Employee_Contract_Bool_Exp>;
};


export type Subscription_RootEmployee_Contract_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootEmployee_Contract_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Employee_Contract_Stream_Cursor_Input>>;
  where?: InputMaybe<Employee_Contract_Bool_Exp>;
};


export type Subscription_RootEmployee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Order_By>>;
  where?: InputMaybe<Employee_Bool_Exp>;
};


export type Subscription_RootEmployee_By_PkArgs = {
  email: Scalars['String'];
};


export type Subscription_RootEmployee_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Employee_Stream_Cursor_Input>>;
  where?: InputMaybe<Employee_Bool_Exp>;
};


export type Subscription_RootHardwareArgs = {
  distinct_on?: InputMaybe<Array<Hardware_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Order_By>>;
  where?: InputMaybe<Hardware_Bool_Exp>;
};


export type Subscription_RootHardware_InventoryArgs = {
  distinct_on?: InputMaybe<Array<Hardware_Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Inventory_Order_By>>;
  where?: InputMaybe<Hardware_Inventory_Bool_Exp>;
};


export type Subscription_RootHardware_Inventory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hardware_Inventory_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Inventory_Order_By>>;
  where?: InputMaybe<Hardware_Inventory_Bool_Exp>;
};


export type Subscription_RootHardware_Inventory_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootHardware_Inventory_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Hardware_Inventory_Stream_Cursor_Input>>;
  where?: InputMaybe<Hardware_Inventory_Bool_Exp>;
};


export type Subscription_RootHardware_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hardware_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hardware_Order_By>>;
  where?: InputMaybe<Hardware_Bool_Exp>;
};


export type Subscription_RootHardware_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootHardware_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Hardware_Stream_Cursor_Input>>;
  where?: InputMaybe<Hardware_Bool_Exp>;
};


export type Subscription_RootProjectArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};


export type Subscription_RootProject_ContractArgs = {
  distinct_on?: InputMaybe<Array<Project_Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Contract_Order_By>>;
  where?: InputMaybe<Project_Contract_Bool_Exp>;
};


export type Subscription_RootProject_Contract_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Contract_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Contract_Order_By>>;
  where?: InputMaybe<Project_Contract_Bool_Exp>;
};


export type Subscription_RootProject_Contract_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProject_Contract_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Contract_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Contract_Bool_Exp>;
};


export type Subscription_RootProject_InvoiceArgs = {
  distinct_on?: InputMaybe<Array<Project_Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Invoice_Order_By>>;
  where?: InputMaybe<Project_Invoice_Bool_Exp>;
};


export type Subscription_RootProject_Invoice_CostArgs = {
  distinct_on?: InputMaybe<Array<Project_Invoice_Cost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Invoice_Cost_Order_By>>;
  where?: InputMaybe<Project_Invoice_Cost_Bool_Exp>;
};


export type Subscription_RootProject_Invoice_Cost_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Invoice_Cost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Invoice_Cost_Order_By>>;
  where?: InputMaybe<Project_Invoice_Cost_Bool_Exp>;
};


export type Subscription_RootProject_Invoice_Cost_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProject_Invoice_Cost_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Invoice_Cost_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Invoice_Cost_Bool_Exp>;
};


export type Subscription_RootProject_Invoice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Invoice_Order_By>>;
  where?: InputMaybe<Project_Invoice_Bool_Exp>;
};


export type Subscription_RootProject_Invoice_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootProject_Invoice_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Invoice_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Invoice_Bool_Exp>;
};


export type Subscription_RootProject_MemberArgs = {
  distinct_on?: InputMaybe<Array<Project_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Member_Order_By>>;
  where?: InputMaybe<Project_Member_Bool_Exp>;
};


export type Subscription_RootProject_Member_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Member_Order_By>>;
  where?: InputMaybe<Project_Member_Bool_Exp>;
};


export type Subscription_RootProject_Member_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProject_Member_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Member_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Member_Bool_Exp>;
};


export type Subscription_RootProject_NewsArgs = {
  distinct_on?: InputMaybe<Array<Project_News_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_News_Order_By>>;
  where?: InputMaybe<Project_News_Bool_Exp>;
};


export type Subscription_RootProject_News_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_News_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_News_Order_By>>;
  where?: InputMaybe<Project_News_Bool_Exp>;
};


export type Subscription_RootProject_News_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProject_News_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_News_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_News_Bool_Exp>;
};


export type Subscription_RootProject_ServiceArgs = {
  distinct_on?: InputMaybe<Array<Project_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Service_Order_By>>;
  where?: InputMaybe<Project_Service_Bool_Exp>;
};


export type Subscription_RootProject_Service_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Service_Order_By>>;
  where?: InputMaybe<Project_Service_Bool_Exp>;
};


export type Subscription_RootProject_Service_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProject_Service_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Service_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Service_Bool_Exp>;
};


export type Subscription_RootProject_To_EverhourArgs = {
  distinct_on?: InputMaybe<Array<Project_To_Everhour_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_To_Everhour_Order_By>>;
  where?: InputMaybe<Project_To_Everhour_Bool_Exp>;
};


export type Subscription_RootProject_To_Everhour_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_To_Everhour_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_To_Everhour_Order_By>>;
  where?: InputMaybe<Project_To_Everhour_Bool_Exp>;
};


export type Subscription_RootProject_To_Everhour_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProject_To_Everhour_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_To_Everhour_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_To_Everhour_Bool_Exp>;
};


export type Subscription_RootProject_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Order_By>>;
  where?: InputMaybe<Project_Bool_Exp>;
};


export type Subscription_RootProject_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProject_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Bool_Exp>;
};


export type Subscription_RootService_CatalogArgs = {
  distinct_on?: InputMaybe<Array<Service_Catalog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Catalog_Order_By>>;
  where?: InputMaybe<Service_Catalog_Bool_Exp>;
};


export type Subscription_RootService_Catalog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Service_Catalog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Catalog_Order_By>>;
  where?: InputMaybe<Service_Catalog_Bool_Exp>;
};


export type Subscription_RootService_Catalog_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootService_Catalog_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Service_Catalog_Stream_Cursor_Input>>;
  where?: InputMaybe<Service_Catalog_Bool_Exp>;
};


export type Subscription_RootEverhour_TasksArgs = {
  distinct_on?: InputMaybe<Array<Everhour_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Everhour_Tasks_Order_By>>;
  where?: InputMaybe<Everhour_Tasks_Bool_Exp>;
};


export type Subscription_RootEverhour_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Everhour_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Everhour_Tasks_Order_By>>;
  where?: InputMaybe<Everhour_Tasks_Bool_Exp>;
};


export type Subscription_RootEverhour_Tasks_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEverhour_Tasks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Everhour_Tasks_Stream_Cursor_Input>>;
  where?: InputMaybe<Everhour_Tasks_Bool_Exp>;
};

/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export type Time_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['time']>;
  _gt?: InputMaybe<Scalars['time']>;
  _gte?: InputMaybe<Scalars['time']>;
  _in?: InputMaybe<Array<Scalars['time']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['time']>;
  _lte?: InputMaybe<Scalars['time']>;
  _neq?: InputMaybe<Scalars['time']>;
  _nin?: InputMaybe<Array<Scalars['time']>>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type GetActiveNewsThatAreNotUrgentQueryVariables = Exact<{
  selectedProjectId: Scalars['Int'];
  currentTime: Scalars['timestamptz'];
}>;


export type GetActiveNewsThatAreNotUrgentQuery = { __typename?: 'query_root', Project_News: Array<{ __typename?: 'Project_News', details?: string | null, id: number, project_id?: number | null, showFrom: any, title?: string | null, urgent?: boolean | null, variant?: string | null, showUntil: any }> };

export type GetCustomerQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomerQuery = { __typename?: 'query_root', Customer: Array<{ __typename?: 'Customer', name?: string | null, logo?: any | null }> };

export type GetNewsQueryVariables = Exact<{
  selectedProjectId: Scalars['Int'];
  currentTime: Scalars['timestamptz'];
}>;


export type GetNewsQuery = { __typename?: 'query_root', Project_News: Array<{ __typename?: 'Project_News', details?: string | null, id: number, project_id?: number | null, showFrom: any, title?: string | null, urgent?: boolean | null, variant?: string | null, showUntil: any }> };

export type GetProjectHistoryQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetProjectHistoryQuery = { __typename?: 'query_root', Project_by_pk?: { __typename?: 'Project', name?: string | null, Costs: Array<{ __typename?: 'Project_Invoice_Cost', amount_eur: any, Invoice: { __typename?: 'Project_Invoice', date_created?: any | null, id: string, invoice_date?: any | null, invoice_file?: any | null, paid?: boolean | null, short_description?: string | null, summary?: string | null, title?: string | null, user_created?: any | null, visible?: boolean | null } }> } | null };

export type EmployeeFragment = { __typename?: 'Employee', name?: string | null, skills?: any | null, since_date?: any | null, mobile_phone?: string | null, email: string, avatar_url?: string | null };

export type GetProjectMembersQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetProjectMembersQuery = { __typename?: 'query_root', Project_by_pk?: { __typename?: 'Project', Project_Members: Array<{ __typename?: 'Project_Member', role_position?: string | null, role_area?: string | null, role_activity?: string | null, start_date: any, Employee: { __typename?: 'Employee', name?: string | null, skills?: any | null, since_date?: any | null, mobile_phone?: string | null, email: string, avatar_url?: string | null } }> } | null };

export type GetProjectOverviewQueryVariables = Exact<{
  id: Scalars['Int'];
  cost_since?: InputMaybe<Scalars['date']>;
}>;


export type GetProjectOverviewQuery = { __typename?: 'query_root', Project_by_pk?: { __typename?: 'Project', name?: string | null, started_at?: any | null, description?: string | null, technologies?: any | null, Project_Members_aggregate: { __typename?: 'Project_Member_aggregate', aggregate?: { __typename?: 'Project_Member_aggregate_fields', count: number } | null }, Costs_aggregate: { __typename?: 'Project_Invoice_Cost_aggregate', aggregate?: { __typename?: 'Project_Invoice_Cost_aggregate_fields', sum?: { __typename?: 'Project_Invoice_Cost_sum_fields', amount_eur?: any | null } | null } | null } } | null };

export type GetProjectServicesForNavigationQueryVariables = Exact<{
  selectedProjectId: Scalars['Int'];
}>;


export type GetProjectServicesForNavigationQuery = { __typename?: 'query_root', Project_Service: Array<{ __typename?: 'Project_Service', display_iframe?: boolean | null, title?: string | null, id: number }> };

export type GetProjectServicesQueryVariables = Exact<{
  selectedProjectId: Scalars['Int'];
}>;


export type GetProjectServicesQuery = { __typename?: 'query_root', Project_Service: Array<{ __typename?: 'Project_Service', display_iframe?: boolean | null, title?: string | null, help_text?: string | null, icon?: string | null, id: number, link?: string | null, price_per_month?: any | null, project_id?: number | null, service_catalog_id?: number | null }> };

export type GetProjectServiceByIdQueryVariables = Exact<{
  projectServiceId: Scalars['Int'];
}>;


export type GetProjectServiceByIdQuery = { __typename?: 'query_root', service?: { __typename?: 'Project_Service', icon?: string | null, link?: string | null, title?: string | null, price_per_month?: any | null } | null };

export type GetProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProjectsQuery = { __typename?: 'query_root', Project: Array<{ __typename?: 'Project', id: number, name?: string | null }> };

export type ServiceCatalogFragment = { __typename?: 'Service_Catalog', description?: string | null, id: number, name?: string | null, icon?: string | null };

export type GetServiceCatalogQueryVariables = Exact<{ [key: string]: never; }>;


export type GetServiceCatalogQuery = { __typename?: 'query_root', Service_Catalog: Array<{ __typename?: 'Service_Catalog', description?: string | null, id: number, name?: string | null, icon?: string | null }> };

export type GetTasksQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetTasksQuery = { __typename?: 'query_root', Project_by_pk?: { __typename?: 'Project', Everhour: Array<{ __typename?: 'Project_To_Everhour', Tasks: Array<{ __typename?: 'everhour_tasks', name: string, status: string, total_time: number, url: string, iteration: string, id: string, project_id: string, completed?: boolean | null, completed_at?: any | null, created_at?: any | null, estimate_time?: number | null, labels?: any | null }> }> } | null };

export type GetUrgentActiveNewsQueryVariables = Exact<{
  selectedProjectId: Scalars['Int'];
  currentTime: Scalars['timestamptz'];
}>;


export type GetUrgentActiveNewsQuery = { __typename?: 'query_root', Project_News: Array<{ __typename?: 'Project_News', details?: string | null, id: number, project_id?: number | null, showFrom: any, title?: string | null, urgent?: boolean | null, variant?: string | null, showUntil: any }> };

export const EmployeeFragmentDoc = gql`
    fragment Employee on Employee {
  name
  skills
  since_date
  mobile_phone
  email
  avatar_url
}
    `;
export const ServiceCatalogFragmentDoc = gql`
    fragment ServiceCatalog on Service_Catalog {
  description
  id
  name
  icon
}
    `;
export const GetActiveNewsThatAreNotUrgentDocument = gql`
    query GetActiveNewsThatAreNotUrgent($selectedProjectId: Int!, $currentTime: timestamptz!) {
  Project_News(
    where: {project_id: {_eq: $selectedProjectId}, showUntil: {_gte: $currentTime}, urgent: {_eq: false}, showFrom: {_lte: $currentTime}}
    order_by: {showFrom: desc}
  ) {
    details
    id
    project_id
    showFrom
    title
    urgent
    variant
    showUntil
  }
}
    `;

/**
 * __useGetActiveNewsThatAreNotUrgentQuery__
 *
 * To run a query within a React component, call `useGetActiveNewsThatAreNotUrgentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveNewsThatAreNotUrgentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveNewsThatAreNotUrgentQuery({
 *   variables: {
 *      selectedProjectId: // value for 'selectedProjectId'
 *      currentTime: // value for 'currentTime'
 *   },
 * });
 */
export function useGetActiveNewsThatAreNotUrgentQuery(baseOptions: Apollo.QueryHookOptions<GetActiveNewsThatAreNotUrgentQuery, GetActiveNewsThatAreNotUrgentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveNewsThatAreNotUrgentQuery, GetActiveNewsThatAreNotUrgentQueryVariables>(GetActiveNewsThatAreNotUrgentDocument, options);
      }
export function useGetActiveNewsThatAreNotUrgentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveNewsThatAreNotUrgentQuery, GetActiveNewsThatAreNotUrgentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveNewsThatAreNotUrgentQuery, GetActiveNewsThatAreNotUrgentQueryVariables>(GetActiveNewsThatAreNotUrgentDocument, options);
        }
export type GetActiveNewsThatAreNotUrgentQueryHookResult = ReturnType<typeof useGetActiveNewsThatAreNotUrgentQuery>;
export type GetActiveNewsThatAreNotUrgentLazyQueryHookResult = ReturnType<typeof useGetActiveNewsThatAreNotUrgentLazyQuery>;
export type GetActiveNewsThatAreNotUrgentQueryResult = Apollo.QueryResult<GetActiveNewsThatAreNotUrgentQuery, GetActiveNewsThatAreNotUrgentQueryVariables>;
export const GetCustomerDocument = gql`
    query getCustomer {
  Customer {
    name
    logo
  }
}
    `;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
      }
export function useGetCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
        }
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
export const GetNewsDocument = gql`
    query GetNews($selectedProjectId: Int!, $currentTime: timestamptz!) {
  Project_News(
    where: {project_id: {_eq: $selectedProjectId}, showFrom: {_lte: $currentTime}}
    order_by: {showFrom: desc}
  ) {
    details
    id
    project_id
    showFrom
    title
    urgent
    variant
    showUntil
  }
}
    `;

/**
 * __useGetNewsQuery__
 *
 * To run a query within a React component, call `useGetNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsQuery({
 *   variables: {
 *      selectedProjectId: // value for 'selectedProjectId'
 *      currentTime: // value for 'currentTime'
 *   },
 * });
 */
export function useGetNewsQuery(baseOptions: Apollo.QueryHookOptions<GetNewsQuery, GetNewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNewsQuery, GetNewsQueryVariables>(GetNewsDocument, options);
      }
export function useGetNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewsQuery, GetNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNewsQuery, GetNewsQueryVariables>(GetNewsDocument, options);
        }
export type GetNewsQueryHookResult = ReturnType<typeof useGetNewsQuery>;
export type GetNewsLazyQueryHookResult = ReturnType<typeof useGetNewsLazyQuery>;
export type GetNewsQueryResult = Apollo.QueryResult<GetNewsQuery, GetNewsQueryVariables>;
export const GetProjectHistoryDocument = gql`
    query GetProjectHistory($id: Int!) {
  Project_by_pk(id: $id) {
    name
    Costs(order_by: {Invoice: {invoice_date: desc}}) {
      amount_eur
      Invoice {
        date_created
        id
        invoice_date
        invoice_file
        paid
        short_description
        summary
        title
        user_created
        visible
      }
    }
  }
}
    `;

/**
 * __useGetProjectHistoryQuery__
 *
 * To run a query within a React component, call `useGetProjectHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetProjectHistoryQuery, GetProjectHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectHistoryQuery, GetProjectHistoryQueryVariables>(GetProjectHistoryDocument, options);
      }
export function useGetProjectHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectHistoryQuery, GetProjectHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectHistoryQuery, GetProjectHistoryQueryVariables>(GetProjectHistoryDocument, options);
        }
export type GetProjectHistoryQueryHookResult = ReturnType<typeof useGetProjectHistoryQuery>;
export type GetProjectHistoryLazyQueryHookResult = ReturnType<typeof useGetProjectHistoryLazyQuery>;
export type GetProjectHistoryQueryResult = Apollo.QueryResult<GetProjectHistoryQuery, GetProjectHistoryQueryVariables>;
export const GetProjectMembersDocument = gql`
    query GetProjectMembers($id: Int!) {
  Project_by_pk(id: $id) {
    Project_Members {
      role_position
      role_area
      role_activity
      start_date
      Employee {
        ...Employee
      }
    }
  }
}
    ${EmployeeFragmentDoc}`;

/**
 * __useGetProjectMembersQuery__
 *
 * To run a query within a React component, call `useGetProjectMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectMembersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectMembersQuery(baseOptions: Apollo.QueryHookOptions<GetProjectMembersQuery, GetProjectMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectMembersQuery, GetProjectMembersQueryVariables>(GetProjectMembersDocument, options);
      }
export function useGetProjectMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectMembersQuery, GetProjectMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectMembersQuery, GetProjectMembersQueryVariables>(GetProjectMembersDocument, options);
        }
export type GetProjectMembersQueryHookResult = ReturnType<typeof useGetProjectMembersQuery>;
export type GetProjectMembersLazyQueryHookResult = ReturnType<typeof useGetProjectMembersLazyQuery>;
export type GetProjectMembersQueryResult = Apollo.QueryResult<GetProjectMembersQuery, GetProjectMembersQueryVariables>;
export const GetProjectOverviewDocument = gql`
    query GetProjectOverview($id: Int!, $cost_since: date = "") {
  Project_by_pk(id: $id) {
    name
    started_at
    description
    technologies
    Project_Members_aggregate {
      aggregate {
        count
      }
    }
    Costs_aggregate(where: {Invoice: {invoice_date: {_gte: $cost_since}}}) {
      aggregate {
        sum {
          amount_eur
        }
      }
    }
  }
}
    `;

/**
 * __useGetProjectOverviewQuery__
 *
 * To run a query within a React component, call `useGetProjectOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectOverviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *      cost_since: // value for 'cost_since'
 *   },
 * });
 */
export function useGetProjectOverviewQuery(baseOptions: Apollo.QueryHookOptions<GetProjectOverviewQuery, GetProjectOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectOverviewQuery, GetProjectOverviewQueryVariables>(GetProjectOverviewDocument, options);
      }
export function useGetProjectOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectOverviewQuery, GetProjectOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectOverviewQuery, GetProjectOverviewQueryVariables>(GetProjectOverviewDocument, options);
        }
export type GetProjectOverviewQueryHookResult = ReturnType<typeof useGetProjectOverviewQuery>;
export type GetProjectOverviewLazyQueryHookResult = ReturnType<typeof useGetProjectOverviewLazyQuery>;
export type GetProjectOverviewQueryResult = Apollo.QueryResult<GetProjectOverviewQuery, GetProjectOverviewQueryVariables>;
export const GetProjectServicesForNavigationDocument = gql`
    query GetProjectServicesForNavigation($selectedProjectId: Int!) {
  Project_Service(where: {project_id: {_eq: $selectedProjectId}}) {
    display_iframe
    title
    id
  }
}
    `;

/**
 * __useGetProjectServicesForNavigationQuery__
 *
 * To run a query within a React component, call `useGetProjectServicesForNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectServicesForNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectServicesForNavigationQuery({
 *   variables: {
 *      selectedProjectId: // value for 'selectedProjectId'
 *   },
 * });
 */
export function useGetProjectServicesForNavigationQuery(baseOptions: Apollo.QueryHookOptions<GetProjectServicesForNavigationQuery, GetProjectServicesForNavigationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectServicesForNavigationQuery, GetProjectServicesForNavigationQueryVariables>(GetProjectServicesForNavigationDocument, options);
      }
export function useGetProjectServicesForNavigationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectServicesForNavigationQuery, GetProjectServicesForNavigationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectServicesForNavigationQuery, GetProjectServicesForNavigationQueryVariables>(GetProjectServicesForNavigationDocument, options);
        }
export type GetProjectServicesForNavigationQueryHookResult = ReturnType<typeof useGetProjectServicesForNavigationQuery>;
export type GetProjectServicesForNavigationLazyQueryHookResult = ReturnType<typeof useGetProjectServicesForNavigationLazyQuery>;
export type GetProjectServicesForNavigationQueryResult = Apollo.QueryResult<GetProjectServicesForNavigationQuery, GetProjectServicesForNavigationQueryVariables>;
export const GetProjectServicesDocument = gql`
    query GetProjectServices($selectedProjectId: Int!) {
  Project_Service(where: {project_id: {_eq: $selectedProjectId}}) {
    display_iframe
    title
    help_text
    icon
    id
    link
    price_per_month
    project_id
    service_catalog_id
  }
}
    `;

/**
 * __useGetProjectServicesQuery__
 *
 * To run a query within a React component, call `useGetProjectServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectServicesQuery({
 *   variables: {
 *      selectedProjectId: // value for 'selectedProjectId'
 *   },
 * });
 */
export function useGetProjectServicesQuery(baseOptions: Apollo.QueryHookOptions<GetProjectServicesQuery, GetProjectServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectServicesQuery, GetProjectServicesQueryVariables>(GetProjectServicesDocument, options);
      }
export function useGetProjectServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectServicesQuery, GetProjectServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectServicesQuery, GetProjectServicesQueryVariables>(GetProjectServicesDocument, options);
        }
export type GetProjectServicesQueryHookResult = ReturnType<typeof useGetProjectServicesQuery>;
export type GetProjectServicesLazyQueryHookResult = ReturnType<typeof useGetProjectServicesLazyQuery>;
export type GetProjectServicesQueryResult = Apollo.QueryResult<GetProjectServicesQuery, GetProjectServicesQueryVariables>;
export const GetProjectServiceByIdDocument = gql`
    query GetProjectServiceById($projectServiceId: Int!) {
  service: Project_Service_by_pk(id: $projectServiceId) {
    icon
    link
    title
    price_per_month
  }
}
    `;

/**
 * __useGetProjectServiceByIdQuery__
 *
 * To run a query within a React component, call `useGetProjectServiceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectServiceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectServiceByIdQuery({
 *   variables: {
 *      projectServiceId: // value for 'projectServiceId'
 *   },
 * });
 */
export function useGetProjectServiceByIdQuery(baseOptions: Apollo.QueryHookOptions<GetProjectServiceByIdQuery, GetProjectServiceByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectServiceByIdQuery, GetProjectServiceByIdQueryVariables>(GetProjectServiceByIdDocument, options);
      }
export function useGetProjectServiceByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectServiceByIdQuery, GetProjectServiceByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectServiceByIdQuery, GetProjectServiceByIdQueryVariables>(GetProjectServiceByIdDocument, options);
        }
export type GetProjectServiceByIdQueryHookResult = ReturnType<typeof useGetProjectServiceByIdQuery>;
export type GetProjectServiceByIdLazyQueryHookResult = ReturnType<typeof useGetProjectServiceByIdLazyQuery>;
export type GetProjectServiceByIdQueryResult = Apollo.QueryResult<GetProjectServiceByIdQuery, GetProjectServiceByIdQueryVariables>;
export const GetProjectsDocument = gql`
    query getProjects {
  Project {
    id
    name
  }
}
    `;

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectsQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
      }
export function useGetProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
        }
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<typeof useGetProjectsLazyQuery>;
export type GetProjectsQueryResult = Apollo.QueryResult<GetProjectsQuery, GetProjectsQueryVariables>;
export const GetServiceCatalogDocument = gql`
    query GetServiceCatalog {
  Service_Catalog {
    ...ServiceCatalog
  }
}
    ${ServiceCatalogFragmentDoc}`;

/**
 * __useGetServiceCatalogQuery__
 *
 * To run a query within a React component, call `useGetServiceCatalogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceCatalogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceCatalogQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetServiceCatalogQuery(baseOptions?: Apollo.QueryHookOptions<GetServiceCatalogQuery, GetServiceCatalogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServiceCatalogQuery, GetServiceCatalogQueryVariables>(GetServiceCatalogDocument, options);
      }
export function useGetServiceCatalogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServiceCatalogQuery, GetServiceCatalogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServiceCatalogQuery, GetServiceCatalogQueryVariables>(GetServiceCatalogDocument, options);
        }
export type GetServiceCatalogQueryHookResult = ReturnType<typeof useGetServiceCatalogQuery>;
export type GetServiceCatalogLazyQueryHookResult = ReturnType<typeof useGetServiceCatalogLazyQuery>;
export type GetServiceCatalogQueryResult = Apollo.QueryResult<GetServiceCatalogQuery, GetServiceCatalogQueryVariables>;
export const GetTasksDocument = gql`
    query getTasks($id: Int!) {
  Project_by_pk(id: $id) {
    Everhour {
      Tasks(where: {total_time: {_gt: 0}}, order_by: {created_at: desc}, limit: 100) {
        name
        status
        total_time
        url
        iteration
        id
        project_id
        completed
        completed_at
        created_at
        estimate_time
        labels
      }
    }
  }
}
    `;

/**
 * __useGetTasksQuery__
 *
 * To run a query within a React component, call `useGetTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTasksQuery(baseOptions: Apollo.QueryHookOptions<GetTasksQuery, GetTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, options);
      }
export function useGetTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTasksQuery, GetTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, options);
        }
export type GetTasksQueryHookResult = ReturnType<typeof useGetTasksQuery>;
export type GetTasksLazyQueryHookResult = ReturnType<typeof useGetTasksLazyQuery>;
export type GetTasksQueryResult = Apollo.QueryResult<GetTasksQuery, GetTasksQueryVariables>;
export const GetUrgentActiveNewsDocument = gql`
    query GetUrgentActiveNews($selectedProjectId: Int!, $currentTime: timestamptz!) {
  Project_News(
    where: {project_id: {_eq: $selectedProjectId}, showUntil: {_gte: $currentTime}, urgent: {_eq: true}, showFrom: {_lte: $currentTime}}
    order_by: {showFrom: desc}
  ) {
    details
    id
    project_id
    showFrom
    title
    urgent
    variant
    showUntil
  }
}
    `;

/**
 * __useGetUrgentActiveNewsQuery__
 *
 * To run a query within a React component, call `useGetUrgentActiveNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUrgentActiveNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUrgentActiveNewsQuery({
 *   variables: {
 *      selectedProjectId: // value for 'selectedProjectId'
 *      currentTime: // value for 'currentTime'
 *   },
 * });
 */
export function useGetUrgentActiveNewsQuery(baseOptions: Apollo.QueryHookOptions<GetUrgentActiveNewsQuery, GetUrgentActiveNewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUrgentActiveNewsQuery, GetUrgentActiveNewsQueryVariables>(GetUrgentActiveNewsDocument, options);
      }
export function useGetUrgentActiveNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUrgentActiveNewsQuery, GetUrgentActiveNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUrgentActiveNewsQuery, GetUrgentActiveNewsQueryVariables>(GetUrgentActiveNewsDocument, options);
        }
export type GetUrgentActiveNewsQueryHookResult = ReturnType<typeof useGetUrgentActiveNewsQuery>;
export type GetUrgentActiveNewsLazyQueryHookResult = ReturnType<typeof useGetUrgentActiveNewsLazyQuery>;
export type GetUrgentActiveNewsQueryResult = Apollo.QueryResult<GetUrgentActiveNewsQuery, GetUrgentActiveNewsQueryVariables>;