import {createStateContext} from "react-use";

interface UserInfo {
    name: string
    loggedIn: boolean
    companyId: number,
    isManager: boolean,
    isCustomer: boolean,
    avatarFile: string
}

const [useUserInfo, UserInfoProvider] = createStateContext<UserInfo>({
    name: "",
    loggedIn: false,
    companyId: -1,
    isManager: false,
    isCustomer: false,
    avatarFile: ""
})


export {useUserInfo, UserInfoProvider}
