import 'simplebar/src/simplebar.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import {CollapseDrawerProvider} from './contexts/CollapseDrawerContext';

import App from './App';
import {AuthProvider} from './contexts/JWTContext';
import {UserInfoProvider} from './hooks/useUserInfo';

import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

LogRocket.init('selectcode/selectproject');
setupLogRocketReact(LogRocket);

ReactDOM.render(
    <UserInfoProvider>
        <AuthProvider>
            <HelmetProvider>
                <CollapseDrawerProvider>
                    <BrowserRouter>
                        <App/>
                    </BrowserRouter>
                </CollapseDrawerProvider>
            </HelmetProvider>
        </AuthProvider>
    </UserInfoProvider>
    ,
    document.getElementById('root')
);
