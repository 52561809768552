import { Project_News, useGetNewsQuery } from '../generated/graphql';
import { Box, Typography } from '@mui/material';
import Page from '../components/Page';
import useProject from '../utils/useProject';
import NewsMessage from '../components/NewsMessage';
import { useState } from 'react';
import { fToGQLRepresentation } from '../utils/formatTime';

export default function News() {
  const [projectId] = useProject();
  const [currentDate] = useState(new Date());

  const news: Project_News[] | undefined = useGetNewsQuery({
    variables: {
      selectedProjectId: projectId,
      currentTime: fToGQLRepresentation(currentDate),
    },
  }).data?.Project_News;

  return (
    <Page title="News">
      <Typography variant="h3" component="h1" paragraph>
        News
      </Typography>
      <NewsHistory news={news ?? []} />
    </Page>
  );
}

type NewsHistoryProps = {
  news: Project_News[];
};

function NewsHistory({ news }: NewsHistoryProps) {
  if (news.length === 0) {
    return <Typography>Es gibt aktuell keine Meldungen!</Typography>;
  }

  return (
    <Box>
      {news.map((newsMessage) => (
        <NewsMessage key={'news-message-' + newsMessage.id} newsMessage={newsMessage} />
      ))}
    </Box>
  );
}
