import { Link as RouterLink } from 'react-router-dom';
import { Box, BoxProps, Typography } from '@mui/material';

interface Props extends BoxProps {
  disabledLink?: boolean;
  isCollapse?: boolean;
}

export default function Logo({ disabledLink = false, sx, isCollapse = false }: Props) {
  let logo;

  if (isCollapse) {
    logo = <span>{`<S>`}</span>;
  } else {
    logo = (
      <Box sx={{ width: 180, height: 60, ...sx }}>
        <img src="/logo/RocketSelectCode.svg" alt="SelectCode logo" />
      </Box>
    );
  }

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
