import Router from './routes';
import ThemeProvider from './theme';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './apollo';
import numeral from 'numeral';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Put any other imports below so that CSS from your
// components takes precedence over default styles.
export default function App() {
  useEffect(() => {
    // load a locale
    numeral.register('locale', 'de', {
      delimiters: {
        thousands: ' ',
        decimal: ',',
      },
      abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
      },
      ordinal: function (number) {
        return '.';
      },
      currency: {
        symbol: '€',
      },
    });
    numeral.locale('de');
  }, []);

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ApolloProvider client={apolloClient}>
          <ProgressBarStyle />
          <ScrollToTop />
          <Router />
          <ToastContainer theme="colored" />
        </ApolloProvider>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
